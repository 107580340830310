import { useMemo } from 'react'

import useWindowKeysCombo from './useWindowKeysCombo'

const kids = [
  'mango',
  'hektor',
  'bongo',
  'heaton',
  'feffe',
  'emma',
  'lo10',
  'simon',
  'rodrigues',
  'kotte',
  'erik',
]

export default function useKrusoKids() {
  for (let i = 0; i < kids.length; i += 1) {
    const kidName = kids[i]

    // Hooks used inside loop is ok because the array is never changed
    useWindowKeysCombo(
      kidName.split(''),
      useAsyncLoadImageAndFlashIt(`/kruso-kids/${kidName}.jpg`)
    )
  }
}

function useAsyncLoadImageAndFlashIt(imageSrc: string): () => void {
  return useMemo(() => {
    let image: HTMLImageElement | null = null
    let isFlashing = false

    function flashImage() {
      if (isFlashing || !image) {
        return
      }

      isFlashing = true

      const height = 40

      const aspectRatio = image.width / image.height
      const displayWidth = window.innerHeight * (height / 100) * aspectRatio
      const xPos = Math.random() * (window.innerWidth - displayWidth)

      const { style } = image
      style.height = `${height}vh`
      style.left = `${xPos}px`
      style.objectFit = 'contain'
      style.position = 'fixed'
      style.top = '0'
      style.transform = 'translateY(-100%)'
      style.transition = ''
      style.width = `auto`
      style.zIndex = '100'

      document.body.appendChild(image)

      requestAnimationFrame(() =>
        requestAnimationFrame(() => {
          style.transform = 'translateY(0)'
          style.transition = 'transform .4s cubic-bezier(0.165, 0.84, 0.44, 1)'

          setTimeout(() => {
            style.transform = 'translateY(-100%)'
            style.transition =
              'transform .3s cubic-bezier(0.455, 0.03, 0.515, 0.955)'

            setTimeout(() => {
              document.body.removeChild(image!)
              isFlashing = false
            }, 400)
          }, 1100)
        })
      )
    }

    return function loadImageAndFlash() {
      if (image === null) {
        image = document.createElement('img')
        image.onload = flashImage
        image.src = imageSrc
      } else {
        flashImage()
      }
    }
  }, [imageSrc])
}
