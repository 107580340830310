import React, { ReactNode, useMemo, useState } from 'react'
import { Helmet } from 'react-helmet'

import { BasePageContent } from '../../@import-types/contentful/BasePage.types'
import Colors from '../../constants/Colors'
import GlobalContentContext from '../../contexts/GlobalContentContext'
import NavMenuContext from '../../contexts/NavMenuContext'
import { PageContext, RootContent } from '../../graphql/global.graphql'
import useNavMenuContext from '../../hooks/navMenu/useNavMenuContext'
import usePageLink from '../../hooks/routing/usePageLink'
import useKrusoKids from '../../hooks/useKrusoKids'
import useSmoothScrollToWindowHashOnLoad from '../../hooks/useSmoothScrollToWindowHashOnLoad'
import CookieBanner from '../CookieBanner'
import {
  CookieBannerBlockTypename,
  CookieContentProps,
} from '../CookieBanner/CookieBanner'
import Footer from '../Footer'
import LanguageSelector from '../LanguageSelector'
import LayoutHeader from '../LayoutHeader'
import '../../base.scss'
import '../../hubspot-popup.scss'
import useOnMount from '../../hooks/useOnMount'

import Cookies from 'js-cookie'

import LinkedinInsights from '../_functional/LinkedInInsights/LinkedInInsights'

interface LayoutProps {
  data: RootContent
  basePageContent: BasePageContent
  pageContext: PageContext

  pageTextColor?: string | null
  pageBackgroundColor?: string | null

  noFooter?: boolean

  children: ReactNode | ReactNode[]
}

export default function Layout({
  data,
  basePageContent,
  pageContext,

  pageTextColor,
  pageBackgroundColor,

  noFooter,

  children,
}: LayoutProps) {
  useKrusoKids()
  useSmoothScrollToWindowHashOnLoad()
  const pageLink = usePageLink(pageContext.pagePath)

  const globalContentValue = useMemo(() => {
    const globalContentValue: RootContent = {
      ...data,
      pageContext,
      pageTextColor,
      pageBackgroundColor,
    }
    return globalContentValue
  }, [data, basePageContent, pageContext])

  const navMenuContextValue = useNavMenuContext()
  const canonical = getCanonical(basePageContent, pageContext)
  const cookieBannerContent = getCookieBannerContent(
    data.contentfulStartPage?.content
  )
  const [showBanner, toggleBanner] = useState<boolean>(false)
  const [isBigBannerVisible, setIsBigBannerVisible] = useState<boolean>(false)

  useOnMount(() => {
    const isCookieObjectPresent = Cookies.get('tracking-preferences')

    if (!isCookieObjectPresent) {
      toggleBanner(true)
    } else {
      toggleBanner(false)
    }
  })

  return (
    <GlobalContentContext.Provider value={globalContentValue}>
      <NavMenuContext.Provider value={navMenuContextValue}>
        <Helmet>
          <title>{basePageContent?.seoInformation?.metaTitle ?? 'Kruso'}</title>

          <meta
            name="theme-color"
            content={pageBackgroundColor ?? Colors.Sand}
          />

          <html lang={pageContext.locale} />
          {canonical && <link rel="canonical" href={canonical} />}
          {basePageContent.seoInformation?.metaTitle && (
            <meta
              property="og:title"
              content={basePageContent.seoInformation.metaTitle}
            />
          )}
          {basePageContent.seoInformation?.description && (
            <meta
              property="og:description"
              content={basePageContent.seoInformation.description}
            />
          )}
          {basePageContent.seoInformation?.description && (
            <meta
              name="description"
              content={basePageContent.seoInformation.description}
            />
          )}

          {basePageContent.seoInformation?.metaImage && (
            <meta
              property="og:image"
              content={basePageContent.seoInformation.metaImage.fluid?.src}
            />
          )}
          <meta property="og:url" content={pageLink} />
          {/* <meta name="twitter:card" content="summary_large_image"></meta> */}

          <meta
            name="facebook-domain-verification"
            content="rfdacoar7bbv16ree0jw0r8byqcf5n"
          />
          <meta
            name="facebook-domain-verification"
            content="cutnskfmwwob5wl6pow8cuo5sjehrd"
          />

          <script
            async
            type="text/javascript"
            src="https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=V5mXE8"
          ></script>

          <script
            type="text/javascript"
            id="hs-script-loader"
            async
            defer
            src="//js-eu1.hs-scripts.com/26282571.js"
          ></script>
        </Helmet>

        <LayoutHeader />

        {children}

        <LanguageSelector isFixed />

        <CookieBanner
          showBanner={showBanner}
          toggleBanner={toggleBanner}
          isBigBannerVisible={isBigBannerVisible}
          setIsBigBannerVisible={setIsBigBannerVisible}
          content={cookieBannerContent}
        />

        {!noFooter && (
          <Footer
            toggleBanner={toggleBanner}
            setIsBigBannerVisible={setIsBigBannerVisible}
          />
        )}
        <LinkedinInsights
          id={process.env.GATSBY_LINKEDIN_ID}
        ></LinkedinInsights>
      </NavMenuContext.Provider>
    </GlobalContentContext.Provider>
  )

  function getCanonical(
    basePageContent: BasePageContent,
    pageContext: PageContext
  ): null | string | undefined {
    if ((basePageContent.seoInformation?.languages ?? null) === null)
      return null
    const rootPath = process.env.GATSBY_HOST ?? ''
    const canonicalPath = `${rootPath}/en/${pageContext.pagePath}`
    switch (basePageContent.node_locale) {
      case 'sv':
        if (!basePageContent.seoInformation?.languages.includes('Swedish'))
          return canonicalPath
        break
      case 'da':
        if (!basePageContent.seoInformation?.languages.includes('Danish'))
          return canonicalPath
      default:
        return null
    }
  }

  function getCookieBannerContent(
    contentBlocks: Array<any> | null | undefined
  ): CookieContentProps | null | undefined {
    if (!contentBlocks) {
      return null
    }

    const extractCookieBannerContent = contentBlocks.filter(
      (block) => block.__typename === CookieBannerBlockTypename
    )

    const content = extractCookieBannerContent[0]
    return content
  }
}
