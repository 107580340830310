import classNames from 'classnames'
import React from 'react'

import styles from './cta-button.module.scss'

interface CTAButtonProps {
  isSmaller?: boolean
  withShadow?: boolean
}

export default function CTAButton({
  isSmaller,
  withShadow,

  className,

  ...buttonProps
}: CTAButtonProps & JSX.IntrinsicElements['button']) {
  return (
    <button
      className={classNames(
        styles.ctaButton,
        isSmaller && styles.isSmaller,
        withShadow && styles.withShadow,
        className
      )}
      {...buttonProps}
    />
  )
}
