import classNames from 'classnames'
import React, { useEffect, useMemo, useRef } from 'react'

import KeyEventKeys from '../../constants/KeyEventKeys'
import useOffices from '../../hooks/globalContent/useOffices'
import useIsNavMenuOpen from '../../hooks/navMenu/useIsNavMenuOpen'
import useNavMenuControls from '../../hooks/navMenu/useNavMenuControls'
import useIsDesktop from '../../hooks/useIsDesktop'
import useTabLock from '../../hooks/useTabLock'
import useWindowKeyEvent from '../../hooks/useWindowKeyEvent'
import { enablePageScroll, disablePageScroll } from '../../utils/scrollUtils'
import LanguageSelector from '../LanguageSelector'
import SocialMediaLinks from '../SocialMediaLinks'
import OfficeCard from '../_cards/OfficeCard'
import { InternalLink } from '../_elements/InternalLink/InternalLink'
import MenuIcon from '../_elements/MenuIcon'

import { useNavMenuLinks } from './NavMenu.hooks'
import SubNavMenu from './SubNavMenu'

import styles from './nav-menu.module.scss'

interface NavMenuProps {
  iconsColor?: string | null
}

export default function NavMenu({ iconsColor }: NavMenuProps) {
  const isDesktop = useIsDesktop()

  const navMenuLinks = useNavMenuLinks()

  const offices = useOffices()
  const limitedOffices = useMemo(
    () => offices.slice(0, isDesktop ? 3 : 4),
    [offices, isDesktop]
  )

  const isOpen = useIsNavMenuOpen()
  const { close, toggle } = useNavMenuControls()

  const menuBackgroundRef = useRef<HTMLDivElement | null>(null)

  useWindowKeyEvent(KeyEventKeys.Escape, onEscapePressed)

  const { lockTabFocus, unlockTabFocus } = useTabLock(menuBackgroundRef)

  useEffect(() => {
    if (isOpen) {
      lockTabFocus()
      disablePageScroll()

      return () => {
        unlockTabFocus()
        enablePageScroll()
      }
    }
  }, [isOpen])

  function onOverlayClick() {
    close()
  }

  function onEscapePressed() {
    if (isOpen) {
      close()
    }
  }

  function onMenuLinkClick() {
    // For gatsby to calculate a correct scroll position for the new route
    enablePageScroll()
  }

  return (
    <div ref={menuBackgroundRef}>
      <button
        className={styles.button}
        id="menu-button"
        onClick={toggle}
        aria-label={isOpen ? 'Hide navigation menu' : 'Show navigation menu'}
        aria-haspopup={true}
        aria-expanded={isOpen}
        aria-controls="menu-flyout"
      >
        <MenuIcon closedColor={iconsColor} isOpen={isOpen} />
      </button>

      <div
        className={classNames(styles.overlay, isOpen && styles.overlayIsOpen)}
        onClick={onOverlayClick}
      />

      <div
        className={classNames(
          styles.background,
          isOpen && styles.backgroundIsOpen
        )}
        id="menu-flyout"
        aria-labelledby="menu-button"
        aria-hidden={!isOpen}
      >
        <div className={styles.backgroundInner}>
          <nav className={styles.nav}>
            <ul className={styles.navList}>
              {navMenuLinks.map((navMenuLink) => {
                if (!navMenuLink.subpages)
                  return (
                      <li
                        key={navMenuLink.to + navMenuLink.label}
                        className={styles.navListItem}
                        onClick={onMenuLinkClick}
                      >
                        <InternalLink
                          className={classNames(
                            styles.navLink,
                            navMenuLink.subpages && styles.navLinkWithSub
                          )}
                          activeClassName={styles.navLinkActive}
                          to={navMenuLink.to}
                          tabIndex={isOpen ? undefined : -1}
                          segmentdata={{
                            anchor_text: navMenuLink.label,
                            position: 'navigation',
                            url: navMenuLink.to,
                          }}
                        >
                          {navMenuLink.label}
                        </InternalLink>
                      </li>
                  )
                else if (navMenuLink.subpages)
                  return <SubNavMenu key={navMenuLink.to + navMenuLink.label} navItem={navMenuLink} />
              })}
            </ul>
          </nav>

          <ul className={styles.officeList}>
            {limitedOffices.map((office) => (
              <li key={office.id} className={styles.officeItem}>
                <OfficeCard
                  className={styles.office}
                  office={office}
                  isCondensed={!isDesktop}
                  isLargerText={isDesktop}
                  isDisabledLinks={!isOpen}
                />
              </li>
            ))}
          </ul>

          <div className={styles.socialContainer}>
            <LanguageSelector
              className={styles.languageSelector}
              isHorizontal={!isDesktop}
              isDisabledLinks={!isOpen}
            />
            <SocialMediaLinks
              className={styles.socialMediaLinks}
              isDisabledLinks={!isOpen}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
