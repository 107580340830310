import React from 'react'

interface SvgLinkedInProps {}

export default function SvgLinkedIn(
  svgProps: SvgLinkedInProps & JSX.IntrinsicElements['svg']
) {
  return (
    <svg width="16" height="15" viewBox="0 0 16 15" {...svgProps}>
      <path
        className="svg-fill"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.5277 3.47569C2.55278 3.65018 3.447 3.14854 3.6651 2.27613C3.94864 1.12018 3.14166 0.138721 1.92028 0.160532C1.1133 0.138721 0.480803 0.531306 0.21908 1.18561C-0.173505 2.23251 0.437183 3.30121 1.5277 3.47569ZM14.854 14.4901C13.938 14.4683 13.0438 14.4683 12.1277 14.4901C11.9314 14.4901 11.8878 14.4464 11.8878 14.2502V9.38646C11.8878 9.01569 11.866 8.64491 11.757 8.29595C11.4298 7.16181 10.1212 6.74742 9.18334 7.51078C8.6817 7.90336 8.48541 8.44862 8.48541 9.10293V13.6831C8.48541 13.7477 8.48305 13.8124 8.48065 13.8778V13.8778C8.47578 14.011 8.47078 14.1475 8.48541 14.2938C8.50722 14.4683 8.41998 14.5119 8.26731 14.4901H5.47559C5.30111 14.4901 5.25749 14.4464 5.25749 14.272C5.2793 12.8979 5.2793 11.5239 5.2793 10.128C5.2793 8.42681 5.2793 6.72561 5.25749 5.04622C5.25749 4.84992 5.30111 4.8063 5.47559 4.8063H8.26731C8.44179 4.8063 8.48541 4.84992 8.48541 5.0244V6.13673L8.59446 6.02768C9.44506 4.78449 10.6446 4.43553 12.0623 4.65363C13.6981 4.91535 14.745 6.04949 15.0067 7.79431C15.0721 8.2087 15.0939 8.6231 15.0939 9.0375V14.2502C15.0939 14.4246 15.0503 14.4901 14.854 14.4901ZM3.51247 9.64798V14.2281C3.51247 14.4244 3.46885 14.4899 3.27255 14.4899C2.66187 14.4753 2.05118 14.4802 1.44049 14.485C1.13515 14.4874 0.829803 14.4899 0.524459 14.4899C0.349977 14.4899 0.306356 14.4462 0.306356 14.2718V5.0242C0.306356 4.87153 0.349977 4.8061 0.524459 4.8061H3.33798C3.53428 4.8061 3.55609 4.87153 3.55609 5.04601C3.51247 6.57273 3.51247 8.09945 3.51247 9.64798Z"
        fill="#2F210C"
      />
    </svg>
  )
}
