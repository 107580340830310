import { useMemo } from 'react'

export default function useSortedEntries<
  T extends { id?: string | null },
  S extends { id?: string | null }
>(allEntries: T[], entriesOrder?: S[] | null): T[] {
  return useMemo(() => {
    const allEntriesClone = allEntries.slice()

    if (!entriesOrder) {
      return allEntriesClone
    }

    allEntriesClone.sort((a, b) => {
      const aSortOrder = entriesOrder.findIndex(
        (orderedEntry) => orderedEntry.id === a.id
      )

      const bSortOrder = entriesOrder.findIndex(
        (orderedEntry) => orderedEntry.id === b.id
      )

      if (aSortOrder === -1) {
        if (bSortOrder === -1) {
          return 0
        } else {
          return 1
        }
      } else {
        if (bSortOrder === -1) {
          return -1
        } else {
          return aSortOrder - bSortOrder
        }
      }
    })

    return allEntriesClone
  }, [allEntries, entriesOrder])
}
