import React, { useRef } from 'react'

import useIsNavMenuOpen from '../../hooks/navMenu/useIsNavMenuOpen'
import useFixedElementPageTextColor from '../../hooks/useFixedElementPageTextColor'
import ContactUsAnchor from '../ContactUsAnchor'
import NavMenu from '../NavMenu'
import LogoLink from '../_elements/LogoLink'

interface LayoutHeaderProps {}

export default function LayoutHeader({}: LayoutHeaderProps) {
  const logoLinkRef = useRef(null)

  const iconsColor = useFixedElementPageTextColor(logoLinkRef)

  const isNavMenuOpen = useIsNavMenuOpen()

  const shouldForceDefaultAnchorColor = isNavMenuOpen

  return (
    <>
      <LogoLink color={iconsColor} forwardRef={logoLinkRef} />

      <NavMenu iconsColor={iconsColor} />

      <ContactUsAnchor
        color={shouldForceDefaultAnchorColor ? null : iconsColor}
      />
    </>
  )
}
