import { useEffect, useRef } from 'react'

export default function useWindowKeysCombo(
  keyCodeCombo: string[],
  callback: () => void
) {
  const callbackRef = useRef(callback)
  callbackRef.current = callback

  useEffect(() => {
    let nextIndex = 0
    let timeoutId: ReturnType<typeof setTimeout>

    function onKeyDown(event: KeyboardEvent) {
      clearTimeout(timeoutId)

      if (event.key === keyCodeCombo[nextIndex]) {
        nextIndex += 1

        if (nextIndex === keyCodeCombo.length) {
          callbackRef.current()
          nextIndex = 0
        } else {
          const resetTimeoutMs = 750
          timeoutId = setTimeout(() => {
            nextIndex = 0
          }, resetTimeoutMs)
        }
      } else {
        nextIndex = 0
      }
    }

    window.addEventListener('keydown', onKeyDown, false)

    return () => {
      clearTimeout(timeoutId)
      window.removeEventListener('keydown', onKeyDown, false)
    }
  }, keyCodeCombo)
}
