import { MutableRefObject, useCallback, useLayoutEffect, useRef } from 'react'

declare global {
  interface HTMLElement {
    isClone?: boolean
  }
}

interface CachedItemValues {
  offsetTop: number
  offsetLeft: number
  clientHeight: number
  clientWidth: number
  clone: HTMLElement
}

export interface FadeTransitionOptions {
  containerElRef: MutableRefObject<HTMLElement | null>
  animateContainerHeight?: boolean
  durationMultiplier?: number
  scale?: number
  translateX?: number
  forceCloneSize?: boolean
}

export default function useFadeTransitionItems({
  containerElRef,
  animateContainerHeight = false,
  durationMultiplier = 1,
  scale = 0,
  translateX = 0,
  forceCloneSize = false,
}: FadeTransitionOptions) {
  const iterationKeyRef = useRef(1)
  const cachedHeight = useRef(-1)
  const cachedValuesRef = useRef<CachedItemValues[]>([])

  useLayoutEffect(() => {
    const containerEl = containerElRef.current
    if (!containerEl) {
      return
    }

    containerEl.style.position = 'relative'

    const baseDuration = 400 * durationMultiplier
    const baseBetweenItemsDelay = 50 * durationMultiplier

    const inAnimationDuration = baseDuration * 1.5
    const inAnimationDelay =
      cachedValuesRef.current.length > 1 ? baseDuration * 0.6 : 0

    const outAnimationDuration = baseDuration

    let cancelHeightAnimation: (() => void) | undefined
    if (animateContainerHeight) {
      containerEl.style.willChange = 'height'

      cancelHeightAnimation = runAnimateContainerHeight(
        containerEl,
        cachedHeight.current,
        baseDuration + baseBetweenItemsDelay * cachedValuesRef.current.length
      )
    }

    const cancelInAnimations = animateInChildren(
      containerEl.children,
      scale,
      translateX,
      inAnimationDuration,
      inAnimationDelay,
      baseBetweenItemsDelay
    )

    const cancelOutAnimations = animateOutPreviousChildren(
      containerEl,
      cachedValuesRef.current,
      forceCloneSize,
      scale,
      translateX,
      outAnimationDuration,
      baseBetweenItemsDelay
    )

    return () => {
      cancelHeightAnimation?.()
      cancelInAnimations()
      cancelOutAnimations()
    }
  }, [iterationKeyRef.current])

  const cacheValuesBeforeChange = useCallback(() => {
    const containerEl = containerElRef.current
    if (!containerEl) {
      cachedHeight.current = -1
      cachedValuesRef.current = []

      return
    }

    iterationKeyRef.current = iterationKeyRef.current + 1
    cachedHeight.current = containerEl.clientHeight

    cachedValuesRef.current = Array.from(containerEl.children)
      .filter(
        (childEl) =>
          !(childEl as HTMLElement).isClone &&
          !cachedValuesRef.current.find(
            (cachedItem) => cachedItem.clone === childEl
          )
      )
      .map((childEl) => {
        return {
          offsetLeft: (childEl as HTMLElement).offsetLeft,
          offsetTop: (childEl as HTMLElement).offsetTop,
          clientHeight: (childEl as HTMLElement).clientHeight,
          clientWidth: (childEl as HTMLElement).clientWidth,
          clone: childEl.cloneNode(true) as HTMLElement,
        }
      })
  }, [])

  return cacheValuesBeforeChange
}

function runAnimateContainerHeight(
  containerEl: HTMLElement,
  previousHeight: number,
  duration: number
) {
  if (previousHeight === -1) {
    return
  }

  let rafId = -1
  let timeoutId = -1

  const { clientHeight: newHeight, style } = containerEl

  style.height = previousHeight + 'px'
  style.transition = ''

  rafId = requestAnimationFrame(() => {
    rafId = requestAnimationFrame(() => {
      style.height = newHeight + 'px'
      style.transition = `height ${duration}ms`

      timeoutId = (setTimeout(() => {
        style.height = ''
      }, duration) as unknown) as number
    })
  })

  return () => {
    clearTimeout(timeoutId)
    cancelAnimationFrame(rafId)
  }
}

function animateInChildren(
  children: HTMLCollection,
  scale: number,
  translateX: number,
  duration: number,
  initialDelay: number,
  itemDelay: number
) {
  let rafId = -1

  const childArray = (Array.from(children) as HTMLElement[]).filter(
    (child) => !child.isClone
  )

  childArray.forEach((childEl) => {
    const { style } = childEl
    style.opacity = '0'
    style.transform = `translateX(-${translateX}px) scale(${1 - scale})`
    style.transition = ''
  })

  rafId = requestAnimationFrame(() => {
    rafId = requestAnimationFrame(() => {
      childArray.forEach((childEl, index) => {
        const { style } = childEl

        const delay = initialDelay + itemDelay * index

        style.opacity = ''
        style.transform = ''
        style.transition = `all ${duration}ms ${delay}ms`
      })
    })
  })

  return () => cancelAnimationFrame(rafId)
}

function animateOutPreviousChildren(
  containerEl: HTMLElement,
  previousItems: CachedItemValues[],
  forceCloneSize: boolean,
  scale: number,
  translateX: number,
  duration: number,
  itemDelay: number
) {
  let rafId = -1
  let timeoutId = -1
  let hasCleanedUp = false

  previousItems.forEach((item) => {
    const { clone, offsetLeft, offsetTop, clientHeight, clientWidth } = item
    const { style } = clone

    clone.isClone = true

    if (forceCloneSize) {
      style.height = clientHeight + 'px'
      style.width = clientWidth + 'px'
    }

    style.left = offsetLeft + 'px'
    style.margin = '0'
    style.opacity = '1'
    style.pointerEvents = 'none'
    style.position = 'absolute'
    style.top = offsetTop + 'px'
    style.transform = ''
    style.transition = ''
    style.willChange = 'opacity, transform'

    containerEl.appendChild(clone)
  })

  rafId = requestAnimationFrame(() => {
    rafId = requestAnimationFrame(() => {
      previousItems.forEach((item, index) => {
        const { clone } = item
        const { style } = clone

        const delay = itemDelay * index

        style.opacity = '0'
        style.transform = `translateX(${translateX}px) scale(${1 + scale})`
        style.transition = `all ${duration}ms ${delay}ms`
      })

      const cleanTimeoutMs = duration + itemDelay * previousItems.length
      timeoutId = (setTimeout(cleanup, cleanTimeoutMs) as unknown) as number
    })
  })

  function cleanup() {
    if (hasCleanedUp) {
      return
    }

    previousItems.forEach((item) => containerEl.removeChild(item.clone))

    hasCleanedUp = true
  }

  return () => {
    cleanup()
    cancelAnimationFrame(rafId)
    clearTimeout(timeoutId)
  }
}
