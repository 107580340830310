import React from 'react'

import {
  getSegmentLinkTrackingdData,
  LinkTrackProps,
} from '../../_functional/Segment/helpers'

interface ExternalLinkProps {
  to: string
  segmentdata: LinkTrackProps
}

export default function ExternalLink({
  to,
  target = '_blank',
  rel = 'noopener noreferrer',
  segmentdata,
  ...linkProps
}: ExternalLinkProps & Omit<JSX.IntrinsicElements['a'], 'href'>) {
  return (
    <a
      href={to}
      target={target}
      rel={rel}
      {...linkProps}
      onClick={() =>
        getSegmentLinkTrackingdData({
          anchor_text: segmentdata.anchor_text,
          destination: 'external',
          position: segmentdata.position,
          url: segmentdata.url,
        })
      }
    />
  )
}
