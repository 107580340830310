export function joinUrlPaths(...paths: (string | null | undefined)[]) {
  const firstIndex = 0
  const lastIndex = paths.length - 1

  return paths
    .filter(Boolean)
    .map((path, index) => {
      if (index !== firstIndex) {
        path = path!.replace(/^\/+/, '')
      }

      if (index !== lastIndex) {
        path = path!.replace(/\/+$/, '')
      }

      return path
    })
    .join('/')
}
