import classNames from 'classnames'
import React from 'react'

import styles from './menu-icon.module.scss'

interface MenuIconProps {
  closedColor?: string | null

  isOpen?: boolean

  className?: string
}

export default function MenuIcon({
  closedColor,

  isOpen = false,

  className,
}: MenuIconProps) {
  return (
    <span className={classNames(styles.container, className)}>
      <span
        className={classNames(styles.hamburger, !isOpen && styles.isVisible)}
      >
        <span style={{ backgroundColor: isOpen ? undefined : closedColor! }} />
        <span style={{ backgroundColor: isOpen ? undefined : closedColor! }} />
        <span style={{ backgroundColor: isOpen ? undefined : closedColor! }} />
      </span>

      <span className={classNames(styles.cross, isOpen && styles.isVisible)}>
        <span />
        <span />
      </span>
    </span>
  )
}
