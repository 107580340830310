import { Block, Inline } from '@contentful/rich-text-types'
import React from 'react'

import useLocale from '../../../../hooks/globalContent/useLocale'
import Blockquote from '../../Blockquote'

interface RichTextEmbeddedEntryProps {
  node: Block | Inline
  children: React.ReactNode
}

interface EmbeddedEntryData<CID extends string = string, Fields = any> {
  target?: {
    fields?: Fields
    sys: {
      contentType: {
        sys: {
          id: CID
        }
      }
    }
  }
}

export default function RichTextEmbeddedEntry({
  node,
}: RichTextEmbeddedEntryProps) {
  //
  // I made this implementation really simple for now.
  // If we are going to add more supported embedded types we should make this more structure,
  // probably similar to how <ContentArea /> works.
  //

  const locale = useLocale()

  const embeddedEntryData = node.data as EmbeddedEntryData
  const contentTypeSys = embeddedEntryData.target?.sys.contentType.sys
  const fields = embeddedEntryData.target?.fields

  if (contentTypeSys?.id === 'blockQuote') {
    return (
      <Blockquote
        quote={getFieldValue(locale, fields?.quote, null)}
        author={getFieldValue(locale, fields?.author, null)}
        context={getFieldValue(locale, fields?.context, null)}
      />
    )
  }

  return null
}

function getFieldValue<T = any, D = any>(
  locale: string,
  fieldContainer: { [locale: string]: T } | null | undefined,
  defaultValue: D,
  defaultLocale = 'en'
) {
  if (fieldContainer) {
    if (locale in fieldContainer) {
      return fieldContainer[locale]
    }

    if (defaultLocale in fieldContainer) {
      return fieldContainer[defaultLocale]
    }
  }

  return defaultValue
}
