import classNames from 'classnames'
import React from 'react'

import styles from './svg-isae-cert.module.scss'

interface SvgIsaeCertProps {}

export default function SvgIsaeCert(
  props: SvgIsaeCertProps & JSX.IntrinsicElements['svg']
) {
  return (
    <div className={classNames(styles.svgContainer)}>
      <svg viewBox="0 0 512 100" {...props} className={classNames(styles.svg)}>
        <g id="_x33_000_GDPR_sort">
          <g>
            <rect
              x="211.39"
              y="44.28"
              className={classNames(styles.st0)}
              width="300.55"
              height="18.28"
            />
            <path d="M212.94,62.55V43.05h1.78v19.51H212.94z" />
            <path d="M227.04,44.28h3.24v13.84h-3.24V44.28z" />
            <path
              d="M235.56,53.53c0,0.75,0.2,1.34,0.61,1.76c0.41,0.42,0.94,0.63,1.6,0.63c0.51,0,0.95-0.15,1.29-0.44s0.52-0.69,0.52-1.18
                          c0-0.28-0.06-0.51-0.17-0.7c-0.11-0.19-0.26-0.36-0.45-0.5c-0.19-0.14-0.42-0.27-0.68-0.39c-0.26-0.11-0.55-0.23-0.85-0.35
                          c-0.63-0.24-1.24-0.47-1.81-0.71c-0.57-0.24-1.07-0.52-1.49-0.86s-0.76-0.74-1.01-1.22c-0.25-0.47-0.38-1.06-0.38-1.76
                          c0-0.63,0.13-1.19,0.41-1.68c0.27-0.49,0.63-0.9,1.08-1.23c0.45-0.33,0.96-0.58,1.55-0.74c0.59-0.16,1.2-0.25,1.83-0.25
                          c0.82,0,1.53,0.12,2.15,0.36c0.62,0.24,1.13,0.56,1.54,0.97c0.41,0.41,0.71,0.88,0.92,1.4c0.2,0.53,0.31,1.09,0.31,1.68h-3.2
                          c0-0.62-0.15-1.11-0.44-1.46s-0.72-0.53-1.27-0.53c-0.46,0-0.86,0.12-1.19,0.36c-0.33,0.24-0.49,0.59-0.49,1.07
                          c0,0.46,0.18,0.83,0.54,1.1c0.36,0.27,0.84,0.51,1.43,0.72c0.57,0.2,1.14,0.41,1.72,0.64c0.58,0.23,1.1,0.51,1.56,0.85
                          c0.46,0.34,0.84,0.75,1.14,1.23c0.3,0.48,0.44,1.06,0.44,1.75c0,0.71-0.13,1.34-0.39,1.9c-0.26,0.55-0.62,1.02-1.08,1.39
                          c-0.46,0.38-1.01,0.66-1.64,0.85c-0.63,0.19-1.33,0.29-2.1,0.29c-0.74,0-1.43-0.11-2.07-0.33c-0.64-0.22-1.19-0.54-1.66-0.96
                          s-0.83-0.94-1.1-1.55c-0.26-0.61-0.4-1.31-0.4-2.11H235.56z"
            />
            <path
              d="M252.53,58.11L252,56.43h-4.92l-0.53,1.68h-3.42l4.94-13.84h2.94l5,13.84H252.53z M249.55,48.23l-1.7,5.69h3.36
                          L249.55,48.23z"
            />
            <path d="M260.62,46.85v3.14h5.24v2.51h-5.24v3.04h5.24v2.57h-8.44V44.28h8.44v2.57H260.62z" />
            <path
              d="M275.7,53.47c0,0.75,0.19,1.33,0.57,1.75c0.38,0.42,0.88,0.62,1.5,0.62c0.59,0,1.05-0.18,1.36-0.55s0.47-0.79,0.47-1.27
                          c0-0.58-0.15-1.04-0.45-1.39c-0.3-0.35-0.83-0.52-1.58-0.52h-1.25v-2.47h1.25c0.71,0,1.2-0.16,1.45-0.47
                          c0.26-0.32,0.39-0.72,0.39-1.23c0-0.53-0.15-0.92-0.45-1.17c-0.3-0.25-0.67-0.38-1.14-0.38c-0.68,0-1.15,0.19-1.39,0.55
                          c-0.24,0.37-0.37,0.86-0.37,1.46h-3.2c0-0.59,0.1-1.16,0.29-1.69c0.19-0.53,0.48-1,0.88-1.4c0.4-0.4,0.9-0.72,1.51-0.97
                          c0.61-0.24,1.35-0.37,2.2-0.37c0.63,0,1.24,0.08,1.83,0.24c0.59,0.16,1.1,0.4,1.54,0.72c0.44,0.32,0.79,0.73,1.06,1.22
                          s0.4,1.05,0.4,1.7c0,0.78-0.2,1.44-0.6,1.98c-0.4,0.54-0.88,0.9-1.43,1.09c0.32,0.12,0.61,0.28,0.88,0.47
                          c0.27,0.2,0.51,0.44,0.71,0.71c0.2,0.28,0.37,0.6,0.48,0.96c0.12,0.36,0.18,0.76,0.18,1.2c0,0.66-0.14,1.25-0.41,1.76
                          c-0.27,0.51-0.64,0.95-1.1,1.3c-0.46,0.36-0.99,0.63-1.59,0.81s-1.23,0.28-1.89,0.28c-0.86,0-1.61-0.12-2.27-0.36
                          c-0.66-0.24-1.22-0.57-1.67-1.01c-0.45-0.43-0.8-0.96-1.03-1.56c-0.23-0.61-0.35-1.28-0.35-2.02H275.7z"
            />
            <path
              d="M284.55,48.92c0-0.67,0.12-1.3,0.37-1.9c0.24-0.59,0.59-1.12,1.05-1.57s1.01-0.81,1.66-1.08c0.65-0.26,1.38-0.4,2.18-0.4
                          c0.8,0,1.53,0.13,2.18,0.4c0.65,0.26,1.21,0.62,1.67,1.08c0.46,0.45,0.82,0.98,1.07,1.57c0.25,0.59,0.38,1.23,0.38,1.9v4.55
                          c0,0.67-0.12,1.3-0.38,1.9c-0.25,0.59-0.61,1.12-1.07,1.57c-0.46,0.45-1.02,0.81-1.67,1.08s-1.38,0.4-2.18,0.4
                          c-0.8,0-1.53-0.13-2.18-0.4s-1.21-0.62-1.66-1.08c-0.46-0.46-0.8-0.98-1.05-1.57c-0.24-0.59-0.37-1.23-0.37-1.9V48.92z
                          M291.91,48.92c0-0.3-0.05-0.59-0.15-0.86c-0.1-0.27-0.24-0.51-0.42-0.71s-0.4-0.37-0.66-0.48s-0.55-0.18-0.87-0.18
                          s-0.6,0.06-0.86,0.18s-0.47,0.28-0.65,0.48s-0.31,0.44-0.41,0.71c-0.09,0.27-0.14,0.56-0.14,0.86v4.55c0,0.3,0.05,0.59,0.14,0.86
                          c0.09,0.27,0.23,0.51,0.41,0.71s0.4,0.37,0.65,0.48c0.26,0.12,0.54,0.18,0.86,0.18s0.61-0.06,0.87-0.18
                          c0.26-0.12,0.48-0.28,0.66-0.48s0.32-0.44,0.42-0.71c0.1-0.27,0.15-0.56,0.15-0.86V48.92z"
            />
            <path
              d="M296.73,48.92c0-0.67,0.12-1.3,0.37-1.9c0.24-0.59,0.59-1.12,1.05-1.57s1.01-0.81,1.66-1.08c0.65-0.26,1.38-0.4,2.18-0.4
                          c0.8,0,1.53,0.13,2.18,0.4c0.65,0.26,1.21,0.62,1.67,1.08c0.46,0.45,0.82,0.98,1.07,1.57c0.25,0.59,0.38,1.23,0.38,1.9v4.55
                          c0,0.67-0.12,1.3-0.38,1.9c-0.25,0.59-0.61,1.12-1.07,1.57c-0.46,0.45-1.02,0.81-1.67,1.08s-1.38,0.4-2.18,0.4
                          c-0.8,0-1.53-0.13-2.18-0.4s-1.21-0.62-1.66-1.08c-0.46-0.46-0.8-0.98-1.05-1.57c-0.24-0.59-0.37-1.23-0.37-1.9V48.92z
                          M304.08,48.92c0-0.3-0.05-0.59-0.15-0.86c-0.1-0.27-0.24-0.51-0.42-0.71s-0.4-0.37-0.66-0.48s-0.55-0.18-0.87-0.18
                          s-0.6,0.06-0.86,0.18s-0.47,0.28-0.65,0.48s-0.31,0.44-0.41,0.71c-0.09,0.27-0.14,0.56-0.14,0.86v4.55c0,0.3,0.05,0.59,0.14,0.86
                          c0.09,0.27,0.23,0.51,0.41,0.71s0.4,0.37,0.65,0.48c0.26,0.12,0.54,0.18,0.86,0.18s0.61-0.06,0.87-0.18
                          c0.26-0.12,0.48-0.28,0.66-0.48s0.32-0.44,0.42-0.71c0.1-0.27,0.15-0.56,0.15-0.86V48.92z"
            />
            <path
              d="M308.91,48.92c0-0.67,0.12-1.3,0.37-1.9c0.24-0.59,0.59-1.12,1.05-1.57s1.01-0.81,1.66-1.08c0.65-0.26,1.38-0.4,2.18-0.4
                          c0.8,0,1.53,0.13,2.18,0.4c0.65,0.26,1.21,0.62,1.67,1.08c0.46,0.45,0.82,0.98,1.07,1.57c0.25,0.59,0.38,1.23,0.38,1.9v4.55
                          c0,0.67-0.12,1.3-0.38,1.9c-0.25,0.59-0.61,1.12-1.07,1.57c-0.46,0.45-1.02,0.81-1.67,1.08s-1.38,0.4-2.18,0.4
                          c-0.8,0-1.53-0.13-2.18-0.4s-1.21-0.62-1.66-1.08c-0.46-0.46-0.8-0.98-1.05-1.57c-0.24-0.59-0.37-1.23-0.37-1.9V48.92z
                          M316.26,48.92c0-0.3-0.05-0.59-0.15-0.86c-0.1-0.27-0.24-0.51-0.42-0.71s-0.4-0.37-0.66-0.48s-0.55-0.18-0.87-0.18
                          s-0.6,0.06-0.86,0.18s-0.47,0.28-0.65,0.48s-0.31,0.44-0.41,0.71c-0.09,0.27-0.14,0.56-0.14,0.86v4.55c0,0.3,0.05,0.59,0.14,0.86
                          c0.09,0.27,0.23,0.51,0.41,0.71s0.4,0.37,0.65,0.48c0.26,0.12,0.54,0.18,0.86,0.18s0.61-0.06,0.87-0.18
                          c0.26-0.12,0.48-0.28,0.66-0.48s0.32-0.44,0.42-0.71c0.1-0.27,0.15-0.56,0.15-0.86V48.92z"
            />
            <path
              d="M334.07,50.8h7.17v2.39h-1.42c-0.15,0.79-0.4,1.51-0.75,2.15c-0.36,0.65-0.82,1.2-1.4,1.66
                          c-0.58,0.46-1.26,0.82-2.05,1.08s-1.67,0.38-2.66,0.38c-1.16,0-2.19-0.2-3.09-0.59s-1.66-0.93-2.27-1.59
                          c-0.61-0.67-1.08-1.44-1.4-2.31c-0.32-0.88-0.48-1.8-0.48-2.78c0-0.95,0.16-1.86,0.49-2.74c0.33-0.88,0.81-1.65,1.43-2.32
                          s1.39-1.21,2.28-1.61c0.9-0.4,1.91-0.6,3.04-0.6c0.91,0,1.74,0.13,2.5,0.39c0.76,0.26,1.41,0.61,1.97,1.05
                          c0.55,0.44,1,0.96,1.32,1.54s0.53,1.21,0.59,1.87h-3.42c-0.05-0.28-0.16-0.54-0.32-0.79s-0.37-0.47-0.62-0.66s-0.56-0.34-0.9-0.45
                          s-0.72-0.17-1.15-0.17c-0.66,0-1.23,0.12-1.72,0.38c-0.49,0.25-0.9,0.59-1.24,1.01c-0.34,0.42-0.59,0.91-0.75,1.45
                          c-0.17,0.55-0.25,1.11-0.25,1.69c0,0.62,0.08,1.2,0.26,1.75c0.17,0.55,0.43,1.03,0.78,1.44c0.35,0.42,0.79,0.74,1.32,0.99
                          c0.53,0.24,1.16,0.37,1.87,0.37c0.49,0,0.92-0.07,1.3-0.21c0.38-0.14,0.71-0.33,0.99-0.56c0.28-0.24,0.5-0.51,0.67-0.82
                          c0.17-0.31,0.28-0.64,0.34-0.98h-2.45V50.8z"
            />
            <path
              d="M347.98,44.28c1.15,0,2.16,0.19,3.05,0.58s1.64,0.91,2.25,1.55c0.61,0.65,1.08,1.38,1.4,2.21
                          c0.32,0.83,0.48,1.69,0.48,2.57s-0.16,1.74-0.47,2.57c-0.32,0.83-0.78,1.57-1.39,2.21c-0.61,0.65-1.37,1.16-2.26,1.55
                          c-0.9,0.39-1.92,0.58-3.06,0.58h-5.08V44.28H347.98z M346.1,47.04v8.3h1.72c0.65,0,1.22-0.12,1.73-0.35
                          c0.51-0.23,0.94-0.54,1.28-0.93c0.35-0.39,0.62-0.83,0.8-1.32s0.28-1.01,0.28-1.55c0-0.53-0.09-1.04-0.28-1.54
                          c-0.18-0.5-0.45-0.95-0.8-1.33c-0.35-0.39-0.78-0.7-1.28-0.93c-0.51-0.23-1.08-0.35-1.73-0.35H346.1z"
            />
            <path
              d="M357.13,44.28h5.79c0.76,0,1.46,0.14,2.08,0.41c0.62,0.27,1.15,0.63,1.59,1.09s0.78,0.99,1.02,1.59s0.36,1.25,0.36,1.92
                          c0,0.67-0.12,1.31-0.36,1.92c-0.24,0.61-0.58,1.14-1.02,1.59c-0.44,0.46-0.97,0.82-1.59,1.09c-0.62,0.27-1.31,0.4-2.08,0.4h-2.59
                          v3.83h-3.2V44.28z M362.53,51.67c0.33,0,0.63-0.07,0.91-0.2c0.28-0.13,0.51-0.31,0.7-0.52s0.34-0.47,0.45-0.76
                          c0.1-0.29,0.16-0.59,0.16-0.91c0-0.32-0.05-0.62-0.16-0.91c-0.11-0.29-0.25-0.54-0.45-0.76s-0.42-0.39-0.7-0.52
                          c-0.28-0.13-0.58-0.2-0.91-0.2h-2.19v4.78H362.53z"
            />
            <path
              d="M369.68,44.28h5.81c0.76,0,1.46,0.14,2.08,0.41c0.62,0.27,1.15,0.63,1.59,1.09s0.78,0.99,1.02,1.59
                          c0.24,0.61,0.36,1.25,0.36,1.92c0,0.98-0.24,1.86-0.72,2.66c-0.48,0.8-1.16,1.4-2.03,1.81l2.77,4.37h-3.72l-2.45-3.83h-1.5v3.83
                          h-3.2V44.28z M375.08,51.67c0.33,0,0.63-0.07,0.91-0.2c0.28-0.13,0.51-0.31,0.7-0.52s0.34-0.47,0.45-0.76
                          c0.1-0.29,0.16-0.59,0.16-0.91c0-0.32-0.05-0.62-0.16-0.91c-0.11-0.29-0.25-0.54-0.45-0.76s-0.42-0.39-0.7-0.52
                          c-0.28-0.13-0.58-0.2-0.91-0.2h-2.19v4.78H375.08z"
            />
            <path d="M392.2,58.11h-1.54l-4.59-13.28h2.3l3.07,9.66l3.05-9.66h2.3L392.2,58.11z" />
            <path
              d="M398.59,54.32c0.03,0.32,0.08,0.61,0.17,0.89c0.09,0.28,0.22,0.52,0.41,0.73s0.42,0.37,0.71,0.49
                          c0.29,0.12,0.65,0.18,1.08,0.18c0.59,0,1.03-0.11,1.29-0.34s0.46-0.5,0.59-0.82h2.09c-0.06,0.38-0.2,0.74-0.41,1.09
                          c-0.21,0.35-0.48,0.65-0.83,0.92c-0.34,0.27-0.74,0.48-1.2,0.64c-0.46,0.16-0.97,0.24-1.54,0.24c-0.75,0-1.39-0.12-1.94-0.36
                          c-0.55-0.24-1.01-0.57-1.38-0.99c-0.37-0.42-0.64-0.91-0.83-1.48s-0.28-1.18-0.28-1.82c0-0.66,0.1-1.27,0.29-1.84
                          s0.48-1.06,0.84-1.48c0.37-0.42,0.82-0.74,1.37-0.98c0.54-0.23,1.16-0.35,1.86-0.35c0.57,0,1.1,0.1,1.6,0.3
                          c0.5,0.2,0.94,0.48,1.31,0.84c0.37,0.36,0.66,0.78,0.87,1.26c0.21,0.48,0.31,1.01,0.31,1.58c0,0.15,0,0.33-0.01,0.54
                          c-0.01,0.21-0.03,0.46-0.08,0.75H398.59z M400.85,50.6c-0.65,0-1.18,0.18-1.61,0.55c-0.43,0.37-0.64,0.91-0.64,1.63h4.4
                          c0-0.68-0.2-1.22-0.6-1.6C402,50.79,401.48,50.6,400.85,50.6z"
            />
            <path
              d="M412.86,51.19c-0.17-0.04-0.3-0.06-0.41-0.08s-0.24-0.02-0.41-0.02c-0.89,0-1.56,0.26-2.03,0.77
                          c-0.47,0.51-0.7,1.33-0.7,2.44v3.81h-2.07v-8.84h2.07v1.42c0.1-0.2,0.24-0.4,0.43-0.58c0.18-0.18,0.4-0.35,0.64-0.49
                          c0.25-0.15,0.51-0.26,0.8-0.34c0.29-0.08,0.57-0.12,0.86-0.12c0.33,0,0.6,0.04,0.82,0.13V51.19z"
            />
            <path
              d="M413.92,46.04c0-0.43,0.15-0.79,0.45-1.09c0.3-0.3,0.65-0.45,1.07-0.45c0.43,0,0.79,0.15,1.09,0.45
                          c0.3,0.3,0.45,0.66,0.45,1.09c0,0.42-0.15,0.77-0.45,1.07c-0.3,0.3-0.66,0.45-1.09,0.45c-0.42,0-0.78-0.15-1.07-0.45
                          C414.07,46.82,413.92,46.46,413.92,46.04z M414.42,49.27h2.07v8.84h-2.07V49.27z"
            />
            <path
              d="M424.43,46.69c-0.14-0.04-0.29-0.07-0.44-0.1c-0.15-0.02-0.31-0.04-0.47-0.04s-0.33,0.01-0.49,0.02s-0.31,0.06-0.45,0.15
                          s-0.24,0.23-0.33,0.42c-0.09,0.19-0.13,0.46-0.13,0.8v1.33h2.32V51h-2.32v7.12h-2.07V51h-1.8v-1.73h1.8v-1.1
                          c0-0.85,0.12-1.5,0.35-1.96c0.23-0.46,0.52-0.8,0.85-1.02c0.33-0.22,0.69-0.35,1.05-0.4c0.37-0.04,0.68-0.07,0.93-0.07
                          c0.27,0,0.5,0.02,0.71,0.07c0.21,0.04,0.37,0.1,0.48,0.16V46.69z M426.07,46.06c0-0.4,0.14-0.75,0.42-1.03
                          c0.28-0.28,0.62-0.43,1.03-0.43c0.4,0,0.75,0.14,1.03,0.43c0.28,0.28,0.43,0.63,0.43,1.03c0,0.41-0.14,0.75-0.43,1.02
                          c-0.29,0.28-0.63,0.42-1.03,0.42c-0.41,0-0.75-0.14-1.03-0.42S426.07,46.47,426.07,46.06z M426.48,49.27h2.07v8.84h-2.07V49.27z"
            />
            <path
              d="M433.16,54.32c0.03,0.32,0.08,0.61,0.17,0.89c0.09,0.28,0.22,0.52,0.41,0.73s0.42,0.37,0.71,0.49
                          c0.29,0.12,0.65,0.18,1.08,0.18c0.59,0,1.03-0.11,1.29-0.34s0.46-0.5,0.59-0.82h2.09c-0.06,0.38-0.2,0.74-0.41,1.09
                          c-0.21,0.35-0.48,0.65-0.83,0.92c-0.34,0.27-0.74,0.48-1.2,0.64c-0.46,0.16-0.97,0.24-1.54,0.24c-0.75,0-1.39-0.12-1.94-0.36
                          c-0.55-0.24-1.01-0.57-1.38-0.99c-0.37-0.42-0.64-0.91-0.83-1.48s-0.28-1.18-0.28-1.82c0-0.66,0.1-1.27,0.29-1.84
                          s0.48-1.06,0.84-1.48c0.37-0.42,0.82-0.74,1.37-0.98c0.54-0.23,1.16-0.35,1.86-0.35c0.57,0,1.1,0.1,1.6,0.3
                          c0.5,0.2,0.94,0.48,1.31,0.84c0.37,0.36,0.66,0.78,0.87,1.26c0.21,0.48,0.31,1.01,0.31,1.58c0,0.15,0,0.33-0.01,0.54
                          c-0.01,0.21-0.03,0.46-0.08,0.75H433.16z M435.42,50.6c-0.65,0-1.18,0.18-1.61,0.55c-0.43,0.37-0.64,0.91-0.64,1.63h4.4
                          c0-0.68-0.2-1.22-0.6-1.6C436.57,50.79,436.05,50.6,435.42,50.6z"
            />
            <path
              d="M448.42,56.92c-0.28,0.44-0.67,0.79-1.19,1.04c-0.51,0.25-1.03,0.38-1.55,0.38c-0.67,0-1.28-0.12-1.84-0.37
                          c-0.56-0.25-1.03-0.58-1.43-1.01c-0.4-0.43-0.71-0.92-0.93-1.48c-0.22-0.56-0.33-1.15-0.33-1.78c0-0.62,0.11-1.21,0.33-1.77
                          c0.22-0.56,0.53-1.06,0.93-1.49c0.4-0.43,0.88-0.77,1.43-1.02c0.56-0.25,1.17-0.37,1.84-0.37c0.52,0,1.03,0.13,1.55,0.38
                          c0.51,0.25,0.91,0.6,1.19,1.04v-5.64h2.07v13.28h-2.07V56.92z M445.8,56.5c0.39,0,0.75-0.08,1.07-0.23
                          c0.32-0.15,0.6-0.35,0.83-0.61c0.23-0.25,0.4-0.55,0.53-0.89c0.13-0.34,0.19-0.7,0.19-1.08c0-0.38-0.06-0.74-0.19-1.08
                          c-0.13-0.34-0.3-0.64-0.53-0.89s-0.5-0.46-0.83-0.61c-0.32-0.15-0.68-0.23-1.07-0.23c-0.39,0-0.75,0.08-1.06,0.23
                          c-0.32,0.15-0.59,0.35-0.82,0.61s-0.4,0.55-0.52,0.89s-0.18,0.7-0.18,1.08c0,0.38,0.06,0.74,0.18,1.08
                          c0.12,0.34,0.29,0.64,0.52,0.89c0.23,0.25,0.5,0.46,0.82,0.61C445.05,56.42,445.41,56.5,445.8,56.5z"
            />
            <path
              d="M457.77,55.47c0.18-0.71,0.42-1.31,0.74-1.8s0.69-0.91,1.13-1.25s0.91-0.68,1.41-1.01c0.29-0.18,0.59-0.36,0.9-0.55
                          c0.31-0.19,0.59-0.4,0.84-0.64s0.46-0.5,0.63-0.8c0.16-0.3,0.25-0.65,0.25-1.05c0-0.65-0.18-1.11-0.55-1.39
                          c-0.37-0.28-0.82-0.43-1.37-0.43c-0.57,0-1.06,0.17-1.46,0.52c-0.4,0.35-0.61,0.91-0.61,1.68h-2.18c0-0.67,0.12-1.27,0.35-1.78
                          c0.23-0.52,0.55-0.95,0.95-1.3s0.85-0.61,1.37-0.8c0.51-0.18,1.05-0.28,1.6-0.28c0.57,0,1.1,0.08,1.6,0.25
                          c0.5,0.16,0.93,0.41,1.3,0.75c0.37,0.34,0.65,0.75,0.86,1.25c0.21,0.5,0.31,1.08,0.31,1.74c0,0.61-0.15,1.15-0.44,1.63
                          s-0.63,0.9-1.03,1.26c-0.39,0.36-0.79,0.67-1.19,0.92c-0.4,0.25-0.71,0.46-0.92,0.61c-0.48,0.34-0.87,0.64-1.17,0.89
                          c-0.3,0.25-0.53,0.49-0.69,0.72c-0.17,0.23-0.28,0.46-0.33,0.7c-0.06,0.24-0.08,0.52-0.08,0.83h5.77v1.95h-8.27
                          C457.51,57.06,457.6,56.18,457.77,55.47z"
            />
            <path
              d="M467.45,49.25c0-0.62,0.11-1.21,0.33-1.77c0.22-0.56,0.53-1.06,0.94-1.49c0.4-0.43,0.89-0.77,1.46-1.02
                          s1.21-0.37,1.92-0.37c0.71,0,1.35,0.12,1.93,0.37c0.58,0.25,1.07,0.59,1.48,1.02c0.41,0.43,0.73,0.93,0.95,1.49
                          c0.22,0.56,0.33,1.15,0.33,1.77v4.44c0,0.63-0.11,1.23-0.33,1.78c-0.22,0.56-0.54,1.05-0.95,1.48s-0.9,0.77-1.48,1.01
                          c-0.58,0.25-1.22,0.37-1.93,0.37c-0.71,0-1.35-0.12-1.92-0.37c-0.57-0.25-1.06-0.58-1.46-1.01c-0.41-0.43-0.72-0.92-0.94-1.48
                          c-0.22-0.56-0.33-1.15-0.33-1.78V49.25z M474.6,49.25c0-0.37-0.06-0.71-0.18-1.03c-0.12-0.32-0.29-0.61-0.5-0.85
                          c-0.22-0.25-0.48-0.44-0.79-0.59c-0.31-0.15-0.65-0.22-1.03-0.22c-0.38,0-0.72,0.07-1.02,0.22s-0.56,0.34-0.78,0.59
                          c-0.21,0.25-0.38,0.53-0.49,0.85c-0.11,0.32-0.17,0.67-0.17,1.03v4.44c0,0.37,0.06,0.71,0.17,1.03c0.11,0.32,0.28,0.61,0.49,0.85
                          c0.22,0.25,0.47,0.44,0.78,0.59c0.3,0.15,0.65,0.22,1.02,0.22c0.38,0,0.72-0.07,1.03-0.22c0.31-0.14,0.57-0.34,0.79-0.59
                          c0.21-0.25,0.38-0.53,0.5-0.85c0.12-0.32,0.18-0.67,0.18-1.03V49.25z"
            />
            <path
              d="M478.78,55.47c0.18-0.71,0.42-1.31,0.74-1.8s0.69-0.91,1.13-1.25s0.91-0.68,1.41-1.01c0.29-0.18,0.59-0.36,0.9-0.55
                          c0.31-0.19,0.59-0.4,0.84-0.64s0.46-0.5,0.63-0.8c0.16-0.3,0.25-0.65,0.25-1.05c0-0.65-0.18-1.11-0.55-1.39
                          c-0.37-0.28-0.82-0.43-1.37-0.43c-0.57,0-1.06,0.17-1.46,0.52c-0.4,0.35-0.61,0.91-0.61,1.68h-2.18c0-0.67,0.12-1.27,0.35-1.78
                          c0.23-0.52,0.55-0.95,0.95-1.3s0.85-0.61,1.37-0.8c0.51-0.18,1.05-0.28,1.6-0.28c0.57,0,1.1,0.08,1.6,0.25
                          c0.5,0.16,0.93,0.41,1.3,0.75c0.37,0.34,0.65,0.75,0.86,1.25c0.21,0.5,0.31,1.08,0.31,1.74c0,0.61-0.15,1.15-0.44,1.63
                          s-0.63,0.9-1.03,1.26c-0.39,0.36-0.79,0.67-1.19,0.92c-0.4,0.25-0.71,0.46-0.92,0.61c-0.48,0.34-0.87,0.64-1.17,0.89
                          c-0.3,0.25-0.53,0.49-0.69,0.72c-0.17,0.23-0.28,0.46-0.33,0.7c-0.06,0.24-0.08,0.52-0.08,0.83h5.77v1.95h-8.27
                          C478.51,57.06,478.6,56.18,478.78,55.47z"
            />
            <path d="M493.69,54.13h-4.84v-9.3h2.18v7.34h2.66v-3.98h2.18v3.98h1.82v1.96h-1.82v3.98h-2.18V54.13z" />
          </g>
          <g>
            <path
              className={classNames(styles.st1)}
              d="M184.18,45.89c-3.79,0-5.77,2.61-5.77,6.06c0,3.55,1.97,5.92,5.5,5.92c3.53,0,5.71-2.32,5.71-5.98
                          C189.63,48.12,187.6,45.89,184.18,45.89L184.18,45.89L184.18,45.89z M146.03,45.89c-3.79,0-5.77,2.61-5.77,6.06
                          c0,3.55,1.97,5.92,5.5,5.92c3.53,0,5.71-2.32,5.71-5.98C151.48,48.12,149.45,45.89,146.03,45.89L146.03,45.89L146.03,45.89z
                          M69.22,50.78h4.05v3.82c-1.24,1.08-3.13,1.5-4.66,1.5c-3.82,0-6-2.47-6-6.82c0-4.61,2.34-7.16,5.95-7.16
                          c2.16,0,3.76,0.76,5.29,2.21l1.34-1.53c-1.37-1.5-3.71-2.61-6.4-2.61c-5.4,0-8.58,3.76-8.58,9.03c0,5.16,2.66,8.74,8.42,8.74
                          c3.19,0,5.66-1.4,6.87-2.71v-6.24h-6.29V50.78L69.22,50.78z M129,57.6h2.08v-6.58c0-1.9,0.81-3.21,2.79-3.21
                          c1.84,0,2.42,0.79,2.42,2.71v7.08h2.13v-7.69c0-2.63-1.11-4-3.61-4c-1.71,0-2.98,0.63-3.74,1.71v-7.21H129V57.6L129,57.6z
                          M127.89,42.25v-1.84h-12.16v1.84h4.98V57.6h2.29V42.25H127.89L127.89,42.25z M191.58,57.6h2.08v-6.58c0-1.9,0.82-3.21,2.79-3.21
                          c1.84,0,2.42,0.79,2.42,2.71v7.08H201v-7.69c0-2.63-1.1-4-3.61-4c-1.71,0-2.98,0.63-3.74,1.71l-0.26-1.45h-1.82V57.6L191.58,57.6z
                          M96.31,57.6h2.08v-6.58c0-1.9,0.82-3.21,2.79-3.21c1.84,0,2.42,0.79,2.42,2.71v7.08h2.13v-7.69c0-2.63-1.11-4-3.61-4
                          c-1.71,0-2.97,0.63-3.74,1.71l-0.26-1.45h-1.82V57.6L96.31,57.6z M160.72,57.6h2.08v-6.58c0-1.9,0.81-3.21,2.79-3.21
                          c1.84,0,2.42,0.79,2.42,2.71v7.08h2.13v-7.69c0-2.63-1.11-4-3.61-4c-1.71,0-2.97,0.63-3.74,1.71l-0.26-1.45h-1.82V57.6
                          L160.72,57.6z M184.02,56.13c-2.05,0-3.37-1.42-3.37-4.27c0-2.71,1.37-4.24,3.4-4.24c2.05,0,3.26,1.5,3.26,4.37
                          C187.31,54.76,186.05,56.13,184.02,56.13L184.02,56.13L184.02,56.13z M145.87,56.13c-2.05,0-3.37-1.42-3.37-4.27
                          c0-2.71,1.37-4.24,3.4-4.24c2.05,0,3.26,1.5,3.26,4.37C149.16,54.76,147.9,56.13,145.87,56.13L145.87,56.13L145.87,56.13z
                          M177.65,48.05v-1.87h-2.34v-3.27l-2.18,0.34v2.92h-1.66v1.87h1.66v7.27c0,1.63,0.6,2.61,3,2.61c0.53,0,1.32-0.1,1.79-0.24v-1.63
                          c-0.5,0.13-0.98,0.16-1.32,0.16c-1,0-1.29-0.47-1.29-1.53v-6.64H177.65L177.65,48.05z M113.31,48.05v-1.87h-2.34v-3.27l-2.19,0.34
                          v2.92h-1.66v1.87h1.66v7.27c0,1.63,0.61,2.61,3,2.61c0.53,0,1.32-0.1,1.79-0.24v-1.63c-0.5,0.13-0.97,0.16-1.32,0.16
                          c-1,0-1.29-0.47-1.29-1.53v-6.64H113.31L113.31,48.05z M153.4,57.6h2.13v-7.16c0-1.66,1.03-2.42,2.34-2.42
                          c0.45,0,0.9,0.03,1.37,0.11v-2.08c-0.4-0.13-0.76-0.16-1.13-0.16c-1.18,0-2.11,0.71-2.58,1.68l-0.26-1.4h-1.87V57.6L153.4,57.6z
                          M77.77,57.6h2.13v-7.16c0-1.66,1.03-2.42,2.34-2.42c0.45,0,0.9,0.03,1.37,0.11v-2.08c-0.4-0.13-0.76-0.16-1.13-0.16
                          c-1.18,0-2.11,0.71-2.58,1.68l-0.26-1.4h-1.87V57.6L77.77,57.6z M83.8,54.5c0,1.63,0.95,3.37,3.9,3.37c1.71,0,2.9-0.66,3.71-1.71
                          l0.29,1.45h1.84v-7.79c0-3.34-2-3.92-4.37-3.92c-1.79,0-4.13,0.53-4.71,3.21h2.26c0.29-1,0.97-1.58,2.42-1.58
                          c1.19,0,2.32,0.42,2.32,2.32v0.82h-2.5C85.96,50.65,83.8,51.65,83.8,54.5L83.8,54.5L83.8,54.5z M88.38,56.21
                          c-1.68,0-2.32-0.74-2.32-1.92c0-1.37,0.76-2.08,2.76-2.08h2.63v0.79C91.46,55.07,90.17,56.21,88.38,56.21L88.38,56.21z"
            />
            <path
              className={classNames(styles.st1)}
              d="M49.65,51.97c-1.13,6.16-5.82,11.07-11.87,12.52c-0.57,0.14-1.17,0.14-1.75,0.03c0,0-0.01,0-0.01,0
                          c-0.66-0.12-1.31-0.31-1.92-0.54c-2.98-1.12-5.37-3.43-6.59-6.35c-0.19-0.46-0.35-0.95-0.48-1.44c-0.26-0.96-0.41-1.96-0.41-3
                          c0-0.73,0.08-1.45,0.21-2.14c1.2,2.27,3.07,4.14,5.35,5.35c0.61,0.32,1.26,0.6,1.92,0.82c1.28,0.43,2.64,0.67,4.07,0.67
                          c0.86,0,1.71-0.09,2.52-0.25c0.52-0.1,1.02-0.24,1.51-0.41c3.16-1.05,5.77-3.28,7.3-6.18c0.13-0.24,0.24-0.49,0.35-0.74
                          C49.82,50.86,49.75,51.42,49.65,51.97L49.65,51.97z M18.73,52.79c-0.14-0.57-0.14-1.17-0.03-1.75c0,0,0-0.01,0-0.01
                          c0.12-0.66,0.31-1.31,0.54-1.92c1.12-2.98,3.43-5.37,6.35-6.59c0.46-0.19,0.95-0.35,1.44-0.48c0.96-0.26,1.96-0.41,3-0.41
                          c0.73,0,1.45,0.08,2.14,0.21c-2.27,1.2-4.14,3.07-5.35,5.35c-0.32,0.61-0.6,1.26-0.82,1.92c-0.43,1.28-0.67,2.64-0.67,4.07
                          c0,0.86,0.09,1.71,0.25,2.52c0.1,0.52,0.24,1.02,0.41,1.51c1.05,3.16,3.28,5.77,6.18,7.3c0.24,0.13,0.49,0.24,0.74,0.35
                          c-0.57-0.04-1.12-0.11-1.67-0.21C25.08,63.53,20.17,58.83,18.73,52.79L18.73,52.79z M18.55,46.24
                          c1.13-6.16,5.82-11.07,11.87-12.52c0.57-0.14,1.17-0.14,1.75-0.03c0,0,0.01,0,0.01,0c0.66,0.12,1.31,0.31,1.92,0.54
                          c2.98,1.12,5.37,3.43,6.59,6.35c0.19,0.46,0.35,0.95,0.48,1.44c0.26,0.96,0.41,1.96,0.41,3c0,0.73-0.08,1.45-0.21,2.14
                          c-1.2-2.27-3.07-4.14-5.35-5.35c-0.61-0.32-1.26-0.6-1.92-0.82c-1.28-0.43-2.64-0.67-4.07-0.67c-0.86,0-1.71,0.09-2.52,0.25
                          C27,40.7,26.49,40.84,26,41c-3.16,1.05-5.77,3.28-7.3,6.18c-0.13,0.24-0.24,0.49-0.35,0.74C18.39,47.35,18.45,46.79,18.55,46.24
                          L18.55,46.24z M33.36,55.52c-2.51-1.15-4.52-3.17-5.67-5.67c-0.21-0.47-0.21-1.02,0-1.48c1.15-2.51,3.17-4.52,5.67-5.67
                          c0.47-0.21,1.02-0.21,1.48,0c2.51,1.15,4.52,3.17,5.67,5.67c0.21,0.47,0.21,1.02,0,1.48c-1.15,2.51-3.17,4.52-5.67,5.67
                          C34.38,55.74,33.83,55.74,33.36,55.52L33.36,55.52z M49.48,45.42c0.14,0.57,0.14,1.17,0.03,1.75c0,0,0,0.01,0,0.01
                          c-0.12,0.66-0.31,1.31-0.54,1.92c-1.12,2.98-3.43,5.37-6.35,6.59c-0.46,0.19-0.95,0.35-1.44,0.48c-0.96,0.26-1.96,0.41-3,0.41
                          c-0.73,0-1.45-0.08-2.14-0.21c2.27-1.2,4.14-3.07,5.35-5.35c0.32-0.61,0.6-1.26,0.82-1.92c0.43-1.28,0.67-2.64,0.67-4.07
                          c0-0.86-0.09-1.71-0.25-2.52c-0.1-0.52-0.24-1.02-0.41-1.51c-1.05-3.16-3.28-5.77-6.18-7.3c-0.24-0.13-0.49-0.24-0.74-0.35
                          c0.57,0.04,1.12,0.11,1.67,0.21C43.13,34.68,48.04,39.38,49.48,45.42L49.48,45.42z M50.94,46.19c-0.4-2.3-1.24-4.44-2.46-6.32
                          c-1.33-2.06-3.08-3.81-5.14-5.14c-1.89-1.21-4.03-2.06-6.32-2.46C36.07,32.1,35.1,32,34.1,32s-1.97,0.1-2.91,0.27
                          c-2.3,0.4-4.44,1.24-6.32,2.46c-2.06,1.33-3.82,3.08-5.14,5.14c-1.21,1.89-2.06,4.03-2.46,6.32C17.1,47.14,17,48.11,17,49.1
                          c0,1,0.1,1.97,0.27,2.91c0.4,2.3,1.24,4.44,2.46,6.32c1.33,2.06,3.08,3.82,5.14,5.14c1.89,1.21,4.03,2.06,6.32,2.46
                          c0.95,0.16,1.92,0.27,2.91,0.27s1.97-0.1,2.91-0.27c2.3-0.4,4.44-1.24,6.32-2.46c2.06-1.33,3.81-3.08,5.14-5.14
                          c1.21-1.89,2.06-4.03,2.46-6.32c0.16-0.95,0.27-1.92,0.27-2.91C51.21,48.11,51.11,47.14,50.94,46.19L50.94,46.19z"
            />
          </g>
        </g>
        <g id="_x33_000_GDPR_hvid" className={classNames(styles.st2)}>
          <rect
            x="-0.5"
            y="0.5"
            className={classNames(styles.st3)}
            width="512"
            height="100"
          />
          <g className={classNames(styles.st4)}>
            <rect
              x="211.1"
              y="44.35"
              className={classNames(styles.st0)}
              width="346.96"
              height="24.56"
            />
            <path className="st5" d="M212.66,62.62V43.12h1.78v19.51H212.66z" />
            <path className="st5" d="M226.75,44.35h3.24v13.84h-3.24V44.35z" />
            <path
              className="st5"
              d="M235.27,53.6c0,0.75,0.2,1.34,0.61,1.76c0.41,0.42,0.94,0.63,1.6,0.63c0.51,0,0.95-0.15,1.29-0.44
                          s0.52-0.69,0.52-1.18c0-0.28-0.06-0.51-0.17-0.7c-0.11-0.19-0.26-0.36-0.45-0.5c-0.19-0.14-0.42-0.27-0.68-0.39
                          c-0.26-0.11-0.55-0.23-0.85-0.35c-0.63-0.24-1.24-0.47-1.81-0.71c-0.57-0.24-1.07-0.52-1.49-0.86c-0.42-0.34-0.76-0.74-1.01-1.22
                          c-0.25-0.47-0.38-1.06-0.38-1.76c0-0.63,0.13-1.19,0.41-1.68c0.27-0.49,0.63-0.9,1.08-1.23c0.45-0.33,0.96-0.58,1.55-0.74
                          c0.59-0.17,1.2-0.25,1.83-0.25c0.82,0,1.53,0.12,2.15,0.36c0.62,0.24,1.13,0.56,1.54,0.97c0.41,0.41,0.71,0.88,0.92,1.4
                          c0.2,0.53,0.31,1.09,0.31,1.68h-3.2c0-0.62-0.15-1.11-0.44-1.46c-0.3-0.36-0.72-0.53-1.27-0.53c-0.46,0-0.86,0.12-1.19,0.36
                          c-0.33,0.24-0.49,0.59-0.49,1.07c0,0.46,0.18,0.83,0.54,1.1c0.36,0.27,0.84,0.51,1.43,0.72c0.57,0.2,1.14,0.41,1.72,0.64
                          c0.58,0.23,1.1,0.51,1.56,0.85c0.46,0.34,0.84,0.75,1.14,1.23c0.3,0.48,0.44,1.06,0.44,1.75c0,0.71-0.13,1.34-0.39,1.9
                          c-0.26,0.55-0.62,1.02-1.08,1.39c-0.46,0.38-1.01,0.66-1.64,0.85c-0.63,0.19-1.33,0.29-2.1,0.29c-0.74,0-1.43-0.11-2.07-0.33
                          c-0.64-0.22-1.19-0.54-1.66-0.96s-0.83-0.94-1.1-1.55c-0.26-0.61-0.4-1.31-0.4-2.11H235.27z"
            />
            <path
              className="st5"
              d="M252.25,58.18l-0.53-1.68h-4.92l-0.53,1.68h-3.42l4.94-13.84h2.94l5,13.84H252.25z M249.27,48.3l-1.7,5.69
                          h3.36L249.27,48.3z"
            />
            <path
              className="st5"
              d="M260.33,46.92v3.14h5.24v2.51h-5.24v3.04h5.24v2.57h-8.44V44.35h8.44v2.57H260.33z"
            />
            <path
              className="st5"
              d="M275.41,53.54c0,0.75,0.19,1.33,0.57,1.75s0.88,0.62,1.5,0.62c0.59,0,1.05-0.18,1.36-0.55
                          s0.47-0.79,0.47-1.27c0-0.58-0.15-1.04-0.46-1.39c-0.3-0.35-0.83-0.52-1.58-0.52h-1.25V49.7h1.25c0.71,0,1.2-0.16,1.45-0.47
                          s0.38-0.72,0.38-1.23c0-0.53-0.15-0.92-0.44-1.17c-0.3-0.25-0.68-0.38-1.14-0.38c-0.69,0-1.15,0.18-1.39,0.55
                          c-0.24,0.37-0.37,0.86-0.37,1.46h-3.2c0-0.59,0.1-1.16,0.29-1.69c0.19-0.53,0.48-1,0.88-1.4c0.4-0.4,0.9-0.72,1.51-0.97
                          c0.61-0.24,1.35-0.37,2.2-0.37c0.63,0,1.24,0.08,1.83,0.24s1.1,0.4,1.54,0.72c0.44,0.32,0.79,0.73,1.06,1.22s0.39,1.05,0.39,1.7
                          c0,0.78-0.2,1.44-0.6,1.98c-0.4,0.54-0.88,0.9-1.43,1.09c0.32,0.12,0.61,0.28,0.88,0.47c0.27,0.2,0.51,0.44,0.71,0.71
                          c0.2,0.28,0.37,0.6,0.48,0.96c0.12,0.36,0.18,0.76,0.18,1.2c0,0.66-0.14,1.25-0.41,1.76c-0.27,0.51-0.63,0.95-1.1,1.3
                          c-0.46,0.36-0.99,0.63-1.59,0.81s-1.23,0.28-1.89,0.28c-0.86,0-1.61-0.12-2.27-0.36c-0.66-0.24-1.21-0.57-1.67-1.01
                          c-0.45-0.43-0.8-0.96-1.03-1.56c-0.23-0.61-0.35-1.28-0.35-2.02H275.41z"
            />
            <path
              className="st5"
              d="M284.27,48.99c0-0.67,0.12-1.3,0.37-1.9c0.24-0.59,0.59-1.12,1.05-1.57c0.46-0.45,1.01-0.81,1.66-1.08
                          s1.38-0.4,2.18-0.4c0.8,0,1.53,0.13,2.18,0.4s1.21,0.62,1.67,1.08c0.46,0.46,0.82,0.98,1.07,1.57c0.25,0.59,0.38,1.23,0.38,1.9
                          v4.55c0,0.67-0.12,1.3-0.38,1.9c-0.25,0.59-0.61,1.12-1.07,1.57c-0.46,0.45-1.02,0.81-1.67,1.08s-1.38,0.4-2.18,0.4
                          c-0.8,0-1.53-0.13-2.18-0.4s-1.21-0.62-1.66-1.08c-0.46-0.46-0.8-0.98-1.05-1.57c-0.24-0.59-0.37-1.23-0.37-1.9V48.99z
                          M291.62,48.99c0-0.3-0.05-0.59-0.15-0.86c-0.1-0.27-0.24-0.51-0.42-0.71c-0.18-0.2-0.4-0.37-0.66-0.48
                          c-0.26-0.12-0.55-0.18-0.87-0.18s-0.6,0.06-0.86,0.18c-0.26,0.12-0.47,0.28-0.65,0.48c-0.18,0.21-0.31,0.44-0.41,0.71
                          c-0.09,0.27-0.14,0.56-0.14,0.86v4.55c0,0.3,0.05,0.59,0.14,0.86c0.09,0.27,0.23,0.51,0.41,0.71s0.4,0.37,0.65,0.48
                          c0.26,0.12,0.54,0.18,0.86,0.18s0.61-0.06,0.87-0.18c0.26-0.12,0.48-0.28,0.66-0.48s0.32-0.44,0.42-0.71
                          c0.1-0.27,0.15-0.56,0.15-0.86V48.99z"
            />
            <path
              className="st5"
              d="M296.45,48.99c0-0.67,0.12-1.3,0.37-1.9c0.24-0.59,0.59-1.12,1.05-1.57c0.46-0.45,1.01-0.81,1.66-1.08
                          s1.38-0.4,2.18-0.4c0.8,0,1.53,0.13,2.18,0.4s1.21,0.62,1.67,1.08c0.46,0.46,0.82,0.98,1.07,1.57c0.25,0.59,0.38,1.23,0.38,1.9
                          v4.55c0,0.67-0.12,1.3-0.38,1.9c-0.25,0.59-0.61,1.12-1.07,1.57c-0.46,0.45-1.02,0.81-1.67,1.08s-1.38,0.4-2.18,0.4
                          c-0.8,0-1.53-0.13-2.18-0.4s-1.21-0.62-1.66-1.08c-0.46-0.46-0.8-0.98-1.05-1.57c-0.24-0.59-0.37-1.23-0.37-1.9V48.99z
                          M303.8,48.99c0-0.3-0.05-0.59-0.15-0.86c-0.1-0.27-0.24-0.51-0.42-0.71c-0.18-0.2-0.4-0.37-0.66-0.48
                          c-0.26-0.12-0.55-0.18-0.87-0.18s-0.6,0.06-0.86,0.18c-0.26,0.12-0.47,0.28-0.65,0.48c-0.18,0.21-0.31,0.44-0.41,0.71
                          c-0.09,0.27-0.14,0.56-0.14,0.86v4.55c0,0.3,0.05,0.59,0.14,0.86c0.09,0.27,0.23,0.51,0.41,0.71s0.4,0.37,0.65,0.48
                          c0.26,0.12,0.54,0.18,0.86,0.18s0.61-0.06,0.87-0.18c0.26-0.12,0.48-0.28,0.66-0.48s0.32-0.44,0.42-0.71
                          c0.1-0.27,0.15-0.56,0.15-0.86V48.99z"
            />
            <path
              className="st5"
              d="M308.62,48.99c0-0.67,0.12-1.3,0.37-1.9c0.24-0.59,0.59-1.12,1.05-1.57c0.46-0.45,1.01-0.81,1.66-1.08
                          s1.38-0.4,2.18-0.4c0.8,0,1.53,0.13,2.18,0.4s1.21,0.62,1.67,1.08c0.46,0.46,0.82,0.98,1.07,1.57c0.25,0.59,0.38,1.23,0.38,1.9
                          v4.55c0,0.67-0.12,1.3-0.38,1.9c-0.25,0.59-0.61,1.12-1.07,1.57c-0.46,0.45-1.02,0.81-1.67,1.08s-1.38,0.4-2.18,0.4
                          c-0.8,0-1.53-0.13-2.18-0.4s-1.21-0.62-1.66-1.08c-0.46-0.46-0.8-0.98-1.05-1.57c-0.24-0.59-0.37-1.23-0.37-1.9V48.99z
                          M315.97,48.99c0-0.3-0.05-0.59-0.15-0.86c-0.1-0.27-0.24-0.51-0.42-0.71c-0.18-0.2-0.4-0.37-0.66-0.48
                          c-0.26-0.12-0.55-0.18-0.87-0.18s-0.6,0.06-0.86,0.18c-0.26,0.12-0.47,0.28-0.65,0.48c-0.18,0.21-0.31,0.44-0.41,0.71
                          c-0.09,0.27-0.14,0.56-0.14,0.86v4.55c0,0.3,0.05,0.59,0.14,0.86c0.09,0.27,0.23,0.51,0.41,0.71s0.4,0.37,0.65,0.48
                          c0.26,0.12,0.54,0.18,0.86,0.18s0.61-0.06,0.87-0.18c0.26-0.12,0.48-0.28,0.66-0.48s0.32-0.44,0.42-0.71
                          c0.1-0.27,0.15-0.56,0.15-0.86V48.99z"
            />
            <path
              className="st5"
              d="M333.78,50.87h7.17v2.39h-1.42c-0.15,0.79-0.4,1.51-0.75,2.15c-0.36,0.65-0.82,1.2-1.4,1.66
                          c-0.58,0.46-1.26,0.82-2.05,1.08s-1.67,0.38-2.66,0.38c-1.16,0-2.19-0.2-3.09-0.59s-1.66-0.93-2.27-1.59
                          c-0.61-0.67-1.08-1.44-1.4-2.31c-0.32-0.88-0.48-1.8-0.48-2.78c0-0.95,0.16-1.86,0.49-2.74c0.33-0.88,0.81-1.65,1.43-2.32
                          c0.63-0.67,1.39-1.21,2.28-1.61c0.9-0.4,1.91-0.6,3.04-0.6c0.91,0,1.74,0.13,2.5,0.38s1.41,0.61,1.97,1.05s1,0.96,1.32,1.54
                          s0.53,1.21,0.59,1.87h-3.42c-0.05-0.28-0.16-0.54-0.32-0.79s-0.37-0.47-0.62-0.66s-0.56-0.34-0.9-0.46
                          c-0.34-0.11-0.72-0.17-1.15-0.17c-0.66,0-1.23,0.13-1.72,0.38c-0.49,0.25-0.9,0.59-1.24,1.01s-0.59,0.91-0.75,1.45
                          c-0.17,0.55-0.25,1.11-0.25,1.69c0,0.62,0.08,1.2,0.26,1.75c0.17,0.55,0.43,1.03,0.78,1.44c0.35,0.42,0.79,0.74,1.32,0.99
                          c0.53,0.24,1.16,0.37,1.87,0.37c0.49,0,0.92-0.07,1.3-0.21c0.38-0.14,0.71-0.33,0.99-0.56c0.28-0.24,0.5-0.51,0.67-0.82
                          c0.17-0.31,0.28-0.64,0.34-0.98h-2.45V50.87z"
            />
            <path
              className="st5"
              d="M347.7,44.35c1.15,0,2.16,0.19,3.05,0.58s1.64,0.91,2.25,1.55c0.61,0.65,1.08,1.38,1.4,2.21
                          s0.48,1.69,0.48,2.57c0,0.88-0.16,1.74-0.47,2.57c-0.32,0.83-0.78,1.57-1.39,2.21c-0.61,0.65-1.37,1.16-2.26,1.55
                          c-0.9,0.39-1.92,0.58-3.06,0.58h-5.08V44.35H347.7z M345.82,47.12v8.3h1.72c0.65,0,1.22-0.12,1.73-0.35
                          c0.51-0.23,0.94-0.54,1.28-0.93c0.35-0.39,0.62-0.83,0.8-1.32s0.28-1.01,0.28-1.55c0-0.53-0.09-1.04-0.28-1.54
                          c-0.18-0.5-0.45-0.95-0.8-1.33c-0.35-0.39-0.78-0.7-1.28-0.93c-0.51-0.23-1.08-0.35-1.73-0.35H345.82z"
            />
            <path
              className="st5"
              d="M356.85,44.35h5.79c0.76,0,1.46,0.14,2.08,0.41c0.62,0.27,1.15,0.63,1.59,1.09c0.44,0.46,0.78,0.99,1.02,1.59
                          c0.24,0.61,0.36,1.25,0.36,1.92s-0.12,1.31-0.36,1.92c-0.24,0.61-0.58,1.14-1.02,1.59c-0.44,0.46-0.97,0.82-1.59,1.09
                          c-0.62,0.27-1.31,0.4-2.08,0.4h-2.59v3.83h-3.2V44.35z M362.25,51.74c0.33,0,0.63-0.07,0.91-0.2c0.28-0.13,0.51-0.31,0.7-0.52
                          c0.19-0.22,0.34-0.47,0.45-0.76c0.1-0.29,0.16-0.59,0.16-0.91s-0.05-0.62-0.16-0.91c-0.11-0.29-0.25-0.54-0.45-0.76
                          c-0.19-0.22-0.42-0.39-0.7-0.52c-0.28-0.13-0.58-0.2-0.91-0.2h-2.19v4.78H362.25z"
            />
            <path
              className="st5"
              d="M369.4,44.35h5.81c0.76,0,1.46,0.14,2.08,0.41c0.62,0.27,1.15,0.63,1.59,1.09c0.44,0.46,0.78,0.99,1.02,1.59
                          c0.24,0.61,0.36,1.25,0.36,1.92c0,0.97-0.24,1.86-0.72,2.66c-0.48,0.8-1.16,1.4-2.03,1.81l2.77,4.37h-3.72l-2.45-3.83h-1.5v3.83
                          h-3.2V44.35z M374.8,51.74c0.33,0,0.63-0.07,0.91-0.2c0.28-0.13,0.51-0.31,0.7-0.52c0.19-0.22,0.34-0.47,0.45-0.76
                          c0.1-0.29,0.16-0.59,0.16-0.91s-0.05-0.62-0.16-0.91c-0.11-0.29-0.25-0.54-0.45-0.76c-0.19-0.22-0.42-0.39-0.7-0.52
                          c-0.28-0.13-0.58-0.2-0.91-0.2h-2.19v4.78H374.8z"
            />
            <path
              className="st5"
              d="M391.97,58.18h-1.54l-4.59-13.28h2.3l3.07,9.66l3.05-9.66h2.3L391.97,58.18z"
            />
            <path
              className="st5"
              d="M398.37,54.39c0.03,0.32,0.08,0.61,0.17,0.89c0.09,0.28,0.22,0.52,0.41,0.73s0.42,0.37,0.71,0.49
                          c0.29,0.12,0.65,0.18,1.08,0.18c0.59,0,1.03-0.11,1.29-0.34s0.46-0.5,0.59-0.82h2.09c-0.06,0.38-0.2,0.74-0.41,1.09
                          c-0.21,0.35-0.48,0.65-0.83,0.92c-0.34,0.27-0.74,0.48-1.2,0.64c-0.46,0.16-0.97,0.24-1.54,0.24c-0.75,0-1.39-0.12-1.94-0.36
                          c-0.55-0.24-1.01-0.57-1.38-0.99c-0.37-0.42-0.64-0.91-0.83-1.48s-0.28-1.18-0.28-1.82c0-0.66,0.1-1.27,0.29-1.84
                          s0.48-1.06,0.84-1.48c0.37-0.42,0.82-0.74,1.37-0.98s1.16-0.35,1.86-0.35c0.57,0,1.1,0.1,1.6,0.3c0.5,0.2,0.94,0.48,1.31,0.84
                          s0.66,0.78,0.87,1.26c0.21,0.48,0.31,1.01,0.31,1.58c0,0.15,0,0.33-0.01,0.54c-0.01,0.21-0.03,0.46-0.08,0.75H398.37z
                          M400.62,50.67c-0.65,0-1.18,0.18-1.61,0.55c-0.43,0.37-0.64,0.91-0.64,1.63h4.4c0-0.68-0.2-1.22-0.6-1.6
                          C401.77,50.86,401.26,50.67,400.62,50.67z"
            />
            <path
              className="st5"
              d="M412.63,51.26c-0.17-0.04-0.3-0.06-0.41-0.08s-0.24-0.02-0.41-0.02c-0.89,0-1.56,0.26-2.03,0.77
                          c-0.47,0.51-0.7,1.33-0.7,2.44v3.81h-2.07v-8.84h2.07v1.42c0.1-0.2,0.24-0.4,0.43-0.58c0.18-0.18,0.4-0.35,0.64-0.49
                          c0.25-0.15,0.51-0.26,0.8-0.34c0.29-0.08,0.57-0.12,0.86-0.12c0.33,0,0.6,0.04,0.82,0.13V51.26z"
            />
            <path
              className="st5"
              d="M413.7,46.12c0-0.43,0.15-0.79,0.45-1.09c0.3-0.3,0.65-0.45,1.07-0.45c0.43,0,0.79,0.15,1.09,0.45
                          c0.3,0.3,0.45,0.66,0.45,1.09c0,0.42-0.15,0.78-0.45,1.07c-0.3,0.3-0.66,0.45-1.09,0.45c-0.42,0-0.78-0.15-1.07-0.45
                          C413.84,46.89,413.7,46.53,413.7,46.12z M414.19,49.34h2.07v8.84h-2.07V49.34z"
            />
            <path
              className="st5"
              d="M424.21,46.76c-0.14-0.04-0.29-0.07-0.44-0.09s-0.31-0.04-0.47-0.04s-0.33,0.01-0.49,0.02
                          c-0.16,0.01-0.31,0.06-0.45,0.15s-0.24,0.23-0.33,0.42c-0.09,0.19-0.13,0.46-0.13,0.8v1.33h2.32v1.73h-2.32v7.12h-2.07v-7.12h-1.8
                          v-1.73h1.8v-1.1c0-0.85,0.12-1.5,0.35-1.96c0.23-0.46,0.52-0.8,0.85-1.02c0.33-0.22,0.69-0.35,1.05-0.4
                          c0.37-0.04,0.68-0.07,0.93-0.07c0.27,0,0.5,0.02,0.71,0.07c0.21,0.04,0.37,0.1,0.48,0.16V46.76z M425.84,46.14
                          c0-0.41,0.14-0.75,0.42-1.03c0.28-0.29,0.62-0.43,1.03-0.43c0.4,0,0.75,0.14,1.03,0.43c0.28,0.28,0.43,0.63,0.43,1.03
                          c0,0.4-0.14,0.75-0.43,1.02c-0.29,0.28-0.63,0.42-1.03,0.42c-0.41,0-0.75-0.14-1.03-0.42S425.84,46.54,425.84,46.14z
                          M426.26,49.34h2.07v8.84h-2.07V49.34z"
            />
            <path
              className="st5"
              d="M432.94,54.39c0.03,0.32,0.08,0.61,0.17,0.89c0.09,0.28,0.22,0.52,0.41,0.73s0.42,0.37,0.71,0.49
                          c0.29,0.12,0.65,0.18,1.08,0.18c0.59,0,1.03-0.11,1.29-0.34s0.46-0.5,0.59-0.82h2.09c-0.06,0.38-0.2,0.74-0.41,1.09
                          c-0.21,0.35-0.48,0.65-0.83,0.92c-0.34,0.27-0.74,0.48-1.2,0.64c-0.46,0.16-0.97,0.24-1.54,0.24c-0.75,0-1.39-0.12-1.94-0.36
                          c-0.55-0.24-1.01-0.57-1.38-0.99c-0.37-0.42-0.64-0.91-0.83-1.48s-0.28-1.18-0.28-1.82c0-0.66,0.1-1.27,0.29-1.84
                          s0.48-1.06,0.84-1.48c0.37-0.42,0.82-0.74,1.37-0.98s1.16-0.35,1.86-0.35c0.57,0,1.1,0.1,1.6,0.3c0.5,0.2,0.94,0.48,1.31,0.84
                          s0.66,0.78,0.87,1.26c0.21,0.48,0.31,1.01,0.31,1.58c0,0.15,0,0.33-0.01,0.54c-0.01,0.21-0.03,0.46-0.08,0.75H432.94z
                          M435.2,50.67c-0.65,0-1.18,0.18-1.61,0.55c-0.43,0.37-0.64,0.91-0.64,1.63h4.4c0-0.68-0.2-1.22-0.6-1.6
                          C436.34,50.86,435.83,50.67,435.2,50.67z"
            />
            <path
              className="st5"
              d="M448.19,56.99c-0.28,0.44-0.67,0.79-1.19,1.04c-0.51,0.25-1.03,0.38-1.55,0.38c-0.67,0-1.28-0.12-1.84-0.37
                          c-0.56-0.25-1.03-0.58-1.43-1.01c-0.4-0.43-0.71-0.92-0.93-1.48c-0.22-0.56-0.33-1.15-0.33-1.78c0-0.62,0.11-1.21,0.33-1.77
                          c0.22-0.56,0.53-1.06,0.93-1.49c0.4-0.43,0.88-0.77,1.43-1.02c0.56-0.25,1.17-0.37,1.84-0.37c0.52,0,1.03,0.13,1.55,0.38
                          c0.51,0.25,0.91,0.6,1.19,1.04V44.9h2.07v13.28h-2.07V56.99z M445.57,56.57c0.39,0,0.75-0.08,1.07-0.23
                          c0.32-0.15,0.6-0.35,0.83-0.61c0.23-0.25,0.4-0.55,0.53-0.89c0.13-0.34,0.19-0.7,0.19-1.08c0-0.38-0.06-0.74-0.19-1.08
                          c-0.13-0.34-0.3-0.64-0.53-0.89s-0.5-0.46-0.83-0.61c-0.32-0.15-0.68-0.23-1.07-0.23c-0.39,0-0.75,0.08-1.06,0.23
                          c-0.32,0.15-0.59,0.35-0.82,0.61s-0.4,0.55-0.52,0.89s-0.18,0.7-0.18,1.08c0,0.38,0.06,0.74,0.18,1.08
                          c0.12,0.34,0.29,0.64,0.52,0.89c0.23,0.25,0.5,0.46,0.82,0.61C444.83,56.5,445.18,56.57,445.57,56.57z"
            />
            <path
              className="st5"
              d="M457.55,55.55c0.18-0.71,0.42-1.31,0.74-1.8s0.69-0.91,1.13-1.25s0.91-0.68,1.41-1.01
                          c0.29-0.18,0.59-0.36,0.9-0.55c0.31-0.19,0.59-0.4,0.84-0.64s0.46-0.5,0.63-0.8c0.16-0.3,0.25-0.65,0.25-1.05
                          c0-0.64-0.18-1.11-0.55-1.39c-0.37-0.28-0.82-0.43-1.37-0.43c-0.57,0-1.06,0.17-1.46,0.52c-0.4,0.35-0.61,0.91-0.61,1.68h-2.18
                          c0-0.67,0.12-1.26,0.35-1.78c0.23-0.52,0.55-0.95,0.95-1.3c0.4-0.35,0.85-0.61,1.37-0.8c0.51-0.18,1.05-0.27,1.6-0.27
                          c0.57,0,1.1,0.08,1.6,0.25c0.5,0.17,0.93,0.42,1.3,0.75c0.37,0.33,0.65,0.75,0.86,1.25s0.31,1.08,0.31,1.74
                          c0,0.61-0.15,1.15-0.44,1.63s-0.63,0.9-1.03,1.26c-0.39,0.36-0.79,0.67-1.19,0.92c-0.4,0.25-0.71,0.46-0.92,0.61
                          c-0.48,0.34-0.87,0.64-1.17,0.89c-0.3,0.25-0.53,0.49-0.69,0.72c-0.17,0.23-0.28,0.46-0.33,0.7c-0.06,0.24-0.08,0.52-0.08,0.83
                          h5.77v1.95h-8.27C457.28,57.13,457.37,56.26,457.55,55.55z"
            />
            <path
              className="st5"
              d="M467.22,49.32c0-0.62,0.11-1.21,0.33-1.77c0.22-0.56,0.53-1.06,0.94-1.49c0.4-0.43,0.89-0.77,1.46-1.02
                          c0.57-0.25,1.21-0.37,1.92-0.37c0.71,0,1.35,0.12,1.93,0.37c0.58,0.25,1.07,0.59,1.48,1.02s0.73,0.93,0.95,1.49
                          c0.22,0.56,0.33,1.15,0.33,1.77v4.44c0,0.63-0.11,1.23-0.33,1.78c-0.22,0.56-0.54,1.05-0.95,1.48s-0.9,0.77-1.48,1.01
                          c-0.58,0.25-1.22,0.37-1.93,0.37c-0.71,0-1.35-0.12-1.92-0.37c-0.57-0.25-1.06-0.58-1.46-1.01c-0.41-0.43-0.72-0.92-0.94-1.48
                          c-0.22-0.56-0.33-1.15-0.33-1.78V49.32z M474.38,49.32c0-0.37-0.06-0.71-0.18-1.03c-0.12-0.32-0.29-0.61-0.5-0.85
                          c-0.22-0.25-0.48-0.44-0.79-0.59c-0.31-0.14-0.65-0.22-1.03-0.22c-0.38,0-0.72,0.07-1.02,0.22c-0.3,0.15-0.56,0.34-0.78,0.59
                          c-0.21,0.25-0.38,0.53-0.49,0.85c-0.11,0.32-0.17,0.67-0.17,1.03v4.44c0,0.37,0.06,0.71,0.17,1.03c0.11,0.32,0.28,0.61,0.49,0.85
                          c0.22,0.25,0.47,0.44,0.78,0.59c0.3,0.15,0.65,0.22,1.02,0.22c0.38,0,0.72-0.07,1.03-0.22c0.31-0.14,0.57-0.34,0.79-0.59
                          c0.21-0.25,0.38-0.53,0.5-0.85c0.12-0.32,0.18-0.67,0.18-1.03V49.32z"
            />
            <path
              className="st5"
              d="M478.55,55.55c0.18-0.71,0.42-1.31,0.74-1.8s0.69-0.91,1.13-1.25s0.91-0.68,1.41-1.01
                          c0.29-0.18,0.59-0.36,0.9-0.55c0.31-0.19,0.59-0.4,0.84-0.64s0.46-0.5,0.63-0.8c0.16-0.3,0.25-0.65,0.25-1.05
                          c0-0.64-0.18-1.11-0.55-1.39c-0.37-0.28-0.82-0.43-1.37-0.43c-0.57,0-1.06,0.17-1.46,0.52c-0.4,0.35-0.61,0.91-0.61,1.68h-2.18
                          c0-0.67,0.12-1.26,0.35-1.78c0.23-0.52,0.55-0.95,0.95-1.3c0.4-0.35,0.85-0.61,1.37-0.8c0.51-0.18,1.05-0.27,1.6-0.27
                          c0.57,0,1.1,0.08,1.6,0.25c0.5,0.17,0.93,0.42,1.3,0.75c0.37,0.33,0.65,0.75,0.86,1.25s0.31,1.08,0.31,1.74
                          c0,0.61-0.15,1.15-0.44,1.63s-0.63,0.9-1.03,1.26c-0.39,0.36-0.79,0.67-1.19,0.92c-0.4,0.25-0.71,0.46-0.92,0.61
                          c-0.48,0.34-0.87,0.64-1.17,0.89c-0.3,0.25-0.53,0.49-0.69,0.72c-0.17,0.23-0.28,0.46-0.33,0.7c-0.06,0.24-0.08,0.52-0.08,0.83
                          h5.77v1.95h-8.27C478.29,57.13,478.38,56.26,478.55,55.55z"
            />
            <path
              className="st5"
              d="M493.47,54.2h-4.84v-9.3h2.18v7.34h2.66v-3.98h2.18v3.98h1.82v1.96h-1.82v3.98h-2.18V54.2z"
            />
          </g>
          <g className={classNames(styles.st4)}>
            <path
              className="st5"
              d="M184.41,45.91c-3.8,0-5.78,2.61-5.78,6.06c0,3.56,1.98,5.93,5.51,5.93c3.53,0,5.72-2.32,5.72-5.99
                          C189.87,48.15,187.84,45.91,184.41,45.91L184.41,45.91L184.41,45.91z M146.21,45.91c-3.8,0-5.77,2.61-5.77,6.06
                          c0,3.56,1.98,5.93,5.51,5.93c3.53,0,5.72-2.32,5.72-5.99C151.67,48.15,149.64,45.91,146.21,45.91L146.21,45.91L146.21,45.91z
                          M69.29,50.81h4.06v3.82c-1.24,1.08-3.14,1.5-4.67,1.5c-3.82,0-6.01-2.48-6.01-6.83c0-4.61,2.35-7.17,5.96-7.17
                          c2.16,0,3.77,0.76,5.3,2.21l1.34-1.53c-1.37-1.5-3.72-2.61-6.41-2.61c-5.41,0-8.6,3.77-8.6,9.04c0,5.17,2.66,8.75,8.44,8.75
                          c3.19,0,5.67-1.4,6.88-2.72v-6.25h-6.3V50.81L69.29,50.81z M129.15,57.64h2.08v-6.59c0-1.9,0.82-3.22,2.79-3.22
                          c1.84,0,2.43,0.79,2.43,2.72v7.09h2.14v-7.7c0-2.64-1.11-4.01-3.61-4.01c-1.71,0-2.98,0.63-3.74,1.71v-7.22h-2.08V57.64
                          L129.15,57.64z M128.05,42.27v-1.84h-12.18v1.84h4.98v15.37h2.29V42.27H128.05L128.05,42.27z M191.82,57.64h2.08v-6.59
                          c0-1.9,0.82-3.22,2.79-3.22c1.84,0,2.43,0.79,2.43,2.72v7.09h2.13v-7.7c0-2.64-1.11-4.01-3.61-4.01c-1.71,0-2.98,0.63-3.74,1.71
                          l-0.26-1.45h-1.82V57.64L191.82,57.64z M96.42,57.64h2.08v-6.59c0-1.9,0.82-3.22,2.79-3.22c1.85,0,2.43,0.79,2.43,2.72v7.09h2.14
                          v-7.7c0-2.64-1.11-4.01-3.61-4.01c-1.71,0-2.98,0.63-3.74,1.71l-0.26-1.45h-1.82V57.64L96.42,57.64z M160.92,57.64h2.08v-6.59
                          c0-1.9,0.82-3.22,2.79-3.22c1.85,0,2.43,0.79,2.43,2.72v7.09h2.14v-7.7c0-2.64-1.11-4.01-3.61-4.01c-1.71,0-2.98,0.63-3.74,1.71
                          l-0.26-1.45h-1.82V57.64L160.92,57.64z M184.25,56.16c-2.06,0-3.37-1.42-3.37-4.27c0-2.72,1.37-4.25,3.4-4.25
                          c2.06,0,3.27,1.5,3.27,4.38C187.55,54.79,186.28,56.16,184.25,56.16L184.25,56.16L184.25,56.16z M146.05,56.16
                          c-2.06,0-3.37-1.42-3.37-4.27c0-2.72,1.37-4.25,3.4-4.25c2.05,0,3.27,1.5,3.27,4.38C149.35,54.79,148.08,56.16,146.05,56.16
                          L146.05,56.16L146.05,56.16z M177.87,48.07V46.2h-2.35v-3.27l-2.19,0.34v2.93h-1.66v1.87h1.66v7.28c0,1.63,0.6,2.61,3,2.61
                          c0.53,0,1.32-0.11,1.79-0.24v-1.63c-0.5,0.13-0.98,0.16-1.32,0.16c-1,0-1.29-0.47-1.29-1.53v-6.64H177.87L177.87,48.07z
                          M113.45,48.07V46.2h-2.34v-3.27l-2.19,0.34v2.93h-1.66v1.87h1.66v7.28c0,1.63,0.61,2.61,3.01,2.61c0.53,0,1.32-0.11,1.79-0.24
                          v-1.63c-0.5,0.13-0.98,0.16-1.32,0.16c-1,0-1.29-0.47-1.29-1.53v-6.64H113.45L113.45,48.07z M153.59,57.64h2.14v-7.17
                          c0-1.66,1.03-2.43,2.35-2.43c0.45,0,0.9,0.03,1.37,0.11v-2.08c-0.4-0.13-0.76-0.16-1.13-0.16c-1.19,0-2.11,0.71-2.58,1.69
                          l-0.26-1.4h-1.87V57.64L153.59,57.64z M77.86,57.64h2.14v-7.17c0-1.66,1.03-2.43,2.35-2.43c0.45,0,0.9,0.03,1.37,0.11v-2.08
                          c-0.4-0.13-0.76-0.16-1.13-0.16c-1.19,0-2.11,0.71-2.58,1.69l-0.26-1.4h-1.87V57.64L77.86,57.64z M83.9,54.53
                          c0,1.64,0.95,3.37,3.9,3.37c1.71,0,2.9-0.66,3.72-1.71l0.29,1.45h1.85v-7.8c0-3.35-2-3.93-4.38-3.93c-1.79,0-4.14,0.53-4.72,3.22
                          h2.27c0.29-1,0.98-1.58,2.43-1.58c1.19,0,2.32,0.42,2.32,2.32v0.82h-2.5C86.06,50.68,83.9,51.68,83.9,54.53L83.9,54.53L83.9,54.53
                          z M88.48,56.24c-1.69,0-2.32-0.74-2.32-1.92c0-1.37,0.76-2.08,2.77-2.08h2.64v0.79C91.57,55.11,90.28,56.24,88.48,56.24
                          L88.48,56.24z"
            />
            <path
              className="st5"
              d="M49.7,51.99c-1.13,6.17-5.83,11.09-11.88,12.53c-0.57,0.14-1.17,0.14-1.75,0.03c0,0-0.01,0-0.01,0
                          c-0.67-0.13-1.31-0.31-1.93-0.55c-2.98-1.12-5.38-3.43-6.6-6.36c-0.19-0.46-0.35-0.95-0.48-1.44c-0.26-0.96-0.41-1.96-0.41-3.01
                          c0-0.73,0.08-1.45,0.21-2.15c1.21,2.28,3.08,4.15,5.35,5.35c0.61,0.33,1.26,0.6,1.93,0.82c1.28,0.43,2.65,0.67,4.07,0.67
                          c0.86,0,1.71-0.09,2.52-0.25c0.52-0.1,1.02-0.24,1.52-0.41c3.16-1.05,5.78-3.28,7.31-6.19c0.13-0.24,0.24-0.49,0.36-0.74
                          C49.87,50.89,49.8,51.44,49.7,51.99L49.7,51.99z M18.73,52.81c-0.14-0.57-0.14-1.17-0.03-1.75c0,0,0-0.01,0-0.01
                          c0.13-0.67,0.31-1.31,0.55-1.93c1.12-2.98,3.43-5.38,6.36-6.6c0.46-0.19,0.95-0.35,1.44-0.48c0.96-0.26,1.96-0.41,3.01-0.41
                          c0.73,0,1.45,0.08,2.15,0.21c-2.28,1.21-4.15,3.08-5.35,5.35c-0.33,0.61-0.6,1.26-0.82,1.93c-0.43,1.28-0.67,2.65-0.67,4.07
                          c0,0.86,0.09,1.71,0.25,2.52c0.1,0.52,0.24,1.02,0.41,1.52c1.05,3.16,3.28,5.78,6.19,7.31c0.24,0.13,0.49,0.24,0.74,0.36
                          c-0.57-0.04-1.13-0.11-1.68-0.21C25.09,63.57,20.17,58.87,18.73,52.81L18.73,52.81z M18.56,46.26
                          c1.13-6.17,5.83-11.09,11.88-12.53c0.57-0.14,1.17-0.14,1.75-0.03c0,0,0.01,0,0.01,0c0.67,0.13,1.31,0.31,1.93,0.55
                          c2.98,1.12,5.38,3.43,6.6,6.36c0.19,0.46,0.35,0.95,0.48,1.44c0.26,0.96,0.41,1.96,0.41,3.01c0,0.73-0.08,1.45-0.21,2.15
                          c-1.21-2.28-3.08-4.15-5.35-5.35c-0.61-0.33-1.26-0.6-1.93-0.82c-1.28-0.43-2.65-0.67-4.07-0.67c-0.86,0-1.71,0.09-2.52,0.25
                          c-0.52,0.1-1.02,0.24-1.52,0.41c-3.16,1.05-5.78,3.28-7.31,6.19c-0.13,0.24-0.24,0.49-0.36,0.74
                          C18.39,47.37,18.46,46.81,18.56,46.26L18.56,46.26z M33.39,55.55c-2.51-1.15-4.53-3.17-5.68-5.68c-0.21-0.47-0.21-1.02,0-1.48
                          c1.15-2.51,3.17-4.53,5.68-5.68c0.47-0.21,1.02-0.21,1.48,0c2.51,1.15,4.53,3.17,5.68,5.68c0.21,0.47,0.21,1.02,0,1.48
                          c-1.15,2.51-3.17,4.53-5.68,5.68C34.4,55.77,33.85,55.77,33.39,55.55L33.39,55.55z M49.53,45.44c0.14,0.57,0.14,1.17,0.03,1.75
                          c0,0,0,0.01,0,0.01c-0.13,0.67-0.31,1.31-0.55,1.93c-1.12,2.98-3.43,5.38-6.36,6.6c-0.46,0.19-0.95,0.35-1.44,0.48
                          c-0.96,0.26-1.96,0.41-3.01,0.41c-0.73,0-1.45-0.08-2.15-0.21c2.28-1.21,4.15-3.08,5.35-5.35c0.33-0.61,0.6-1.26,0.82-1.93
                          c0.43-1.28,0.67-2.65,0.67-4.07c0-0.86-0.09-1.71-0.25-2.52c-0.1-0.52-0.24-1.02-0.41-1.52c-1.05-3.16-3.28-5.78-6.19-7.31
                          c-0.24-0.13-0.49-0.24-0.74-0.36c0.57,0.04,1.13,0.11,1.68,0.21C43.17,34.69,48.08,39.39,49.53,45.44L49.53,45.44z M50.99,46.21
                          c-0.4-2.3-1.24-4.44-2.46-6.33c-1.33-2.06-3.08-3.82-5.15-5.15c-1.89-1.22-4.03-2.07-6.33-2.46C36.1,32.1,35.13,32,34.13,32
                          s-1.97,0.1-2.92,0.27c-2.3,0.4-4.44,1.24-6.33,2.46c-2.06,1.33-3.82,3.09-5.15,5.15c-1.22,1.89-2.07,4.03-2.46,6.33
                          C17.1,47.16,17,48.13,17,49.13c0,1,0.1,1.97,0.27,2.92c0.4,2.3,1.24,4.44,2.46,6.33c1.33,2.06,3.09,3.82,5.15,5.15
                          c1.89,1.22,4.03,2.07,6.33,2.46c0.95,0.16,1.92,0.27,2.92,0.27s1.97-0.1,2.92-0.27c2.3-0.4,4.44-1.24,6.33-2.46
                          c2.06-1.33,3.82-3.09,5.15-5.15c1.22-1.89,2.07-4.03,2.46-6.33c0.16-0.95,0.27-1.92,0.27-2.92
                          C51.26,48.13,51.15,47.16,50.99,46.21L50.99,46.21z"
            />
          </g>
        </g>
        <g id="_x33_402_sort" className={classNames(styles.st2)}>
          <g className={classNames(styles.st4)}>
            <rect
              x="211.39"
              y="44.28"
              className={classNames(styles.st0)}
              width="300.55"
              height="18.28"
            />
            <path d="M212.94,62.55V43.05h1.78v19.51H212.94z" />
            <path d="M227.04,44.28h3.24v13.84h-3.24V44.28z" />
            <path
              d="M235.56,53.53c0,0.75,0.2,1.34,0.61,1.76c0.41,0.42,0.94,0.63,1.6,0.63c0.51,0,0.95-0.15,1.29-0.44s0.52-0.69,0.52-1.18
                          c0-0.28-0.06-0.51-0.17-0.7c-0.11-0.19-0.26-0.36-0.45-0.5c-0.19-0.14-0.42-0.27-0.68-0.39c-0.26-0.11-0.55-0.23-0.85-0.35
                          c-0.63-0.24-1.24-0.47-1.81-0.71c-0.57-0.24-1.07-0.52-1.49-0.86s-0.76-0.74-1.01-1.22c-0.25-0.47-0.38-1.06-0.38-1.76
                          c0-0.63,0.13-1.19,0.41-1.68c0.27-0.49,0.63-0.9,1.08-1.23c0.45-0.33,0.96-0.58,1.55-0.74c0.59-0.16,1.2-0.25,1.83-0.25
                          c0.82,0,1.53,0.12,2.15,0.36c0.62,0.24,1.13,0.56,1.54,0.97c0.41,0.41,0.71,0.88,0.92,1.4c0.2,0.53,0.31,1.09,0.31,1.68h-3.2
                          c0-0.62-0.15-1.11-0.44-1.46s-0.72-0.53-1.27-0.53c-0.46,0-0.86,0.12-1.19,0.36c-0.33,0.24-0.49,0.59-0.49,1.07
                          c0,0.46,0.18,0.83,0.54,1.1c0.36,0.27,0.84,0.51,1.43,0.72c0.57,0.2,1.14,0.41,1.72,0.64c0.58,0.23,1.1,0.51,1.56,0.85
                          c0.46,0.34,0.84,0.75,1.14,1.23c0.3,0.48,0.44,1.06,0.44,1.75c0,0.71-0.13,1.34-0.39,1.9c-0.26,0.55-0.62,1.02-1.08,1.39
                          c-0.46,0.38-1.01,0.66-1.64,0.85c-0.63,0.19-1.33,0.29-2.1,0.29c-0.74,0-1.43-0.11-2.07-0.33c-0.64-0.22-1.19-0.54-1.66-0.96
                          s-0.83-0.94-1.1-1.55c-0.26-0.61-0.4-1.31-0.4-2.11H235.56z"
            />
            <path
              d="M252.53,58.11L252,56.43h-4.92l-0.53,1.68h-3.42l4.94-13.84h2.94l5,13.84H252.53z M249.55,48.23l-1.7,5.69h3.36
                          L249.55,48.23z"
            />
            <path d="M260.62,46.85v3.14h5.24v2.51h-5.24v3.04h5.24v2.57h-8.44V44.28h8.44v2.57H260.62z" />
            <path
              d="M275.7,53.47c0,0.75,0.19,1.33,0.57,1.75c0.38,0.42,0.88,0.62,1.5,0.62c0.59,0,1.05-0.18,1.36-0.55s0.47-0.79,0.47-1.27
                          c0-0.58-0.15-1.04-0.45-1.39c-0.3-0.35-0.83-0.52-1.58-0.52h-1.25v-2.47h1.25c0.71,0,1.2-0.16,1.45-0.47
                          c0.26-0.32,0.39-0.72,0.39-1.23c0-0.53-0.15-0.92-0.45-1.17c-0.3-0.25-0.67-0.38-1.14-0.38c-0.68,0-1.15,0.19-1.39,0.55
                          c-0.24,0.37-0.37,0.86-0.37,1.46h-3.2c0-0.59,0.1-1.16,0.29-1.69c0.19-0.53,0.48-1,0.88-1.4c0.4-0.4,0.9-0.72,1.51-0.97
                          c0.61-0.24,1.35-0.37,2.2-0.37c0.63,0,1.24,0.08,1.83,0.24c0.59,0.16,1.1,0.4,1.54,0.72c0.44,0.32,0.79,0.73,1.06,1.22
                          s0.4,1.05,0.4,1.7c0,0.78-0.2,1.44-0.6,1.98c-0.4,0.54-0.88,0.9-1.43,1.09c0.32,0.12,0.61,0.28,0.88,0.47
                          c0.27,0.2,0.51,0.44,0.71,0.71c0.2,0.28,0.37,0.6,0.48,0.96c0.12,0.36,0.18,0.76,0.18,1.2c0,0.66-0.14,1.25-0.41,1.76
                          c-0.27,0.51-0.64,0.95-1.1,1.3c-0.46,0.36-0.99,0.63-1.59,0.81s-1.23,0.28-1.89,0.28c-0.86,0-1.61-0.12-2.27-0.36
                          c-0.66-0.24-1.22-0.57-1.67-1.01c-0.45-0.43-0.8-0.96-1.03-1.56c-0.23-0.61-0.35-1.28-0.35-2.02H275.7z"
            />
            <path d="M290.4,54.3h-5.53V44.28h3.2v7.31h2.33v-3.81h3.18v3.81h1.68v2.71h-1.68v3.81h-3.18V54.3z" />
            <path
              d="M296.63,48.92c0-0.67,0.12-1.3,0.37-1.9c0.24-0.59,0.59-1.12,1.05-1.57s1.01-0.81,1.66-1.08c0.65-0.26,1.38-0.4,2.18-0.4
                          c0.8,0,1.53,0.13,2.18,0.4c0.65,0.26,1.21,0.62,1.67,1.08c0.46,0.45,0.82,0.98,1.07,1.57c0.25,0.59,0.38,1.23,0.38,1.9v4.55
                          c0,0.67-0.12,1.3-0.38,1.9c-0.25,0.59-0.61,1.12-1.07,1.57c-0.46,0.45-1.02,0.81-1.67,1.08s-1.38,0.4-2.18,0.4
                          c-0.8,0-1.53-0.13-2.18-0.4s-1.21-0.62-1.66-1.08c-0.46-0.46-0.8-0.98-1.05-1.57c-0.24-0.59-0.37-1.23-0.37-1.9V48.92z
                          M303.98,48.92c0-0.3-0.05-0.59-0.15-0.86c-0.1-0.27-0.24-0.51-0.42-0.71s-0.4-0.37-0.66-0.48s-0.55-0.18-0.87-0.18
                          s-0.6,0.06-0.86,0.18s-0.47,0.28-0.65,0.48s-0.31,0.44-0.41,0.71c-0.09,0.27-0.14,0.56-0.14,0.86v4.55c0,0.3,0.05,0.59,0.14,0.86
                          c0.09,0.27,0.23,0.51,0.41,0.71s0.4,0.37,0.65,0.48c0.26,0.12,0.54,0.18,0.86,0.18s0.61-0.06,0.87-0.18
                          c0.26-0.12,0.48-0.28,0.66-0.48s0.32-0.44,0.42-0.71c0.1-0.27,0.15-0.56,0.15-0.86V48.92z"
            />
            <path
              d="M309.15,55.29c0.18-0.76,0.43-1.41,0.76-1.94c0.33-0.53,0.71-0.97,1.16-1.34s0.93-0.72,1.47-1.05
                          c0.41-0.24,0.79-0.46,1.14-0.68c0.35-0.22,0.66-0.44,0.92-0.66s0.47-0.47,0.62-0.74c0.15-0.27,0.23-0.58,0.23-0.92
                          c0-0.55-0.15-0.95-0.45-1.2c-0.3-0.24-0.68-0.37-1.13-0.37c-0.5,0-0.92,0.16-1.25,0.46s-0.5,0.83-0.5,1.55h-3.18
                          c0-0.63,0.11-1.22,0.33-1.76c0.22-0.54,0.54-1.01,0.96-1.4c0.42-0.4,0.94-0.71,1.54-0.93c0.61-0.22,1.3-0.34,2.09-0.34
                          c0.63,0,1.24,0.08,1.81,0.25c0.57,0.17,1.08,0.42,1.51,0.75c0.44,0.34,0.78,0.77,1.04,1.3s0.39,1.16,0.39,1.89
                          c0,0.58-0.13,1.11-0.38,1.58c-0.25,0.47-0.56,0.9-0.92,1.27s-0.75,0.7-1.18,0.98c-0.42,0.28-0.8,0.52-1.15,0.72
                          c-0.47,0.32-0.87,0.59-1.18,0.82c-0.31,0.23-0.56,0.45-0.74,0.64c-0.18,0.2-0.31,0.4-0.38,0.61c-0.07,0.21-0.11,0.45-0.11,0.73
                          h5.93v2.59h-9.61C308.88,56.99,308.97,56.05,309.15,55.29z"
            />
            <path d="M330.37,58.11h-1.54l-4.59-13.28h2.3l3.07,9.66l3.05-9.66h2.3L330.37,58.11z" />
            <path
              d="M336.76,54.32c0.03,0.32,0.08,0.61,0.17,0.89c0.09,0.28,0.22,0.52,0.41,0.73s0.42,0.37,0.71,0.49
                          c0.29,0.12,0.65,0.18,1.08,0.18c0.59,0,1.03-0.11,1.29-0.34s0.46-0.5,0.59-0.82h2.09c-0.06,0.38-0.2,0.74-0.41,1.09
                          c-0.21,0.35-0.48,0.65-0.83,0.92c-0.34,0.27-0.74,0.48-1.2,0.64c-0.46,0.16-0.97,0.24-1.54,0.24c-0.75,0-1.39-0.12-1.94-0.36
                          c-0.55-0.24-1.01-0.57-1.38-0.99c-0.37-0.42-0.64-0.91-0.83-1.48s-0.28-1.18-0.28-1.82c0-0.66,0.1-1.27,0.29-1.84
                          s0.48-1.06,0.84-1.48c0.37-0.42,0.82-0.74,1.37-0.98c0.54-0.23,1.16-0.35,1.86-0.35c0.57,0,1.1,0.1,1.6,0.3
                          c0.5,0.2,0.94,0.48,1.31,0.84c0.37,0.36,0.66,0.78,0.87,1.26c0.21,0.48,0.31,1.01,0.31,1.58c0,0.15,0,0.33-0.01,0.54
                          c-0.01,0.21-0.03,0.46-0.08,0.75H336.76z M339.02,50.6c-0.65,0-1.18,0.18-1.61,0.55c-0.43,0.37-0.64,0.91-0.64,1.63h4.4
                          c0-0.68-0.2-1.22-0.6-1.6C340.17,50.79,339.65,50.6,339.02,50.6z"
            />
            <path
              d="M351.03,51.19c-0.17-0.04-0.3-0.06-0.41-0.08s-0.24-0.02-0.41-0.02c-0.89,0-1.56,0.26-2.03,0.77
                          c-0.47,0.51-0.7,1.33-0.7,2.44v3.81h-2.07v-8.84h2.07v1.42c0.1-0.2,0.24-0.4,0.43-0.58c0.18-0.18,0.4-0.35,0.64-0.49
                          c0.25-0.15,0.51-0.26,0.8-0.34c0.29-0.08,0.57-0.12,0.86-0.12c0.33,0,0.6,0.04,0.82,0.13V51.19z"
            />
            <path
              d="M352.09,46.04c0-0.43,0.15-0.79,0.45-1.09c0.3-0.3,0.65-0.45,1.07-0.45c0.43,0,0.79,0.15,1.09,0.45
                          c0.3,0.3,0.45,0.66,0.45,1.09c0,0.42-0.15,0.77-0.45,1.07c-0.3,0.3-0.66,0.45-1.09,0.45c-0.42,0-0.78-0.15-1.07-0.45
                          C352.24,46.82,352.09,46.46,352.09,46.04z M352.59,49.27h2.07v8.84h-2.07V49.27z"
            />
            <path
              d="M362.61,46.69c-0.14-0.04-0.29-0.07-0.44-0.1c-0.15-0.02-0.31-0.04-0.47-0.04s-0.33,0.01-0.49,0.02s-0.31,0.06-0.45,0.15
                          s-0.24,0.23-0.33,0.42c-0.09,0.19-0.13,0.46-0.13,0.8v1.33h2.32V51h-2.32v7.12h-2.07V51h-1.8v-1.73h1.8v-1.1
                          c0-0.85,0.12-1.5,0.35-1.96c0.23-0.46,0.52-0.8,0.85-1.02c0.33-0.22,0.69-0.35,1.05-0.4c0.37-0.04,0.68-0.07,0.93-0.07
                          c0.27,0,0.5,0.02,0.71,0.07c0.21,0.04,0.37,0.1,0.48,0.16V46.69z M364.24,46.06c0-0.4,0.14-0.75,0.42-1.03
                          c0.28-0.28,0.62-0.43,1.03-0.43c0.4,0,0.75,0.14,1.03,0.43c0.28,0.28,0.43,0.63,0.43,1.03c0,0.41-0.14,0.75-0.43,1.02
                          c-0.29,0.28-0.63,0.42-1.03,0.42c-0.41,0-0.75-0.14-1.03-0.42S364.24,46.47,364.24,46.06z M364.66,49.27h2.07v8.84h-2.07V49.27z"
            />
            <path
              d="M371.33,54.32c0.03,0.32,0.08,0.61,0.17,0.89c0.09,0.28,0.22,0.52,0.41,0.73s0.42,0.37,0.71,0.49
                          c0.29,0.12,0.65,0.18,1.08,0.18c0.59,0,1.03-0.11,1.29-0.34s0.46-0.5,0.59-0.82h2.09c-0.06,0.38-0.2,0.74-0.41,1.09
                          c-0.21,0.35-0.48,0.65-0.83,0.92c-0.34,0.27-0.74,0.48-1.2,0.64c-0.46,0.16-0.97,0.24-1.54,0.24c-0.75,0-1.39-0.12-1.94-0.36
                          c-0.55-0.24-1.01-0.57-1.38-0.99c-0.37-0.42-0.64-0.91-0.83-1.48s-0.28-1.18-0.28-1.82c0-0.66,0.1-1.27,0.29-1.84
                          s0.48-1.06,0.84-1.48c0.37-0.42,0.82-0.74,1.37-0.98c0.54-0.23,1.16-0.35,1.86-0.35c0.57,0,1.1,0.1,1.6,0.3
                          c0.5,0.2,0.94,0.48,1.31,0.84c0.37,0.36,0.66,0.78,0.87,1.26c0.21,0.48,0.31,1.01,0.31,1.58c0,0.15,0,0.33-0.01,0.54
                          c-0.01,0.21-0.03,0.46-0.08,0.75H371.33z M373.59,50.6c-0.65,0-1.18,0.18-1.61,0.55c-0.43,0.37-0.64,0.91-0.64,1.63h4.4
                          c0-0.68-0.2-1.22-0.6-1.6C374.74,50.79,374.22,50.6,373.59,50.6z"
            />
            <path
              d="M386.59,56.92c-0.28,0.44-0.67,0.79-1.19,1.04c-0.51,0.25-1.03,0.38-1.55,0.38c-0.67,0-1.28-0.12-1.84-0.37
                          c-0.56-0.25-1.03-0.58-1.43-1.01c-0.4-0.43-0.71-0.92-0.93-1.48c-0.22-0.56-0.33-1.15-0.33-1.78c0-0.62,0.11-1.21,0.33-1.77
                          c0.22-0.56,0.53-1.06,0.93-1.49c0.4-0.43,0.88-0.77,1.43-1.02c0.56-0.25,1.17-0.37,1.84-0.37c0.52,0,1.03,0.13,1.55,0.38
                          c0.51,0.25,0.91,0.6,1.19,1.04v-5.64h2.07v13.28h-2.07V56.92z M383.97,56.5c0.39,0,0.75-0.08,1.07-0.23
                          c0.32-0.15,0.6-0.35,0.83-0.61c0.23-0.25,0.4-0.55,0.53-0.89c0.13-0.34,0.19-0.7,0.19-1.08c0-0.38-0.06-0.74-0.19-1.08
                          c-0.13-0.34-0.3-0.64-0.53-0.89s-0.5-0.46-0.83-0.61c-0.32-0.15-0.68-0.23-1.07-0.23c-0.39,0-0.75,0.08-1.06,0.23
                          c-0.32,0.15-0.59,0.35-0.82,0.61s-0.4,0.55-0.52,0.89s-0.18,0.7-0.18,1.08c0,0.38,0.06,0.74,0.18,1.08
                          c0.12,0.34,0.29,0.64,0.52,0.89c0.23,0.25,0.5,0.46,0.82,0.61C383.22,56.42,383.58,56.5,383.97,56.5z"
            />
            <path
              d="M395.94,55.47c0.18-0.71,0.42-1.31,0.74-1.8s0.69-0.91,1.13-1.25s0.91-0.68,1.41-1.01c0.29-0.18,0.59-0.36,0.9-0.55
                          c0.31-0.19,0.59-0.4,0.84-0.64s0.46-0.5,0.63-0.8c0.16-0.3,0.25-0.65,0.25-1.05c0-0.65-0.18-1.11-0.55-1.39
                          c-0.37-0.28-0.82-0.43-1.37-0.43c-0.57,0-1.06,0.17-1.46,0.52c-0.4,0.35-0.61,0.91-0.61,1.68h-2.18c0-0.67,0.12-1.27,0.35-1.78
                          c0.23-0.52,0.55-0.95,0.95-1.3s0.85-0.61,1.37-0.8c0.51-0.18,1.05-0.28,1.6-0.28c0.57,0,1.1,0.08,1.6,0.25
                          c0.5,0.16,0.93,0.41,1.3,0.75c0.37,0.34,0.65,0.75,0.86,1.25c0.21,0.5,0.31,1.08,0.31,1.74c0,0.61-0.15,1.15-0.44,1.63
                          s-0.63,0.9-1.03,1.26c-0.39,0.36-0.79,0.67-1.19,0.92c-0.4,0.25-0.71,0.46-0.92,0.61c-0.48,0.34-0.87,0.64-1.17,0.89
                          c-0.3,0.25-0.53,0.49-0.69,0.72c-0.17,0.23-0.28,0.46-0.33,0.7c-0.06,0.24-0.08,0.52-0.08,0.83h5.77v1.95h-8.27
                          C395.68,57.06,395.77,56.18,395.94,55.47z"
            />
            <path
              d="M405.62,49.25c0-0.62,0.11-1.21,0.33-1.77c0.22-0.56,0.53-1.06,0.94-1.49c0.4-0.43,0.89-0.77,1.46-1.02
                          s1.21-0.37,1.92-0.37c0.71,0,1.35,0.12,1.93,0.37c0.58,0.25,1.07,0.59,1.48,1.02c0.41,0.43,0.73,0.93,0.95,1.49
                          c0.22,0.56,0.33,1.15,0.33,1.77v4.44c0,0.63-0.11,1.23-0.33,1.78c-0.22,0.56-0.54,1.05-0.95,1.48s-0.9,0.77-1.48,1.01
                          c-0.58,0.25-1.22,0.37-1.93,0.37c-0.71,0-1.35-0.12-1.92-0.37c-0.57-0.25-1.06-0.58-1.46-1.01c-0.41-0.43-0.72-0.92-0.94-1.48
                          c-0.22-0.56-0.33-1.15-0.33-1.78V49.25z M412.78,49.25c0-0.37-0.06-0.71-0.18-1.03c-0.12-0.32-0.29-0.61-0.5-0.85
                          c-0.22-0.25-0.48-0.44-0.79-0.59c-0.31-0.15-0.65-0.22-1.03-0.22c-0.38,0-0.72,0.07-1.02,0.22s-0.56,0.34-0.78,0.59
                          c-0.21,0.25-0.38,0.53-0.49,0.85c-0.11,0.32-0.17,0.67-0.17,1.03v4.44c0,0.37,0.06,0.71,0.17,1.03c0.11,0.32,0.28,0.61,0.49,0.85
                          c0.22,0.25,0.47,0.44,0.78,0.59c0.3,0.15,0.65,0.22,1.02,0.22c0.38,0,0.72-0.07,1.03-0.22c0.31-0.14,0.57-0.34,0.79-0.59
                          c0.21-0.25,0.38-0.53,0.5-0.85c0.12-0.32,0.18-0.67,0.18-1.03V49.25z"
            />
            <path
              d="M416.95,55.47c0.18-0.71,0.42-1.31,0.74-1.8s0.69-0.91,1.13-1.25s0.91-0.68,1.41-1.01c0.29-0.18,0.59-0.36,0.9-0.55
                          c0.31-0.19,0.59-0.4,0.84-0.64s0.46-0.5,0.63-0.8c0.16-0.3,0.25-0.65,0.25-1.05c0-0.65-0.18-1.11-0.55-1.39
                          c-0.37-0.28-0.82-0.43-1.37-0.43c-0.57,0-1.06,0.17-1.46,0.52c-0.4,0.35-0.61,0.91-0.61,1.68h-2.18c0-0.67,0.12-1.27,0.35-1.78
                          c0.23-0.52,0.55-0.95,0.95-1.3s0.85-0.61,1.37-0.8c0.51-0.18,1.05-0.28,1.6-0.28c0.57,0,1.1,0.08,1.6,0.25
                          c0.5,0.16,0.93,0.41,1.3,0.75c0.37,0.34,0.65,0.75,0.86,1.25c0.21,0.5,0.31,1.08,0.31,1.74c0,0.61-0.15,1.15-0.44,1.63
                          s-0.63,0.9-1.03,1.26c-0.39,0.36-0.79,0.67-1.19,0.92c-0.4,0.25-0.71,0.46-0.92,0.61c-0.48,0.34-0.87,0.64-1.17,0.89
                          c-0.3,0.25-0.53,0.49-0.69,0.72c-0.17,0.23-0.28,0.46-0.33,0.7c-0.06,0.24-0.08,0.52-0.08,0.83h5.77v1.95h-8.27
                          C416.68,57.06,416.77,56.18,416.95,55.47z"
            />
            <path d="M431.86,54.13h-4.84v-9.3h2.18v7.34h2.66v-3.98h2.18v3.98h1.82v1.96h-1.82v3.98h-2.18V54.13z" />
          </g>
          <g className={classNames(styles.st4)}>
            <path
              className={classNames(styles.st1)}
              d="M184.18,45.89c-3.79,0-5.77,2.61-5.77,6.06c0,3.55,1.97,5.92,5.5,5.92c3.53,0,5.71-2.32,5.71-5.98
                          C189.63,48.12,187.6,45.89,184.18,45.89L184.18,45.89L184.18,45.89z M146.03,45.89c-3.79,0-5.77,2.61-5.77,6.06
                          c0,3.55,1.97,5.92,5.5,5.92c3.53,0,5.71-2.32,5.71-5.98C151.48,48.12,149.45,45.89,146.03,45.89L146.03,45.89L146.03,45.89z
                          M69.22,50.78h4.05v3.82c-1.24,1.08-3.13,1.5-4.66,1.5c-3.82,0-6-2.47-6-6.82c0-4.61,2.34-7.16,5.95-7.16
                          c2.16,0,3.76,0.76,5.29,2.21l1.34-1.53c-1.37-1.5-3.71-2.61-6.4-2.61c-5.4,0-8.58,3.76-8.58,9.03c0,5.16,2.66,8.74,8.42,8.74
                          c3.19,0,5.66-1.4,6.87-2.71v-6.24h-6.29V50.78L69.22,50.78z M129,57.6h2.08v-6.58c0-1.9,0.81-3.21,2.79-3.21
                          c1.84,0,2.42,0.79,2.42,2.71v7.08h2.13v-7.69c0-2.63-1.11-4-3.61-4c-1.71,0-2.98,0.63-3.74,1.71v-7.21H129V57.6L129,57.6z
                          M127.89,42.25v-1.84h-12.16v1.84h4.98V57.6h2.29V42.25H127.89L127.89,42.25z M191.58,57.6h2.08v-6.58c0-1.9,0.82-3.21,2.79-3.21
                          c1.84,0,2.42,0.79,2.42,2.71v7.08H201v-7.69c0-2.63-1.1-4-3.61-4c-1.71,0-2.98,0.63-3.74,1.71l-0.26-1.45h-1.82V57.6L191.58,57.6z
                          M96.31,57.6h2.08v-6.58c0-1.9,0.82-3.21,2.79-3.21c1.84,0,2.42,0.79,2.42,2.71v7.08h2.13v-7.69c0-2.63-1.11-4-3.61-4
                          c-1.71,0-2.97,0.63-3.74,1.71l-0.26-1.45h-1.82V57.6L96.31,57.6z M160.72,57.6h2.08v-6.58c0-1.9,0.81-3.21,2.79-3.21
                          c1.84,0,2.42,0.79,2.42,2.71v7.08h2.13v-7.69c0-2.63-1.11-4-3.61-4c-1.71,0-2.97,0.63-3.74,1.71l-0.26-1.45h-1.82V57.6
                          L160.72,57.6z M184.02,56.13c-2.05,0-3.37-1.42-3.37-4.27c0-2.71,1.37-4.24,3.4-4.24c2.05,0,3.26,1.5,3.26,4.37
                          C187.31,54.76,186.05,56.13,184.02,56.13L184.02,56.13L184.02,56.13z M145.87,56.13c-2.05,0-3.37-1.42-3.37-4.27
                          c0-2.71,1.37-4.24,3.4-4.24c2.05,0,3.26,1.5,3.26,4.37C149.16,54.76,147.9,56.13,145.87,56.13L145.87,56.13L145.87,56.13z
                          M177.65,48.05v-1.87h-2.34v-3.27l-2.18,0.34v2.92h-1.66v1.87h1.66v7.27c0,1.63,0.6,2.61,3,2.61c0.53,0,1.32-0.1,1.79-0.24v-1.63
                          c-0.5,0.13-0.98,0.16-1.32,0.16c-1,0-1.29-0.47-1.29-1.53v-6.64H177.65L177.65,48.05z M113.31,48.05v-1.87h-2.34v-3.27l-2.19,0.34
                          v2.92h-1.66v1.87h1.66v7.27c0,1.63,0.61,2.61,3,2.61c0.53,0,1.32-0.1,1.79-0.24v-1.63c-0.5,0.13-0.97,0.16-1.32,0.16
                          c-1,0-1.29-0.47-1.29-1.53v-6.64H113.31L113.31,48.05z M153.4,57.6h2.13v-7.16c0-1.66,1.03-2.42,2.34-2.42
                          c0.45,0,0.9,0.03,1.37,0.11v-2.08c-0.4-0.13-0.76-0.16-1.13-0.16c-1.18,0-2.11,0.71-2.58,1.68l-0.26-1.4h-1.87V57.6L153.4,57.6z
                          M77.77,57.6h2.13v-7.16c0-1.66,1.03-2.42,2.34-2.42c0.45,0,0.9,0.03,1.37,0.11v-2.08c-0.4-0.13-0.76-0.16-1.13-0.16
                          c-1.18,0-2.11,0.71-2.58,1.68l-0.26-1.4h-1.87V57.6L77.77,57.6z M83.8,54.5c0,1.63,0.95,3.37,3.9,3.37c1.71,0,2.9-0.66,3.71-1.71
                          l0.29,1.45h1.84v-7.79c0-3.34-2-3.92-4.37-3.92c-1.79,0-4.13,0.53-4.71,3.21h2.26c0.29-1,0.97-1.58,2.42-1.58
                          c1.19,0,2.32,0.42,2.32,2.32v0.82h-2.5C85.96,50.65,83.8,51.65,83.8,54.5L83.8,54.5L83.8,54.5z M88.38,56.21
                          c-1.68,0-2.32-0.74-2.32-1.92c0-1.37,0.76-2.08,2.76-2.08h2.63v0.79C91.46,55.07,90.17,56.21,88.38,56.21L88.38,56.21z"
            />
            <path
              className={classNames(styles.st1)}
              d="M49.65,51.97c-1.13,6.16-5.82,11.07-11.87,12.52c-0.57,0.14-1.17,0.14-1.75,0.03c0,0-0.01,0-0.01,0
                          c-0.66-0.12-1.31-0.31-1.92-0.54c-2.98-1.12-5.37-3.43-6.59-6.35c-0.19-0.46-0.35-0.95-0.48-1.44c-0.26-0.96-0.41-1.96-0.41-3
                          c0-0.73,0.08-1.45,0.21-2.14c1.2,2.27,3.07,4.14,5.35,5.35c0.61,0.32,1.26,0.6,1.92,0.82c1.28,0.43,2.64,0.67,4.07,0.67
                          c0.86,0,1.71-0.09,2.52-0.25c0.52-0.1,1.02-0.24,1.51-0.41c3.16-1.05,5.77-3.28,7.3-6.18c0.13-0.24,0.24-0.49,0.35-0.74
                          C49.82,50.86,49.75,51.42,49.65,51.97L49.65,51.97z M18.73,52.79c-0.14-0.57-0.14-1.17-0.03-1.75c0,0,0-0.01,0-0.01
                          c0.12-0.66,0.31-1.31,0.54-1.92c1.12-2.98,3.43-5.37,6.35-6.59c0.46-0.19,0.95-0.35,1.44-0.48c0.96-0.26,1.96-0.41,3-0.41
                          c0.73,0,1.45,0.08,2.14,0.21c-2.27,1.2-4.14,3.07-5.35,5.35c-0.32,0.61-0.6,1.26-0.82,1.92c-0.43,1.28-0.67,2.64-0.67,4.07
                          c0,0.86,0.09,1.71,0.25,2.52c0.1,0.52,0.24,1.02,0.41,1.51c1.05,3.16,3.28,5.77,6.18,7.3c0.24,0.13,0.49,0.24,0.74,0.35
                          c-0.57-0.04-1.12-0.11-1.67-0.21C25.08,63.53,20.17,58.83,18.73,52.79L18.73,52.79z M18.55,46.24
                          c1.13-6.16,5.82-11.07,11.87-12.52c0.57-0.14,1.17-0.14,1.75-0.03c0,0,0.01,0,0.01,0c0.66,0.12,1.31,0.31,1.92,0.54
                          c2.98,1.12,5.37,3.43,6.59,6.35c0.19,0.46,0.35,0.95,0.48,1.44c0.26,0.96,0.41,1.96,0.41,3c0,0.73-0.08,1.45-0.21,2.14
                          c-1.2-2.27-3.07-4.14-5.35-5.35c-0.61-0.32-1.26-0.6-1.92-0.82c-1.28-0.43-2.64-0.67-4.07-0.67c-0.86,0-1.71,0.09-2.52,0.25
                          C27,40.7,26.49,40.84,26,41c-3.16,1.05-5.77,3.28-7.3,6.18c-0.13,0.24-0.24,0.49-0.35,0.74C18.39,47.35,18.45,46.79,18.55,46.24
                          L18.55,46.24z M33.36,55.52c-2.51-1.15-4.52-3.17-5.67-5.67c-0.21-0.47-0.21-1.02,0-1.48c1.15-2.51,3.17-4.52,5.67-5.67
                          c0.47-0.21,1.02-0.21,1.48,0c2.51,1.15,4.52,3.17,5.67,5.67c0.21,0.47,0.21,1.02,0,1.48c-1.15,2.51-3.17,4.52-5.67,5.67
                          C34.38,55.74,33.83,55.74,33.36,55.52L33.36,55.52z M49.48,45.42c0.14,0.57,0.14,1.17,0.03,1.75c0,0,0,0.01,0,0.01
                          c-0.12,0.66-0.31,1.31-0.54,1.92c-1.12,2.98-3.43,5.37-6.35,6.59c-0.46,0.19-0.95,0.35-1.44,0.48c-0.96,0.26-1.96,0.41-3,0.41
                          c-0.73,0-1.45-0.08-2.14-0.21c2.27-1.2,4.14-3.07,5.35-5.35c0.32-0.61,0.6-1.26,0.82-1.92c0.43-1.28,0.67-2.64,0.67-4.07
                          c0-0.86-0.09-1.71-0.25-2.52c-0.1-0.52-0.24-1.02-0.41-1.51c-1.05-3.16-3.28-5.77-6.18-7.3c-0.24-0.13-0.49-0.24-0.74-0.35
                          c0.57,0.04,1.12,0.11,1.67,0.21C43.13,34.68,48.04,39.38,49.48,45.42L49.48,45.42z M50.94,46.19c-0.4-2.3-1.24-4.44-2.46-6.32
                          c-1.33-2.06-3.08-3.81-5.14-5.14c-1.89-1.21-4.03-2.06-6.32-2.46C36.07,32.1,35.1,32,34.1,32s-1.97,0.1-2.91,0.27
                          c-2.3,0.4-4.44,1.24-6.32,2.46c-2.06,1.33-3.82,3.08-5.14,5.14c-1.21,1.89-2.06,4.03-2.46,6.32C17.1,47.14,17,48.11,17,49.1
                          c0,1,0.1,1.97,0.27,2.91c0.4,2.3,1.24,4.44,2.46,6.32c1.33,2.06,3.08,3.82,5.14,5.14c1.89,1.21,4.03,2.06,6.32,2.46
                          c0.95,0.16,1.92,0.27,2.91,0.27s1.97-0.1,2.91-0.27c2.3-0.4,4.44-1.24,6.32-2.46c2.06-1.33,3.81-3.08,5.14-5.14
                          c1.21-1.89,2.06-4.03,2.46-6.32c0.16-0.95,0.27-1.92,0.27-2.91C51.21,48.11,51.11,47.14,50.94,46.19L50.94,46.19z"
            />
          </g>
        </g>
        <g id="_x33_402_hvid" className={classNames(styles.st2)}>
          <rect
            x="0.5"
            y="0.5"
            className={classNames(styles.st3)}
            width="511"
            height="100"
          />
          <g className={classNames(styles.st4)}>
            <rect
              x="211.1"
              y="44.35"
              className={classNames(styles.st0)}
              width="346.96"
              height="24.56"
            />
            <path className="st5" d="M212.66,62.62V43.12h1.78v19.51H212.66z" />
            <path className="st5" d="M226.75,44.35h3.24v13.84h-3.24V44.35z" />
            <path
              className="st5"
              d="M235.27,53.6c0,0.75,0.2,1.34,0.61,1.76c0.41,0.42,0.94,0.63,1.6,0.63c0.51,0,0.95-0.15,1.29-0.44
                          s0.52-0.69,0.52-1.18c0-0.28-0.06-0.51-0.17-0.7c-0.11-0.19-0.26-0.36-0.45-0.5c-0.19-0.14-0.42-0.27-0.68-0.39
                          c-0.26-0.11-0.55-0.23-0.85-0.35c-0.63-0.24-1.24-0.47-1.81-0.71c-0.57-0.24-1.07-0.52-1.49-0.86c-0.42-0.34-0.76-0.74-1.01-1.22
                          c-0.25-0.47-0.38-1.06-0.38-1.76c0-0.63,0.13-1.19,0.41-1.68c0.27-0.49,0.63-0.9,1.08-1.23c0.45-0.33,0.96-0.58,1.55-0.74
                          c0.59-0.17,1.2-0.25,1.83-0.25c0.82,0,1.53,0.12,2.15,0.36c0.62,0.24,1.13,0.56,1.54,0.97c0.41,0.41,0.71,0.88,0.92,1.4
                          c0.2,0.53,0.31,1.09,0.31,1.68h-3.2c0-0.62-0.15-1.11-0.44-1.46c-0.3-0.36-0.72-0.53-1.27-0.53c-0.46,0-0.86,0.12-1.19,0.36
                          c-0.33,0.24-0.49,0.59-0.49,1.07c0,0.46,0.18,0.83,0.54,1.1c0.36,0.27,0.84,0.51,1.43,0.72c0.57,0.2,1.14,0.41,1.72,0.64
                          c0.58,0.23,1.1,0.51,1.56,0.85c0.46,0.34,0.84,0.75,1.14,1.23c0.3,0.48,0.44,1.06,0.44,1.75c0,0.71-0.13,1.34-0.39,1.9
                          c-0.26,0.55-0.62,1.02-1.08,1.39c-0.46,0.38-1.01,0.66-1.64,0.85c-0.63,0.19-1.33,0.29-2.1,0.29c-0.74,0-1.43-0.11-2.07-0.33
                          c-0.64-0.22-1.19-0.54-1.66-0.96s-0.83-0.94-1.1-1.55c-0.26-0.61-0.4-1.31-0.4-2.11H235.27z"
            />
            <path
              className="st5"
              d="M252.25,58.18l-0.53-1.68h-4.92l-0.53,1.68h-3.42l4.94-13.84h2.94l5,13.84H252.25z M249.27,48.3l-1.7,5.69
                          h3.36L249.27,48.3z"
            />
            <path
              className="st5"
              d="M260.33,46.92v3.14h5.24v2.51h-5.24v3.04h5.24v2.57h-8.44V44.35h8.44v2.57H260.33z"
            />
            <path
              className="st5"
              d="M275.41,53.54c0,0.75,0.19,1.33,0.57,1.75s0.88,0.62,1.5,0.62c0.59,0,1.05-0.18,1.36-0.55
                          s0.47-0.79,0.47-1.27c0-0.58-0.15-1.04-0.46-1.39c-0.3-0.35-0.83-0.52-1.58-0.52h-1.25V49.7h1.25c0.71,0,1.2-0.16,1.45-0.47
                          s0.38-0.72,0.38-1.23c0-0.53-0.15-0.92-0.44-1.17c-0.3-0.25-0.68-0.38-1.14-0.38c-0.69,0-1.15,0.18-1.39,0.55
                          c-0.24,0.37-0.37,0.86-0.37,1.46h-3.2c0-0.59,0.1-1.16,0.29-1.69c0.19-0.53,0.48-1,0.88-1.4c0.4-0.4,0.9-0.72,1.51-0.97
                          c0.61-0.24,1.35-0.37,2.2-0.37c0.63,0,1.24,0.08,1.83,0.24s1.1,0.4,1.54,0.72c0.44,0.32,0.79,0.73,1.06,1.22s0.39,1.05,0.39,1.7
                          c0,0.78-0.2,1.44-0.6,1.98c-0.4,0.54-0.88,0.9-1.43,1.09c0.32,0.12,0.61,0.28,0.88,0.47c0.27,0.2,0.51,0.44,0.71,0.71
                          c0.2,0.28,0.37,0.6,0.48,0.96c0.12,0.36,0.18,0.76,0.18,1.2c0,0.66-0.14,1.25-0.41,1.76c-0.27,0.51-0.63,0.95-1.1,1.3
                          c-0.46,0.36-0.99,0.63-1.59,0.81s-1.23,0.28-1.89,0.28c-0.86,0-1.61-0.12-2.27-0.36c-0.66-0.24-1.21-0.57-1.67-1.01
                          c-0.45-0.43-0.8-0.96-1.03-1.56c-0.23-0.61-0.35-1.28-0.35-2.02H275.41z"
            />
            <path
              className="st5"
              d="M290.12,54.37h-5.53V44.35h3.2v7.31h2.33v-3.82h3.18v3.82h1.68v2.71h-1.68v3.81h-3.18V54.37z"
            />
            <path
              className="st5"
              d="M296.35,48.99c0-0.67,0.12-1.3,0.37-1.9c0.24-0.59,0.59-1.12,1.05-1.57c0.46-0.45,1.01-0.81,1.66-1.08
                          s1.38-0.4,2.18-0.4c0.8,0,1.53,0.13,2.18,0.4s1.21,0.62,1.67,1.08c0.46,0.46,0.82,0.98,1.07,1.57c0.25,0.59,0.38,1.23,0.38,1.9
                          v4.55c0,0.67-0.12,1.3-0.38,1.9c-0.25,0.59-0.61,1.12-1.07,1.57c-0.46,0.45-1.02,0.81-1.67,1.08s-1.38,0.4-2.18,0.4
                          c-0.8,0-1.53-0.13-2.18-0.4s-1.21-0.62-1.66-1.08c-0.46-0.46-0.8-0.98-1.05-1.57c-0.24-0.59-0.37-1.23-0.37-1.9V48.99z
                          M303.7,48.99c0-0.3-0.05-0.59-0.15-0.86c-0.1-0.27-0.24-0.51-0.42-0.71c-0.18-0.2-0.4-0.37-0.66-0.48
                          c-0.26-0.12-0.55-0.18-0.87-0.18s-0.6,0.06-0.86,0.18c-0.26,0.12-0.47,0.28-0.65,0.48c-0.18,0.21-0.31,0.44-0.41,0.71
                          c-0.09,0.27-0.14,0.56-0.14,0.86v4.55c0,0.3,0.05,0.59,0.14,0.86c0.09,0.27,0.23,0.51,0.41,0.71s0.4,0.37,0.65,0.48
                          c0.26,0.12,0.54,0.18,0.86,0.18s0.61-0.06,0.87-0.18c0.26-0.12,0.48-0.28,0.66-0.48s0.32-0.44,0.42-0.71
                          c0.1-0.27,0.15-0.56,0.15-0.86V48.99z"
            />
            <path
              className="st5"
              d="M308.87,55.36c0.18-0.76,0.43-1.41,0.76-1.94c0.33-0.53,0.71-0.97,1.16-1.34s0.93-0.72,1.47-1.05
                          c0.41-0.24,0.79-0.47,1.14-0.68c0.35-0.22,0.66-0.44,0.92-0.66s0.47-0.47,0.62-0.74c0.15-0.27,0.23-0.58,0.23-0.92
                          c0-0.55-0.15-0.95-0.45-1.2c-0.3-0.24-0.68-0.37-1.13-0.37c-0.5,0-0.92,0.15-1.25,0.46c-0.34,0.31-0.5,0.83-0.5,1.55h-3.18
                          c0-0.63,0.11-1.22,0.33-1.76c0.22-0.54,0.54-1.01,0.96-1.4s0.94-0.71,1.54-0.93c0.61-0.22,1.3-0.34,2.09-0.34
                          c0.63,0,1.24,0.08,1.81,0.25c0.57,0.17,1.08,0.42,1.51,0.75c0.44,0.34,0.78,0.77,1.04,1.3c0.26,0.53,0.39,1.16,0.39,1.89
                          c0,0.58-0.13,1.11-0.38,1.58c-0.25,0.47-0.56,0.9-0.92,1.27c-0.36,0.37-0.75,0.7-1.18,0.98c-0.42,0.28-0.8,0.52-1.15,0.72
                          c-0.47,0.32-0.87,0.59-1.18,0.82c-0.31,0.23-0.56,0.45-0.74,0.64c-0.18,0.2-0.31,0.4-0.38,0.61c-0.07,0.21-0.11,0.45-0.11,0.73
                          h5.93v2.59h-9.61C308.6,57.06,308.69,56.12,308.87,55.36z"
            />
            <path
              className="st5"
              d="M330.08,58.18h-1.54l-4.59-13.28h2.3l3.07,9.66l3.05-9.66h2.3L330.08,58.18z"
            />
            <path
              className="st5"
              d="M336.48,54.39c0.03,0.32,0.08,0.61,0.17,0.89c0.09,0.28,0.22,0.52,0.41,0.73s0.42,0.37,0.71,0.49
                          c0.29,0.12,0.65,0.18,1.08,0.18c0.59,0,1.03-0.11,1.29-0.34s0.46-0.5,0.59-0.82h2.09c-0.06,0.38-0.2,0.74-0.41,1.09
                          c-0.21,0.35-0.48,0.65-0.83,0.92c-0.34,0.27-0.74,0.48-1.2,0.64c-0.46,0.16-0.97,0.24-1.54,0.24c-0.75,0-1.39-0.12-1.94-0.36
                          c-0.55-0.24-1.01-0.57-1.38-0.99c-0.37-0.42-0.64-0.91-0.83-1.48s-0.28-1.18-0.28-1.82c0-0.66,0.1-1.27,0.29-1.84
                          s0.48-1.06,0.84-1.48c0.37-0.42,0.82-0.74,1.37-0.98s1.16-0.35,1.86-0.35c0.57,0,1.1,0.1,1.6,0.3c0.5,0.2,0.94,0.48,1.31,0.84
                          s0.66,0.78,0.87,1.26c0.21,0.48,0.31,1.01,0.31,1.58c0,0.15,0,0.33-0.01,0.54c-0.01,0.21-0.03,0.46-0.08,0.75H336.48z
                          M338.74,50.67c-0.65,0-1.18,0.18-1.61,0.55c-0.43,0.37-0.64,0.91-0.64,1.63h4.4c0-0.68-0.2-1.22-0.6-1.6
                          C339.88,50.86,339.37,50.67,338.74,50.67z"
            />
            <path
              className="st5"
              d="M350.75,51.26c-0.17-0.04-0.3-0.06-0.41-0.08s-0.24-0.02-0.41-0.02c-0.89,0-1.56,0.26-2.03,0.77
                          c-0.47,0.51-0.7,1.33-0.7,2.44v3.81h-2.07v-8.84h2.07v1.42c0.1-0.2,0.24-0.4,0.43-0.58c0.18-0.18,0.4-0.35,0.64-0.49
                          c0.25-0.15,0.51-0.26,0.8-0.34c0.29-0.08,0.57-0.12,0.86-0.12c0.33,0,0.6,0.04,0.82,0.13V51.26z"
            />
            <path
              className="st5"
              d="M351.81,46.12c0-0.43,0.15-0.79,0.45-1.09c0.3-0.3,0.65-0.45,1.07-0.45c0.43,0,0.79,0.15,1.09,0.45
                          c0.3,0.3,0.45,0.66,0.45,1.09c0,0.42-0.15,0.78-0.45,1.07c-0.3,0.3-0.66,0.45-1.09,0.45c-0.42,0-0.78-0.15-1.07-0.45
                          C351.96,46.89,351.81,46.53,351.81,46.12z M352.3,49.34h2.07v8.84h-2.07V49.34z"
            />
            <path
              className="st5"
              d="M362.32,46.76c-0.14-0.04-0.29-0.07-0.44-0.09s-0.31-0.04-0.47-0.04s-0.33,0.01-0.49,0.02
                          c-0.16,0.01-0.31,0.06-0.45,0.15s-0.24,0.23-0.33,0.42c-0.09,0.19-0.13,0.46-0.13,0.8v1.33h2.32v1.73h-2.32v7.12h-2.07v-7.12h-1.8
                          v-1.73h1.8v-1.1c0-0.85,0.12-1.5,0.35-1.96c0.23-0.46,0.52-0.8,0.85-1.02c0.33-0.22,0.69-0.35,1.05-0.4
                          c0.37-0.04,0.68-0.07,0.93-0.07c0.27,0,0.5,0.02,0.71,0.07c0.21,0.04,0.37,0.1,0.48,0.16V46.76z M363.95,46.14
                          c0-0.41,0.14-0.75,0.42-1.03c0.28-0.29,0.62-0.43,1.03-0.43c0.4,0,0.75,0.14,1.03,0.43c0.28,0.28,0.43,0.63,0.43,1.03
                          c0,0.4-0.14,0.75-0.43,1.02c-0.29,0.28-0.63,0.42-1.03,0.42c-0.41,0-0.75-0.14-1.03-0.42S363.95,46.54,363.95,46.14z
                          M364.37,49.34h2.07v8.84h-2.07V49.34z"
            />
            <path
              className="st5"
              d="M371.05,54.39c0.03,0.32,0.08,0.61,0.17,0.89c0.09,0.28,0.22,0.52,0.41,0.73s0.42,0.37,0.71,0.49
                          c0.29,0.12,0.65,0.18,1.08,0.18c0.59,0,1.03-0.11,1.29-0.34s0.46-0.5,0.59-0.82h2.09c-0.06,0.38-0.2,0.74-0.41,1.09
                          c-0.21,0.35-0.48,0.65-0.83,0.92c-0.34,0.27-0.74,0.48-1.2,0.64c-0.46,0.16-0.97,0.24-1.54,0.24c-0.75,0-1.39-0.12-1.94-0.36
                          c-0.55-0.24-1.01-0.57-1.38-0.99c-0.37-0.42-0.64-0.91-0.83-1.48S369,54.41,369,53.76c0-0.66,0.1-1.27,0.29-1.84
                          s0.48-1.06,0.84-1.48c0.37-0.42,0.82-0.74,1.37-0.98s1.16-0.35,1.86-0.35c0.57,0,1.1,0.1,1.6,0.3c0.5,0.2,0.94,0.48,1.31,0.84
                          s0.66,0.78,0.87,1.26c0.21,0.48,0.31,1.01,0.31,1.58c0,0.15,0,0.33-0.01,0.54c-0.01,0.21-0.03,0.46-0.08,0.75H371.05z
                          M373.31,50.67c-0.65,0-1.18,0.18-1.61,0.55c-0.43,0.37-0.64,0.91-0.64,1.63h4.4c0-0.68-0.2-1.22-0.6-1.6
                          C374.46,50.86,373.94,50.67,373.31,50.67z"
            />
            <path
              className="st5"
              d="M386.31,56.99c-0.28,0.44-0.67,0.79-1.19,1.04c-0.51,0.25-1.03,0.38-1.55,0.38c-0.67,0-1.28-0.12-1.84-0.37
                          c-0.56-0.25-1.03-0.58-1.43-1.01c-0.4-0.43-0.71-0.92-0.93-1.48c-0.22-0.56-0.33-1.15-0.33-1.78c0-0.62,0.11-1.21,0.33-1.77
                          c0.22-0.56,0.53-1.06,0.93-1.49c0.4-0.43,0.88-0.77,1.43-1.02c0.56-0.25,1.17-0.37,1.84-0.37c0.52,0,1.03,0.13,1.55,0.38
                          c0.51,0.25,0.91,0.6,1.19,1.04V44.9h2.07v13.28h-2.07V56.99z M383.69,56.57c0.39,0,0.75-0.08,1.07-0.23
                          c0.32-0.15,0.6-0.35,0.83-0.61c0.23-0.25,0.4-0.55,0.53-0.89c0.13-0.34,0.19-0.7,0.19-1.08c0-0.38-0.06-0.74-0.19-1.08
                          c-0.13-0.34-0.3-0.64-0.53-0.89s-0.5-0.46-0.83-0.61c-0.32-0.15-0.68-0.23-1.07-0.23c-0.39,0-0.75,0.08-1.06,0.23
                          c-0.32,0.15-0.59,0.35-0.82,0.61s-0.4,0.55-0.52,0.89s-0.18,0.7-0.18,1.08c0,0.38,0.06,0.74,0.18,1.08
                          c0.12,0.34,0.29,0.64,0.52,0.89c0.23,0.25,0.5,0.46,0.82,0.61C382.94,56.5,383.29,56.57,383.69,56.57z"
            />
            <path
              className="st5"
              d="M395.66,55.55c0.18-0.71,0.42-1.31,0.74-1.8s0.69-0.91,1.13-1.25s0.91-0.68,1.41-1.01
                          c0.29-0.18,0.59-0.36,0.9-0.55c0.31-0.19,0.59-0.4,0.84-0.64s0.46-0.5,0.63-0.8c0.16-0.3,0.25-0.65,0.25-1.05
                          c0-0.64-0.18-1.11-0.55-1.39c-0.37-0.28-0.82-0.43-1.37-0.43c-0.57,0-1.06,0.17-1.46,0.52c-0.4,0.35-0.61,0.91-0.61,1.68h-2.18
                          c0-0.67,0.12-1.26,0.35-1.78c0.23-0.52,0.55-0.95,0.95-1.3c0.4-0.35,0.85-0.61,1.37-0.8c0.51-0.18,1.05-0.27,1.6-0.27
                          c0.57,0,1.1,0.08,1.6,0.25c0.5,0.17,0.93,0.42,1.3,0.75c0.37,0.33,0.65,0.75,0.86,1.25s0.31,1.08,0.31,1.74
                          c0,0.61-0.15,1.15-0.44,1.63s-0.63,0.9-1.03,1.26c-0.39,0.36-0.79,0.67-1.19,0.92c-0.4,0.25-0.71,0.46-0.92,0.61
                          c-0.48,0.34-0.87,0.64-1.17,0.89c-0.3,0.25-0.53,0.49-0.69,0.72c-0.17,0.23-0.28,0.46-0.33,0.7c-0.06,0.24-0.08,0.52-0.08,0.83
                          h5.77v1.95h-8.27C395.39,57.13,395.48,56.26,395.66,55.55z"
            />
            <path
              className="st5"
              d="M405.34,49.32c0-0.62,0.11-1.21,0.33-1.77c0.22-0.56,0.53-1.06,0.94-1.49c0.4-0.43,0.89-0.77,1.46-1.02
                          c0.57-0.25,1.21-0.37,1.92-0.37c0.71,0,1.35,0.12,1.93,0.37c0.58,0.25,1.07,0.59,1.48,1.02s0.73,0.93,0.95,1.49
                          c0.22,0.56,0.33,1.15,0.33,1.77v4.44c0,0.63-0.11,1.23-0.33,1.78c-0.22,0.56-0.54,1.05-0.95,1.48s-0.9,0.77-1.48,1.01
                          c-0.58,0.25-1.22,0.37-1.93,0.37c-0.71,0-1.35-0.12-1.92-0.37c-0.57-0.25-1.06-0.58-1.46-1.01c-0.41-0.43-0.72-0.92-0.94-1.48
                          c-0.22-0.56-0.33-1.15-0.33-1.78V49.32z M412.49,49.32c0-0.37-0.06-0.71-0.18-1.03c-0.12-0.32-0.29-0.61-0.5-0.85
                          c-0.22-0.25-0.48-0.44-0.79-0.59c-0.31-0.14-0.65-0.22-1.03-0.22c-0.38,0-0.72,0.07-1.02,0.22c-0.3,0.15-0.56,0.34-0.78,0.59
                          c-0.21,0.25-0.38,0.53-0.49,0.85c-0.11,0.32-0.17,0.67-0.17,1.03v4.44c0,0.37,0.06,0.71,0.17,1.03c0.11,0.32,0.28,0.61,0.49,0.85
                          c0.22,0.25,0.47,0.44,0.78,0.59c0.3,0.15,0.65,0.22,1.02,0.22c0.38,0,0.72-0.07,1.03-0.22c0.31-0.14,0.57-0.34,0.79-0.59
                          c0.21-0.25,0.38-0.53,0.5-0.85c0.12-0.32,0.18-0.67,0.18-1.03V49.32z"
            />
            <path
              className="st5"
              d="M416.67,55.55c0.18-0.71,0.42-1.31,0.74-1.8s0.69-0.91,1.13-1.25s0.91-0.68,1.41-1.01
                          c0.29-0.18,0.59-0.36,0.9-0.55c0.31-0.19,0.59-0.4,0.84-0.64s0.46-0.5,0.63-0.8c0.16-0.3,0.25-0.65,0.25-1.05
                          c0-0.64-0.18-1.11-0.55-1.39c-0.37-0.28-0.82-0.43-1.37-0.43c-0.57,0-1.06,0.17-1.46,0.52c-0.4,0.35-0.61,0.91-0.61,1.68h-2.18
                          c0-0.67,0.12-1.26,0.35-1.78c0.23-0.52,0.55-0.95,0.95-1.3c0.4-0.35,0.85-0.61,1.37-0.8c0.51-0.18,1.05-0.27,1.6-0.27
                          c0.57,0,1.1,0.08,1.6,0.25c0.5,0.17,0.93,0.42,1.3,0.75c0.37,0.33,0.65,0.75,0.86,1.25s0.31,1.08,0.31,1.74
                          c0,0.61-0.15,1.15-0.44,1.63s-0.63,0.9-1.03,1.26c-0.39,0.36-0.79,0.67-1.19,0.92c-0.4,0.25-0.71,0.46-0.92,0.61
                          c-0.48,0.34-0.87,0.64-1.17,0.89c-0.3,0.25-0.53,0.49-0.69,0.72c-0.17,0.23-0.28,0.46-0.33,0.7c-0.06,0.24-0.08,0.52-0.08,0.83
                          h5.77v1.95h-8.27C416.4,57.13,416.49,56.26,416.67,55.55z"
            />
            <path
              className="st5"
              d="M428.22,53.74c0,0.43,0.07,0.81,0.2,1.15s0.31,0.62,0.54,0.85c0.23,0.23,0.5,0.41,0.81,0.53
                          c0.31,0.12,0.64,0.18,1,0.18c0.34,0,0.65-0.06,0.93-0.17s0.51-0.27,0.7-0.46c0.19-0.19,0.33-0.41,0.44-0.66s0.15-0.51,0.15-0.78
                          c0-0.32-0.04-0.61-0.12-0.87c-0.08-0.27-0.21-0.5-0.4-0.69s-0.43-0.35-0.73-0.46c-0.3-0.11-0.68-0.17-1.14-0.17h-0.89v-1.94h0.91
                          c0.78,0,1.32-0.18,1.61-0.54c0.29-0.36,0.44-0.83,0.44-1.39s-0.17-0.99-0.52-1.27c-0.35-0.28-0.8-0.42-1.36-0.42
                          c-0.79,0-1.33,0.2-1.64,0.61c-0.31,0.4-0.46,0.94-0.46,1.59h-2.18c0-0.62,0.1-1.18,0.3-1.69s0.49-0.94,0.85-1.31
                          c0.37-0.37,0.8-0.65,1.31-0.85c0.51-0.2,1.06-0.3,1.67-0.3c0.57,0,1.11,0.08,1.62,0.26c0.51,0.17,0.96,0.42,1.34,0.74
                          c0.38,0.32,0.68,0.71,0.91,1.16s0.34,0.95,0.34,1.49c0,0.76-0.17,1.38-0.5,1.86c-0.33,0.48-0.77,0.84-1.3,1.06
                          c0.61,0.24,1.11,0.61,1.52,1.11c0.41,0.5,0.61,1.17,0.61,2.02c0,0.61-0.12,1.16-0.36,1.65s-0.56,0.92-0.97,1.27
                          s-0.88,0.63-1.43,0.83c-0.55,0.2-1.13,0.29-1.74,0.29s-1.19-0.1-1.75-0.31c-0.56-0.21-1.05-0.51-1.47-0.91s-0.77-0.89-1.02-1.46
                          c-0.26-0.58-0.39-1.24-0.39-1.98H428.22z"
            />
          </g>
          <g className={classNames(styles.st4)}>
            <path
              className="st5"
              d="M184.41,45.91c-3.8,0-5.78,2.61-5.78,6.06c0,3.56,1.98,5.93,5.51,5.93c3.53,0,5.72-2.32,5.72-5.99
                          C189.87,48.15,187.84,45.91,184.41,45.91L184.41,45.91L184.41,45.91z M146.21,45.91c-3.8,0-5.77,2.61-5.77,6.06
                          c0,3.56,1.98,5.93,5.51,5.93c3.53,0,5.72-2.32,5.72-5.99C151.67,48.15,149.64,45.91,146.21,45.91L146.21,45.91L146.21,45.91z
                          M69.29,50.81h4.06v3.82c-1.24,1.08-3.14,1.5-4.67,1.5c-3.82,0-6.01-2.48-6.01-6.83c0-4.61,2.35-7.17,5.96-7.17
                          c2.16,0,3.77,0.76,5.3,2.21l1.34-1.53c-1.37-1.5-3.72-2.61-6.41-2.61c-5.41,0-8.6,3.77-8.6,9.04c0,5.17,2.66,8.75,8.44,8.75
                          c3.19,0,5.67-1.4,6.88-2.72v-6.25h-6.3V50.81L69.29,50.81z M129.15,57.64h2.08v-6.59c0-1.9,0.82-3.22,2.79-3.22
                          c1.84,0,2.43,0.79,2.43,2.72v7.09h2.14v-7.7c0-2.64-1.11-4.01-3.61-4.01c-1.71,0-2.98,0.63-3.74,1.71v-7.22h-2.08V57.64
                          L129.15,57.64z M128.05,42.27v-1.84h-12.18v1.84h4.98v15.37h2.29V42.27H128.05L128.05,42.27z M191.82,57.64h2.08v-6.59
                          c0-1.9,0.82-3.22,2.79-3.22c1.84,0,2.43,0.79,2.43,2.72v7.09h2.13v-7.7c0-2.64-1.11-4.01-3.61-4.01c-1.71,0-2.98,0.63-3.74,1.71
                          l-0.26-1.45h-1.82V57.64L191.82,57.64z M96.42,57.64h2.08v-6.59c0-1.9,0.82-3.22,2.79-3.22c1.85,0,2.43,0.79,2.43,2.72v7.09h2.14
                          v-7.7c0-2.64-1.11-4.01-3.61-4.01c-1.71,0-2.98,0.63-3.74,1.71l-0.26-1.45h-1.82V57.64L96.42,57.64z M160.92,57.64h2.08v-6.59
                          c0-1.9,0.82-3.22,2.79-3.22c1.85,0,2.43,0.79,2.43,2.72v7.09h2.14v-7.7c0-2.64-1.11-4.01-3.61-4.01c-1.71,0-2.98,0.63-3.74,1.71
                          l-0.26-1.45h-1.82V57.64L160.92,57.64z M184.25,56.16c-2.06,0-3.37-1.42-3.37-4.27c0-2.72,1.37-4.25,3.4-4.25
                          c2.06,0,3.27,1.5,3.27,4.38C187.55,54.79,186.28,56.16,184.25,56.16L184.25,56.16L184.25,56.16z M146.05,56.16
                          c-2.06,0-3.37-1.42-3.37-4.27c0-2.72,1.37-4.25,3.4-4.25c2.05,0,3.27,1.5,3.27,4.38C149.35,54.79,148.08,56.16,146.05,56.16
                          L146.05,56.16L146.05,56.16z M177.87,48.07V46.2h-2.35v-3.27l-2.19,0.34v2.93h-1.66v1.87h1.66v7.28c0,1.63,0.6,2.61,3,2.61
                          c0.53,0,1.32-0.11,1.79-0.24v-1.63c-0.5,0.13-0.98,0.16-1.32,0.16c-1,0-1.29-0.47-1.29-1.53v-6.64H177.87L177.87,48.07z
                          M113.45,48.07V46.2h-2.34v-3.27l-2.19,0.34v2.93h-1.66v1.87h1.66v7.28c0,1.63,0.61,2.61,3.01,2.61c0.53,0,1.32-0.11,1.79-0.24
                          v-1.63c-0.5,0.13-0.98,0.16-1.32,0.16c-1,0-1.29-0.47-1.29-1.53v-6.64H113.45L113.45,48.07z M153.59,57.64h2.14v-7.17
                          c0-1.66,1.03-2.43,2.35-2.43c0.45,0,0.9,0.03,1.37,0.11v-2.08c-0.4-0.13-0.76-0.16-1.13-0.16c-1.19,0-2.11,0.71-2.58,1.69
                          l-0.26-1.4h-1.87V57.64L153.59,57.64z M77.86,57.64h2.14v-7.17c0-1.66,1.03-2.43,2.35-2.43c0.45,0,0.9,0.03,1.37,0.11v-2.08
                          c-0.4-0.13-0.76-0.16-1.13-0.16c-1.19,0-2.11,0.71-2.58,1.69l-0.26-1.4h-1.87V57.64L77.86,57.64z M83.9,54.53
                          c0,1.64,0.95,3.37,3.9,3.37c1.71,0,2.9-0.66,3.72-1.71l0.29,1.45h1.85v-7.8c0-3.35-2-3.93-4.38-3.93c-1.79,0-4.14,0.53-4.72,3.22
                          h2.27c0.29-1,0.98-1.58,2.43-1.58c1.19,0,2.32,0.42,2.32,2.32v0.82h-2.5C86.06,50.68,83.9,51.68,83.9,54.53L83.9,54.53L83.9,54.53
                          z M88.48,56.24c-1.69,0-2.32-0.74-2.32-1.92c0-1.37,0.76-2.08,2.77-2.08h2.64v0.79C91.57,55.11,90.28,56.24,88.48,56.24
                          L88.48,56.24z"
            />
            <path
              className="st5"
              d="M49.7,51.99c-1.13,6.17-5.83,11.09-11.88,12.53c-0.57,0.14-1.17,0.14-1.75,0.03c0,0-0.01,0-0.01,0
                          c-0.67-0.13-1.31-0.31-1.93-0.55c-2.98-1.12-5.38-3.43-6.6-6.36c-0.19-0.46-0.35-0.95-0.48-1.44c-0.26-0.96-0.41-1.96-0.41-3.01
                          c0-0.73,0.08-1.45,0.21-2.15c1.21,2.28,3.08,4.15,5.35,5.35c0.61,0.33,1.26,0.6,1.93,0.82c1.28,0.43,2.65,0.67,4.07,0.67
                          c0.86,0,1.71-0.09,2.52-0.25c0.52-0.1,1.02-0.24,1.52-0.41c3.16-1.05,5.78-3.28,7.31-6.19c0.13-0.24,0.24-0.49,0.36-0.74
                          C49.87,50.89,49.8,51.44,49.7,51.99L49.7,51.99z M18.73,52.81c-0.14-0.57-0.14-1.17-0.03-1.75c0,0,0-0.01,0-0.01
                          c0.13-0.67,0.31-1.31,0.55-1.93c1.12-2.98,3.43-5.38,6.36-6.6c0.46-0.19,0.95-0.35,1.44-0.48c0.96-0.26,1.96-0.41,3.01-0.41
                          c0.73,0,1.45,0.08,2.15,0.21c-2.28,1.21-4.15,3.08-5.35,5.35c-0.33,0.61-0.6,1.26-0.82,1.93c-0.43,1.28-0.67,2.65-0.67,4.07
                          c0,0.86,0.09,1.71,0.25,2.52c0.1,0.52,0.24,1.02,0.41,1.52c1.05,3.16,3.28,5.78,6.19,7.31c0.24,0.13,0.49,0.24,0.74,0.36
                          c-0.57-0.04-1.13-0.11-1.68-0.21C25.09,63.57,20.17,58.87,18.73,52.81L18.73,52.81z M18.56,46.26
                          c1.13-6.17,5.83-11.09,11.88-12.53c0.57-0.14,1.17-0.14,1.75-0.03c0,0,0.01,0,0.01,0c0.67,0.13,1.31,0.31,1.93,0.55
                          c2.98,1.12,5.38,3.43,6.6,6.36c0.19,0.46,0.35,0.95,0.48,1.44c0.26,0.96,0.41,1.96,0.41,3.01c0,0.73-0.08,1.45-0.21,2.15
                          c-1.21-2.28-3.08-4.15-5.35-5.35c-0.61-0.33-1.26-0.6-1.93-0.82c-1.28-0.43-2.65-0.67-4.07-0.67c-0.86,0-1.71,0.09-2.52,0.25
                          c-0.52,0.1-1.02,0.24-1.52,0.41c-3.16,1.05-5.78,3.28-7.31,6.19c-0.13,0.24-0.24,0.49-0.36,0.74
                          C18.39,47.37,18.46,46.81,18.56,46.26L18.56,46.26z M33.39,55.55c-2.51-1.15-4.53-3.17-5.68-5.68c-0.21-0.47-0.21-1.02,0-1.48
                          c1.15-2.51,3.17-4.53,5.68-5.68c0.47-0.21,1.02-0.21,1.48,0c2.51,1.15,4.53,3.17,5.68,5.68c0.21,0.47,0.21,1.02,0,1.48
                          c-1.15,2.51-3.17,4.53-5.68,5.68C34.4,55.77,33.85,55.77,33.39,55.55L33.39,55.55z M49.53,45.44c0.14,0.57,0.14,1.17,0.03,1.75
                          c0,0,0,0.01,0,0.01c-0.13,0.67-0.31,1.31-0.55,1.93c-1.12,2.98-3.43,5.38-6.36,6.6c-0.46,0.19-0.95,0.35-1.44,0.48
                          c-0.96,0.26-1.96,0.41-3.01,0.41c-0.73,0-1.45-0.08-2.15-0.21c2.28-1.21,4.15-3.08,5.35-5.35c0.33-0.61,0.6-1.26,0.82-1.93
                          c0.43-1.28,0.67-2.65,0.67-4.07c0-0.86-0.09-1.71-0.25-2.52c-0.1-0.52-0.24-1.02-0.41-1.52c-1.05-3.16-3.28-5.78-6.19-7.31
                          c-0.24-0.13-0.49-0.24-0.74-0.36c0.57,0.04,1.13,0.11,1.68,0.21C43.17,34.69,48.08,39.39,49.53,45.44L49.53,45.44z M50.99,46.21
                          c-0.4-2.3-1.24-4.44-2.46-6.33c-1.33-2.06-3.08-3.82-5.15-5.15c-1.89-1.22-4.03-2.07-6.33-2.46C36.1,32.1,35.13,32,34.13,32
                          s-1.97,0.1-2.92,0.27c-2.3,0.4-4.44,1.24-6.33,2.46c-2.06,1.33-3.82,3.09-5.15,5.15c-1.22,1.89-2.07,4.03-2.46,6.33
                          C17.1,47.16,17,48.13,17,49.13c0,1,0.1,1.97,0.27,2.92c0.4,2.3,1.24,4.44,2.46,6.33c1.33,2.06,3.09,3.82,5.15,5.15
                          c1.89,1.22,4.03,2.07,6.33,2.46c0.95,0.16,1.92,0.27,2.92,0.27s1.97-0.1,2.92-0.27c2.3-0.4,4.44-1.24,6.33-2.46
                          c2.06-1.33,3.82-3.09,5.15-5.15c1.22-1.89,2.07-4.03,2.46-6.33c0.16-0.95,0.27-1.92,0.27-2.92
                          C51.26,48.13,51.15,47.16,50.99,46.21L50.99,46.21z"
            />
          </g>
        </g>
      </svg>
    </div>
  )
}
