import classNames from 'classnames'
import React from 'react'

import useMicroCopy from '../../hooks/globalContent/useMicroCopy'
import ExternalLink from '../_elements/ExternalLink'
import SvgFacebook from '../_svg/_social/SvgFacebook'
import SvgInstagram from '../_svg/_social/SvgInstagram'
import SvgLinkedIn from '../_svg/_social/SvgLinkedIn'

import styles from './social-media-links.module.scss'

interface SocialMediaLinksProps {
  isDisabledLinks?: boolean

  className?: string
}

export default function SocialMediaLinks({
  isDisabledLinks = false,

  className,
}: SocialMediaLinksProps) {
  const facebookLink = useMicroCopy('social.facebook', undefined, null)
  const instagramLink = useMicroCopy('social.instagram', undefined, null)
  const linkedinLink = useMicroCopy('social.linkedin', undefined, null)

  return (
    <ul className={classNames(styles.container, className)}>
      {facebookLink && (
        <li className={styles.item}>
          <ExternalLink
            className={styles.link}
            to={facebookLink}
            tabIndex={isDisabledLinks ? -1 : undefined}
            segmentdata={{
              anchor_text: 'Kruso Facebook',
              position: 'body',
              url: facebookLink,
            }}
          >
            <span className={styles.linkText}>Kruso Facebook</span>
            <SvgFacebook
              className={classNames(styles.icon, styles.iconFacebook)}
            />
          </ExternalLink>
        </li>
      )}

      {linkedinLink && (
        <li className={styles.item}>
          <ExternalLink
            className={styles.link}
            to={linkedinLink}
            tabIndex={isDisabledLinks ? -1 : undefined}
            segmentdata={{
              anchor_text: 'Kruso LinkedIn',
              position: 'body',
              url: linkedinLink,
            }}
          >
            <span className={styles.linkText}>Kruso LinkedIn</span>
            <SvgLinkedIn
              className={classNames(styles.icon, styles.iconLinkedin)}
            />
          </ExternalLink>
        </li>
      )}

      {instagramLink && (
        <li className={styles.item}>
          <ExternalLink
            className={styles.link}
            to={instagramLink}
            tabIndex={isDisabledLinks ? -1 : undefined}
            segmentdata={{
              anchor_text: 'Kruso Instagram',
              position: 'body',
              url: instagramLink,
            }}
          >
            <span className={styles.linkText}>Kruso Instagram</span>
            <SvgInstagram
              className={classNames(styles.icon, styles.iconInstagram)}
            />
          </ExternalLink>
        </li>
      )}
    </ul>
  )
}
