import { CaseContent } from '../@import-types/contentful/Case.types'
import { NavigationType } from '../graphql/navigation.graphql'

import { joinUrlPaths } from './urlUtils'

export function createCaseLink(
  rootPath: string,
  globalStartPage: NavigationType | null,
  caseContent: CaseContent
) {
  return createPageLink(
    rootPath,
    globalStartPage?.ourWorkPage.slug,
    caseContent.slug
  )
}

export function createPageLink(
  rootPath: string,
  ...paths: (string | null | undefined)[]
) {
  return joinUrlPaths(rootPath, ...paths)
}
