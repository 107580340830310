import { useMemo } from 'react'

export default function useLowercaseQueryObject(search: string) {
  return useMemo(() => {
    const entries = search.replace('?', '').split('&')

    return entries.reduce((queryObject: Record<string, string>, entry) => {
      const [key, value = ''] = entry.split('=')
      queryObject[key.toLowerCase()] = value
      return queryObject
    }, {})
  }, [search])
}
