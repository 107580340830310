import { useCallback, useMemo, useState } from 'react'

import { NavMenuContextType } from '../../contexts/NavMenuContext'

export default function useNavMenuContext(): NavMenuContextType {
  const [isOpen, _setIsOpen] = useState(false)

  const setIsOpen = useCallback(function setIsOpen(isOpen: boolean) {
    _setIsOpen(isOpen)
  }, [])

  const contextValue = useMemo(() => {
    return { isOpen, setIsOpen }
  }, [isOpen, setIsOpen])

  return contextValue
}
