import { useEffect } from 'react'

export default function useWindowKeyEvent(
  keyCode: string | string[],
  callback: (event: KeyboardEvent) => void
) {
  useEffect(() => {
    function onKeyDown(event: KeyboardEvent) {
      let isMatch = false

      if (typeof keyCode === 'string') {
        isMatch = event.key === keyCode
      } else {
        isMatch = keyCode.includes(event.key)
      }

      if (isMatch) {
        callback(event)
      }
    }

    window.addEventListener('keydown', onKeyDown, false)

    return () => {
      window.removeEventListener('keydown', onKeyDown, false)
    }
  })
}
