import classNames from 'classnames'
import React from 'react'

import { Office } from '../../../@import-types/contentful/Office.types'
import { getSegmentLinkTrackingdData } from '../../_functional/Segment/helpers'

import styles from './office-card.module.scss'

interface OfficeCardProps {
  office: Office

  isCondensed?: boolean
  isLargerText?: boolean
  isDisabledLinks?: boolean

  className?: string
}

export default function OfficeCard({
  office,

  isCondensed = false,
  isLargerText = false,
  isDisabledLinks = false,

  className,
}: OfficeCardProps) {
  const { city, street, postalCode, country, phoneNumber, email } = office

  return (
    <article
      className={classNames(
        styles.container,
        isCondensed && styles.isCondensed,
        isLargerText && styles.largerText,
        className
      )}
    >
      <h4 className={styles.city}>{city}</h4>

      <address className={styles.address}>
        {!isCondensed && (
          <>
            {street}
            <br />
            {postalCode} {city}
            <br />
            {country}
          </>
        )}

        {phoneNumber && (
          <a
            className={styles.link}
            href={`tel:${phoneNumber}`}
            tabIndex={isDisabledLinks ? -1 : undefined}
            onClick={() =>
              getSegmentLinkTrackingdData({
                anchor_text: phoneNumber,
                position: 'footer',
                url: `tel:${phoneNumber}`,
                destination: 'internal',
              })
            }
          >
            {phoneNumber}
          </a>
        )}

        {email && (
          <a
            className={styles.link}
            href={`mailto:${email}`}
            tabIndex={isDisabledLinks ? -1 : undefined}
            onClick={() =>
              getSegmentLinkTrackingdData({
                anchor_text: email,
                position: 'footer',
                url: `tel:${email}`,
                destination: 'internal',
              })
            }
          >
            {email}
          </a>
        )}
      </address>
    </article>
  )
}
