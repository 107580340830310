import { createPageLink } from '../../utils/routingUtils'

import useRootLink from './useRootLink'

export default function usePageLink(
  ...pageSlug: (string | null | undefined)[]
) {
  const rootLink = useRootLink()
  return createPageLink(rootLink, ...pageSlug)
}
