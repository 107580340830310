import React, { useEffect } from 'react'

import { ContactFormId } from '../../constants/GlobalDOM'
import useMicroCopy from '../../hooks/globalContent/useMicroCopy'
import useNavMenuControls from '../../hooks/navMenu/useNavMenuControls'
import useRootLink from '../../hooks/routing/useRootLink'
import {
  getSmoothScrollToElement,
  getSmoothScrollToHash,
} from '../../hooks/useSmoothScrollToWindowHashOnLoad'
import {
  animateScrollToWithMargin,
  enablePageScroll,
} from '../../utils/scrollUtils'
import { InternalLink } from '../_elements/InternalLink/InternalLink'
import SvgArrowRightContact from '../_svg/SvgArrowRightContact'

import styles from './contact-us-anchor.module.scss'

interface ContactUsAnchorProps {
  color: string | null
}

export default function ContactUsAnchor({ color }: ContactUsAnchorProps) {
  const contactUsLabel = useMicroCopy('text.contact_us')
  const rootLink = useRootLink()
  const { close: closeNavMenu } = useNavMenuControls()

  const targetId = getSmoothScrollToHash(ContactFormId)

  function onLinkClick(event: React.MouseEvent<HTMLElement>) {
    const contactUsElement = getSmoothScrollToElement(targetId)
    if (!contactUsElement) {
      return
    }

    enablePageScroll()
    closeNavMenu()

    event.preventDefault()
    animateScrollToWithMargin(contactUsElement)

    // Remove focus to not let the hover/focus style remain after click
    event.currentTarget.blur()
  }

  return (
    <div onClick={onLinkClick}>
      <InternalLink
        className={styles.container}
        to={`${rootLink}${targetId}`}
        segmentdata={{
          anchor_text: contactUsLabel,
          position: 'navigation',
          url: `${rootLink}${targetId}`,
        }}
        id="test"
      >
        <span className={styles.text}>{contactUsLabel}</span>
        <div className={styles.anchorCircle} style={{borderColor: `${color === null ? 'black' : color}`}} />


        <SvgArrowRightContact className={styles.icon} color={color} />
      </InternalLink>
    </div>
  )
}
