import { useMemo } from 'react'

import { Tag } from '../../@import-types/contentful/general.types'
import { InputOption } from '../../@import-types/forms/inputs.types'

import { INTERNAL_SHOW_ALL_TAG } from './useFilteredEntiresByTag'

export default function useTagOptions<T extends { tags?: Tag[] | null }>(
  entries: T[],
  defaultTagValue?: string
) {
  const uniqueTags = useMemo(() => {
    const allTags = entries
      .map((entry) => {
        return entry.tags?.map((tag) => tag) ?? null
      })
      .filter((tag) => tag !== null)
      .flat()

    if (defaultTagValue) {
      allTags.unshift({ value: INTERNAL_SHOW_ALL_TAG, text: defaultTagValue })
    }

    let uniqueTags: Tag[] = []
    allTags.map((allTag) => {
      if (
        allTag &&
        uniqueTags.map((tag) => tag.value).indexOf(allTag.value) == -1
      )
        uniqueTags.push(allTag)
    })

    return uniqueTags
  }, [entries, defaultTagValue])

  return useInputOptionsFromTags(uniqueTags)
}

export function useInputOptionsFromTags(
  tags: Tag[] | null | undefined
): InputOption[] {
  return useMemo(() => {
    if (!tags) {
      return []
    }

    return tags.map((tag) => ({ value: tag.value, text: tag.text }))
  }, [tags])
}
