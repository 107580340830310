import { animateScrollToWithMargin } from '../utils/scrollUtils'

import useOnMount from './useOnMount'

const smoothScrollPrefix = '#smooth|'

export default function useSmoothScrollToWindowHashOnLoad(
  clearWindowHash = true
) {
  useOnMount(() => {
    const { location } = window

    const targetElement = getSmoothScrollToElement(location.hash)

    if (targetElement) {
      if (clearWindowHash && window.history) {
        window.history.replaceState(
          null,
          document.title,
          location.pathname + location.search
        )
      }

      const scrollAnimation = animateScrollToWithMargin(targetElement)
      return () => scrollAnimation?.stop()
    }
  })
}

export function getSmoothScrollToHash(elementId: string) {
  return `${smoothScrollPrefix}${elementId}`
}

export function getSmoothScrollToElement(hash: string) {
  if (hash.indexOf(smoothScrollPrefix) !== 0) {
    return null
  }

  const idToSearchFor = hash.replace(smoothScrollPrefix, '')
  const targetElement = document.getElementById(idToSearchFor)
  return targetElement ?? null
}
