import React from 'react'
import linkedinInsight from 'react-linkedin-insight-tag'

interface Props {
  id?: string
}

export default function LinkedinInsights({ id }: Props) {
  // LinkedIn insights.

  let linkedinInsighturl = undefined
  if (id && typeof window !== 'undefined') {
    linkedinInsight.init(id)
    linkedinInsighturl = linkedinInsight.track()
  } else {
    console.warn('LinkedInID not defined')
  }
  return (
    <>
      {id && linkedinInsighturl && (
        <img src={linkedinInsighturl} width="1" height="1" alt="" />
      )}
    </>
  )
}
