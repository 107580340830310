// https://www.w3.org/TR/uievents-key/#named-key-attribute-values

const KeyEventKeys = {
  ArrowDown: 'ArrowDown',
  ArrowLeft: 'ArrowLeft',
  ArrowRight: 'ArrowRight',
  ArrowUp: 'ArrowUp',

  PageDown: 'PageDown',
  PageUp: 'PageUp',

  Enter: 'Enter',
  Space: ' ',
  Escape: 'Escape',
  Tab: 'Tab',
}

export default KeyEventKeys
