import { useContext, useMemo } from 'react'

import { Office } from '../../@import-types/contentful/Office.types'
import GlobalContentContext from '../../contexts/GlobalContentContext'

export default function useOffices(): Office[] {
  const globalContent = useContext(GlobalContentContext)
  return useMemo(() => {
    return globalContent?.contentfulStartPage?.offices ?? []
  }, [globalContent])
}
