import React from 'react'

import Colors from '../../../constants/Colors'

interface SvgArrowRightContactProps {
  color?: string | null
}

export default function SvgArrowRightContact({
  color,
  ...svgProps
}: SvgArrowRightContactProps & Omit<JSX.IntrinsicElements['svg'], 'color'>) {
  const renderColor = color ?? Colors.Brown

  return (
    <svg width="14" height="13" viewBox="0 0 14 13" fill="none" {...svgProps}>
      <path
        d="M0 6.49988L11.9998 6.49988"
        stroke={renderColor}
        strokeWidth="2"
      />
      <path
        d="M6.54541 12L11.9999 6.5L6.54541 1"
        stroke={renderColor}
        strokeWidth="2"
      />
    </svg>
  )
}
