import { Destination } from '@segment/consent-manager/types/types'
import React, { ChangeEvent } from 'react'

import ContentfulRichText from '../_elements/ContentfulRichText'

import styles from './cookie-banner.module.scss'
export type RichTextJson = any

export interface HeaderProps {
  allowValue: string | null | undefined
  categoryValue: string | null | undefined
  purposeValue: string | null | undefined
  toolsValue: string | null | undefined
}

export function Header({
  allowValue,
  categoryValue,
  purposeValue,
  toolsValue,
}: HeaderProps) {
  return (
    <tr>
      <th>{allowValue}</th>
      <th>{categoryValue}</th>
      <th>{purposeValue}</th>
      <th className={styles.hideOnMobile}>{toolsValue}</th>
    </tr>
  )
}

export interface RowProps {
  name: string
  essential: boolean
  preferenceCategory: boolean | null | undefined
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  rowHeader: string | null | undefined
  purposeText: { json: any } | null | undefined
  purposeExample: { json: any } | null | undefined
  destinations: Destination[]
  ariaLabel?: string
  yesText: string | null | undefined;
  noText: string | null | undefined;
}
// Creates the row of elements for the table in the cookie banner
export function Row({
  name,
  essential,
  preferenceCategory,
  handleChange,
  rowHeader,
  purposeText,
  purposeExample,
  destinations,
  ariaLabel,
}: RowProps) {
  return (
    <tr>
      <td>
        {essential ? (
          <input
            type="checkbox"
            disabled
            checked
            style={{ cursor: 'not-allowed', backgroundColor: '#ddd' }}
          />
        ) : (
          <Checkbox
            name={name}
            preferenceCategory={preferenceCategory}
            handleChange={handleChange}
            label=""
            ariaLabel={ariaLabel ?? 'allow cookies in this category'}
          />
        )}
      </td>
      <th scope="row">{rowHeader}</th>
      <td>
        <ContentfulRichText json={purposeText?.json} />
        <div className={styles.hideOnMobile}>
          <ContentfulRichText json={purposeExample?.json} />
        </div>
      </td>
      <td className={styles.hideOnMobile}>
        {destinations.map((d) => d.name).join(', ')}
      </td>
    </tr>
  )
}

export interface CheckboxProps {
  name: string
  preferenceCategory: boolean | null | undefined
  handleChange: React.ChangeEventHandler
  label: string
  ariaLabel: string
}

export function Checkbox({
  name,
  preferenceCategory,
  handleChange,
  label,
  ariaLabel,
}: CheckboxProps) {
  return (
    <label htmlFor="check">
      <input
        id="check"
        className="toggle"
        type="checkbox"
        name={name}
        checked={preferenceCategory || false}
        onChange={handleChange}
        aria-label={ariaLabel}
      />{' '}
      {label}
    </label>
  )
}
