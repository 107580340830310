import { useCallback, useRef, useEffect } from 'react';
import { getScrollTop } from '../utils/scrollUtils';
import useWindowSize from './useWindowSize';

export default function useOnScroll(
  callback: (scrollTop: number) => void,
  noCallbackOnEffect = false
) {
  useWindowSize();

  const rafIdRef = useRef(-1);

  const callbackRef = useRef(callback);
  callbackRef.current = callback;

  const onScroll = useCallback(() => {
    if (rafIdRef.current === -1) {
      rafIdRef.current = requestAnimationFrame(() => {
        rafIdRef.current = -1;
        callbackRef.current(getScrollTop());
      });
    }
  }, [callbackRef]);

  useEffect(() => {
    if (!noCallbackOnEffect) {
      window.addEventListener('scroll', onScroll);
      return () => {
        window.removeEventListener('scroll', onScroll);
      };
    }
  }, [onScroll, noCallbackOnEffect]);
}