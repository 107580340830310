import { useCallback, useContext } from 'react'

import NavMenuContext from '../../contexts/NavMenuContext'

import useIsNavMenuOpen from './useIsNavMenuOpen'

export default function useNavMenuControls() {
  const context = useContext(NavMenuContext)
  const isOpen = useIsNavMenuOpen()

  const open = useCallback(
    function openNavMenu() {
      context?.setIsOpen(true)
    },
    [context]
  )

  const close = useCallback(
    function closeNavMenu() {
      context?.setIsOpen(false)
    },
    [context]
  )

  const toggle = useCallback(
    function toggleNavMenu() {
      if (isOpen) {
        close()
      } else {
        open()
      }
    },
    [isOpen, open, close]
  )

  return { open, close, toggle }
}
