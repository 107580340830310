import classNames from 'classnames'
import React, { useEffect, useState } from 'react'

import useIsNavMenuOpen from '../../hooks/navMenu/useIsNavMenuOpen'
import { enablePageScroll } from '../../utils/scrollUtils'
import { InternalLink } from '../_elements/InternalLink/InternalLink'

import { NavMenuLink } from './NavMenu.hooks'

import styles from './nav-menu.module.scss'

interface SubNavMenuProps {
  navItem: NavMenuLink
}

export default function SubNavMenu({ navItem }: SubNavMenuProps) {
  const [subIsOpen, setSubIsOpen] = useState(false)
  const [useTransition, setUseTransition] = useState(false)

  const isOpen = useIsNavMenuOpen()

  function onMenuLinkClick() {
    enablePageScroll()
  }

  useEffect(() => {
    if (typeof window == 'undefined') return
    const pathName = window.location.pathname
    const mainSubpage = navItem.to.toLocaleLowerCase()
    const active = pathName.includes(mainSubpage)

    if (!active) {
      setSubIsOpen(false)
    } else if (active && isOpen) {
      setUseTransition(false)
      setSubIsOpen(true)
    }
  }, [isOpen])

  return (
    <>
      <li key={navItem.to + navItem.label} className={styles.navListItem}>
        <button
          tabIndex={isOpen ? undefined : -1}
          title={navItem.label}
          onClick={() => {
            setSubIsOpen(!subIsOpen)
            setUseTransition(true)
          }}
          className={classNames(
            styles.navLink,
            navItem.subpages && styles.navLinkWithSub,
            subIsOpen && styles.navLinkWithSubActive,
            useTransition && styles.navLinkTransition
          )}
        >
          {navItem.label}
        </button>
      </li>
      <ul
        className={classNames(
          styles.subContainer,
          subIsOpen && styles.showSub,
          useTransition && styles.navLinkTransition
        )}
      >
        {navItem.subpages?.map((page, index) => (
          <li
            key={index}
            className={styles.subNavLink}
            onClick={onMenuLinkClick}
            title={page.navigationTitle}
          >
            <InternalLink
              activeClassName={styles.subNavLinkActive}
              to={page.link}
              tabIndex={subIsOpen ? 0 : -1}
              segmentdata={{
                anchor_text: page.navigationTitle,
                position: 'navigation',
                url: page.link,
              }}
            >
              {page.navigationTitle}
            </InternalLink>
          </li>
        ))}
      </ul>
    </>
  )
}
