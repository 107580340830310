import { useCallback, useState } from 'react'

import KeyEventKeys from '../constants/KeyEventKeys'

import useOnUnmount from './useOnUnmount'
import useWindowKeyEvent from './useWindowKeyEvent'

export default function useTabLock(
  rootElementRef: {
    current?: HTMLElement | null
  },
  lockOnMount = false
) {
  const [shouldLock, setShouldLock] = useState(lockOnMount)

  const lockTabFocus = useCallback(() => {
    setShouldLock(true)
  }, [])

  const unlockTabFocus = useCallback(() => {
    setShouldLock(false)
  }, [])

  useWindowKeyEvent(KeyEventKeys.Tab, (event) => {
    const rootElement = rootElementRef.current

    if (!rootElement || !shouldLock) {
      return
    }

    const currentFocused = document.activeElement
    let elementToFocus: HTMLElement | null | undefined = null

    const allFocusableElements = rootElement.querySelectorAll(
      'a[href]:not([disabled]), button:not([disabled]), textarea:not([disabled]), input[type="text"]:not([disabled]), input[type="radio"]:not([disabled]), input[type="checkbox"]:not([disabled]), select:not([disabled])'
    )
    const firstElement = allFocusableElements[0] as HTMLElement | undefined
    const lastElement = allFocusableElements[
      allFocusableElements.length - 1
    ] as HTMLElement | undefined

    if (event.shiftKey) {
      if (currentFocused === firstElement) {
        elementToFocus = lastElement
      }
    } else {
      if (currentFocused === lastElement) {
        elementToFocus = firstElement
      }
    }

    if (!elementToFocus) {
      let isNoValidElementFocused = true
      for (let i = 0; i < allFocusableElements.length; i++) {
        if (allFocusableElements[i] === currentFocused) {
          isNoValidElementFocused = false
          break
        }
      }

      if (isNoValidElementFocused) {
        elementToFocus = firstElement
      }
    }

    if (elementToFocus) {
      event.preventDefault()
      elementToFocus.focus()
    }
  })

  useOnUnmount(unlockTabFocus)

  return { lockTabFocus, unlockTabFocus }
}
