import { useState, useEffect } from 'react'

export enum ScriptStatus {
  Idle = 'idle',
  Loading = 'loading',
  Ready = 'ready',
  Error = 'error',
}

/**
 *
 * @param src string can be undefined. This sets the script state to "Idle". Useful in "async"-ish situations.
 */

export default function useScriptLoader(src?: string) {
  const [status, setStatus] = useState<ScriptStatus>(
    src ? ScriptStatus.Loading : ScriptStatus.Idle
  )

  useEffect(() => {
    if (!src) {
      setStatus(ScriptStatus.Idle)
      return
    }

    let script: HTMLScriptElement | null = document.querySelector(
      `script[src="${src}"]`
    )

    if (!script) {
      script = document.createElement('script')
      script.src = src
      script.async = true
      script.setAttribute('data-status', ScriptStatus.Loading)
      document.body.appendChild(script)

      // Store status in attribute on script
      // This can be read by other instances of this hook
      const setAttributeFromEvent = (event: Event) => {
        script!.setAttribute(
          'data-status',
          event.type === 'load' ? ScriptStatus.Ready : ScriptStatus.Error
        )
      }

      script.addEventListener('load', setAttributeFromEvent)
      script.addEventListener('error', setAttributeFromEvent)
    } else {
      // Grab existing script status from attribute and set to state.
      const status = script.getAttribute('data-status') as ScriptStatus
      setStatus(status)
    }

    const setStateFromEvent = (event: Event) => {
      setStatus(event.type === 'load' ? ScriptStatus.Ready : ScriptStatus.Error)
    }

    // Add event listeners
    script.addEventListener('load', setStateFromEvent)
    script.addEventListener('error', setStateFromEvent)

    return () => {
      if (script) {
        script.removeEventListener('load', setStateFromEvent)
        script.removeEventListener('error', setStateFromEvent)
      }
    }
  }, [src])

  return status
}
