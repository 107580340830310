import { useContext } from 'react'

import GlobalContentContext from '../../contexts/GlobalContentContext'
import { CommonContent } from '../../graphql/global.graphql'
import { getMicroCopy } from '../../utils/translationUtils'

export default function useMicroCopy(
  key: string,
  commonContent?: CommonContent | undefined,
  defaultValue?: any
) {
  const content =
    commonContent || useContext(GlobalContentContext)?.commonContent
  return getMicroCopy(content?.microCopy, key, defaultValue)
}
