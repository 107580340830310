import classNames from 'classnames'
import React from 'react'

import styles from './heading-2.module.scss'

interface Heading2Props {}

export default function Heading2({
  className,
  ...headingProps
}: Heading2Props & JSX.IntrinsicElements['h2']) {
  return (
    <h2 className={classNames(styles.heading2, className)} {...headingProps} />
  )
}
