import { MicroCopy } from '../graphql/global.graphql'

export function getDefaultLocale() {
  return process.env.GATSBY_SITE_LOCALE!
}

export function getTranslation(obj: unknown, key: string): string {
  const defaultValue = `{${key}}`
  const value =
    typeof obj === 'object' && obj !== null
      ? (obj as Record<string, string>)[key]
      : null
  return value ?? defaultValue
}

export function getMicroCopy(
  obj: unknown,
  key: string,
  defaultValue = `{${key}}`
): string {
  const value =
    Array.isArray(obj) && obj !== null
      ? obj.find((item: MicroCopy) => item.key === key)?.value
      : null
  return value ?? defaultValue
}
