import {
  documentToReactComponents,
  Options,
} from '@contentful/rich-text-react-renderer'
import { BLOCKS } from '@contentful/rich-text-types'
import React from 'react'

import RichTextEmbeddedEntry from './RichTextEmbeddedEntry'

export type RichTextJson = any

interface ContentfulRichTextProps {
  json: RichTextJson
}

const richTextRenderingOptions: Options = {
  renderNode: {
    [BLOCKS.EMBEDDED_ENTRY]: function renderEmbeddedEntry(node, children) {
      return <RichTextEmbeddedEntry node={node} children={children} />
    },
  },
}

export default function ContentfulRichText({ json }: ContentfulRichTextProps) {
  return <>{documentToReactComponents(json, richTextRenderingOptions)}</>
}
