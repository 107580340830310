import classNames from 'classnames'
import React, { ReactChild } from 'react'

import LayoutContainer from '../../_layouts/LayoutContainer'
import HeroHeading from '../../_typography/HeroHeading'

import styles from './filter-hero.module.scss'

interface FilterHeroProps {
  title: string | null | undefined
  subtitle?: string | null | undefined

  filterPreamble?: string | null
  filter?: ReactChild

  className?: string
}

export default function FilterHero({
  title,
  subtitle,

  filterPreamble,
  filter,

  className,
}: FilterHeroProps) {
  return (
    <LayoutContainer className={classNames(styles.container, className)}>
      <HeroHeading className={styles.title}>{title}</HeroHeading>

      {subtitle && <p className={styles.subtitle}>{subtitle}</p>}

      {filter && (
        <div
          className={classNames(
            styles.filterContainer,
            !subtitle && styles.marginTop
          )}
        >
          {filterPreamble && (
            <span className={styles.filterPreamble}>{filterPreamble}</span>
          )}

          <span className={styles.filter}>{filter}</span>
        </div>
      )}
    </LayoutContainer>
  )
}
