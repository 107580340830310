import React, { ReactElement } from 'react'
import { MutableRefObject } from 'react'

import LayoutContainer from '../LayoutContainer'

import styles from './teaser-grid-layout.module.scss'

interface TeaserGridLayoutProps {
  children: ReactElement | ReactElement[]

  forwardRef?: MutableRefObject<HTMLDivElement | null>
}

export default function TeaserGridLayout({
  children,

  forwardRef: forwardRef,
}: TeaserGridLayoutProps) {
  return (
    <LayoutContainer
      className={styles.container}
      isWider
      forwardRef={forwardRef}
    >
      {children}
    </LayoutContainer>
  )
}
