import classNames from 'classnames'
import React from 'react'

import { InputOption } from '../../../@import-types/forms/inputs.types'
import SvgArrowDown from '../../_svg/SvgArrowDown'

import styles from './filter-dropdown.module.scss'

interface FilterDropdownProps {
  options: InputOption<string>[]
  value: string

  label?: string | null

  withIcon?: boolean

  className?: string

  onChange: (value: string, event: React.ChangeEvent<HTMLSelectElement>) => void
}

export default function FilterDropdown({
  options,
  value,

  label,

  withIcon = false,

  className,

  onChange,

  ...selectProps
}: FilterDropdownProps &
  Omit<JSX.IntrinsicElements['select'], 'value' | 'onChange'>) {
  function onSelectChange(event: React.ChangeEvent<HTMLSelectElement>) {
    const value = event.currentTarget.value
    onChange(value, event)
  }

  return (
    <label
      className={classNames(
        styles.container,
        withIcon && styles.withIcon,
        className
      )}
    >
      {label && <span className={styles.label}>{label}</span>}

      <select
        className={styles.select}
        value={value}
        onChange={onSelectChange}
        {...selectProps}
      >
        {options.map((item) => (
          <option key={item.value} value={item.value}>
            {item.text}
          </option>
        ))}
      </select>

      {withIcon && <SvgArrowDown className={styles.arrowDown} />}
    </label>
  )
}
