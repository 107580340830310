import React from 'react'

interface SvgArrowDownProps {}

export default function SvgArrowDown(
  props: SvgArrowDownProps & JSX.IntrinsicElements['svg']
) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      stroke="#2F210C"
      {...props}
    >
      <path
        className="svg-stroke"
        d="M2.67767 6.16114L9.83884 13.3223L17 6.16114"
      />
    </svg>
  )
}
