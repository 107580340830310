import classNames from 'classnames'
import React, { useRef } from 'react'

import { usePageContext } from '../../hooks/globalContent/usePageContext'
import useFixedElementPageTextColor from '../../hooks/useFixedElementPageTextColor'
import { getDefaultLocale } from '../../utils/translationUtils'
import { joinUrlPaths } from '../../utils/urlUtils'
import { InternalLink } from '../_elements/InternalLink/InternalLink'

import styles from './language-selector.module.scss'

interface LanguageSelectorProps {
  isFixed?: boolean
  isHorizontal?: boolean
  isDisabledLinks?: boolean

  className?: string
}

export default function LanguageSelector({
  isFixed = false,
  isHorizontal = false,
  isDisabledLinks = false,

  className,
}: LanguageSelectorProps) {
  const pageContext = usePageContext()

  const containerElementRef = useRef<HTMLDivElement>(null)

  const pageTextColor = useFixedElementPageTextColor(containerElementRef)

  const nativeLocale = getDefaultLocale()
  const isNativeLanguageActive = nativeLocale === pageContext?.locale

  const pagePath = pageContext?.pagePath ?? '/'
  const nativeLink = joinUrlPaths('/', pagePath)
  const englishLink = joinUrlPaths('/en', pagePath)

  return (
    <div
      className={classNames(
        styles.languageSelector,
        isFixed && styles.isFixed,
        isHorizontal && styles.isHorizontal,
        className
      )}
      ref={containerElementRef}
      style={{ color: isFixed ? pageTextColor : undefined }}
    >
      {nativeLink && nativeLocale && (
        <InternalLink
          className={classNames(
            styles.link,
            isNativeLanguageActive && styles.linkIsActive
          )}
          to={nativeLink}
          tabIndex={isDisabledLinks ? -1 : undefined}
          segmentdata={{
            anchor_text: nativeLocale,
            position: 'navigation',
            url: nativeLink,
          }}
        >
          {nativeLocale}
        </InternalLink>
      )}

      <InternalLink
        className={classNames(
          styles.link,
          !isNativeLanguageActive && styles.linkIsActive
        )}
        to={englishLink}
        tabIndex={isDisabledLinks ? -1 : undefined}
        segmentdata={{
          anchor_text: 'EN',
          position: 'body',
          url: nativeLink,
        }}
      >
        EN
      </InternalLink>
    </div>
  )
}
