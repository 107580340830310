import React from 'react'

interface SvgFacebookProps {}

export default function SvgFacebook(
  svgProps: SvgFacebookProps & JSX.IntrinsicElements['svg']
) {
  return (
    <svg width="8" height="15" viewBox="0 0 8 15" {...svgProps}>
      <path
        className="svg-fill"
        d="M4.83223 14.4952V7.95603H7.02716L7.35578 5.40761H4.83223V3.78053C4.83223 3.04272 5.03713 2.53986 6.09525 2.53986L7.44473 2.53924V0.259976C7.21123 0.229032 6.41022 0.159668 5.47831 0.159668C3.53265 0.159668 2.20061 1.34724 2.20061 3.52827V5.40768H0V7.9561H2.20054V14.4952L4.83223 14.4952Z"
        fill="#2F210C"
      />
    </svg>
  )
}
