import { MutableRefObject, useEffect, useRef, useState } from 'react'

import { PageColorDataAttribute } from '../constants/GlobalDOM'
import {
  getIsScrollDisabled,
  getScrollTop,
  getScrollTopBeforeDisable,
} from '../utils/scrollUtils'

import usePageTextColor from './globalContent/usePageTextColor'
import useOnScroll from './useOnScroll'
import useOptionalArgumentRef from './useOptionalArgumentRef'

export default function useFixedElementPageTextColor<
  T extends HTMLElement | null
>(elementRef: MutableRefObject<T>) {
  const pageTextColor = usePageTextColor()

  const [currentColor, setCurrentColor] = useState<string | null>(pageTextColor)

  const cachedCalculatedValuesRef = useRef({
    pageColorElementBottom: 0,
    elementMiddle: 0,
  })

  useEffect(() => {
    const element = elementRef?.current
    if (!element) {
      return
    }

    const pageColorElement = document.querySelector<HTMLElement>(
      `[${PageColorDataAttribute}]`
    )

    if (!pageColorElement) {
      return
    }

    const elementBoundingRect = element.getBoundingClientRect()
    cachedCalculatedValuesRef.current.elementMiddle =
      elementBoundingRect.top + elementBoundingRect.height / 2

    const pageColorElementBoundingRect = pageColorElement.getBoundingClientRect()
    cachedCalculatedValuesRef.current.pageColorElementBottom =
      pageColorElementBoundingRect.bottom + getScrollTop()
  })

  useOnScroll((scrollTop) => {
    const {
      pageColorElementBottom,
      elementMiddle,
    } = cachedCalculatedValuesRef.current

    if (!pageTextColor || pageColorElementBottom === 0 || elementMiddle === 0) {
      return
    }

    if (getIsScrollDisabled()) {
      scrollTop = getScrollTopBeforeDisable()
    }

    if (scrollTop + elementMiddle > pageColorElementBottom) {
      setCurrentColor(null)
    } else {
      setCurrentColor(pageTextColor)
    }
  })

  return currentColor
}
