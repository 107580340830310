import React from 'react'

import styles from './blockquote.module.scss'

interface BlockquoteProps {
  quote: string | null | undefined

  author?: string | null
  context?: string | null

  className?: string
}

export default function Blockquote({
  quote,

  author,
  context,
}: BlockquoteProps) {
  if (!quote) {
    return null
  }

  return (
    <blockquote className={styles.blockquote}>
      <p className={styles.quote}>{quote}</p>

      {(author || context) && (
        <footer className={styles.footer}>
          {author && (
            <span className={styles.author}>
              {'- '}
              {author}
              {context && ', '}
            </span>
          )}
          {context && <cite className={styles.context}>{context}</cite>}
        </footer>
      )}
    </blockquote>
  )
}
