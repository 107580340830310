import { useMemo } from 'react'

import { ContentfulClient } from '../../@import-types/contentful/Client.types'
import { Tag } from '../../@import-types/contentful/general.types'
import { caseInsensitiveEquals } from '../../utils/stringUtils'

interface ObjectWithTag {
  id?: string
  tags?: Tag[] | null
  client?: ContentfulClient | null
}

export const INTERNAL_SHOW_ALL_TAG = '__SHOW_ALL'

export const INTERNAL_TAG_CLIENT_PREFIX = 'client|'

export function useFilteredEntriesByTag<T extends ObjectWithTag>(
  entries: T[],
  selectedTag: string,
  spotlightContent?: T[] | null
): T[] {
  const filteredEntries = useMemo(() => {
    if (selectedTag.toUpperCase().includes(INTERNAL_SHOW_ALL_TAG)) {
      return entries
    }

    if (selectedTag && selectedTag.startsWith(INTERNAL_TAG_CLIENT_PREFIX)) {
      const clientName = selectedTag.substr(INTERNAL_TAG_CLIENT_PREFIX.length)
      return entries.filter((entry) =>
        caseInsensitiveEquals(entry.client?.name, clientName)
      )
    }

    return entries.filter((entry) =>
      entry.tags?.some((tag) => tag.value === selectedTag)
    )
  }, [entries, selectedTag, spotlightContent])

  return filteredEntries
}
